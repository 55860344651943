import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../assets/img';
import CalendarMeeting from '../../assets/img/CalendarMeeting.svg';
import {
  ApprovedSP,
  Assessment,
  BestFitRecommendation,
  BoardMembership,
  BreadCrumb,
  CareerAspiration,
  CareerJourney,
  ContractDetails,
  DevelopmentPlan,
  Documents,
  DropdownCustomEmployee,
  DropdownMeetingCategory,
  EDGEView,
  Experiences,
  HistoricalTalentStatus,
  MobilityPlan,
  Notes,
  OtherContribution,
  OtherExperience,
  Personality,
  PopupMeetingDetails,
  Qualification,
  SpokenLanguage,
  TalentComparison,
  TalentProfilePrintPreview,
  TalentReview,
  TalentSketch,
} from '../../components';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import GlobalAlertMessage from '../../components/GlobalAlertMessage/GlobalAlertMessage';
import { SelectMultipleSearch } from '../../components/SelectMultiple/SelectMultiple';
import { profileManagementApi } from '../../services/profileManagementApi';
import talentProfileApi, {
  cancelUploadDocument,
  fetchCheckPermittedStaff,
  fetchTalentProfilePersonality,
  fetchTalentProfileSummary,
  getNotes,
  getTalentDetails,
} from '../../services/talentProfiles';
import { clearDataCancelUploadDocument } from '../../store/cancelUploadSlice';
import {
  EMPLOYEE_INSIGHTS_OPTIONS,
  PERSONALITY_SORT_ORDER,
  STATUS_UPLOAD,
  TALENT_PROFILE_DISCLAIMER,
} from '../../utils/constants';
import { getPermissionsByRole, sortCollectionByField } from '../../utils/helper';
import ExternalAssessments from './components/ExternalAssessments/ExternalAssessments';
import OverAllProfileUser from './components/OverAllProfileUser/OverAllProfileUser';
import TalentVideo from './components/TalentVideo/TalentVideo';
import { profileSelector } from './selector';
import { getFirstProfiles, getSecondProfiles, getThirdProfiles } from './store/profileSlice';
import styles from './talentProfilePage.module.scss';
import ExternalExperience from '../../components/ExternalExperience/ExternalExperience';
import WorkPreference from '../../components/WorkPreference/WorkPreference';
import { setTokenTalentProfile } from '../../store/pageSlice';

// this code of page meeting detail
const mock_data_category = [
  {
    id: '12',
    name: 'level 1',
  },
  {
    id: '22',
    name: 'level 2',
  },
  {
    id: '32',
    name: 'level 3',
  },
  {
    id: '42',
    name: 'level 4',
  },
];

const mock_data = [
  {
    value: '1',
    label: 'Sani ZUhairi bin zainudin',
  },
  {
    value: '2',
    label: 'shafik shaff bin azfar',
  },
  {
    value: '3',
    label: 'muhaimin bin abdul',
  },
  {
    value: '4',
    label: 'mohammad fikri bin hakim',
  },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 44,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '2px 8px 2px 15px',
    gap: '8px',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    flexDirection: 'row-reverse',
    background: '#FFFFFF',
    border: '1px solid #BDBDBD',
    borderRadius: '11px',
    margin: 0,
    padding: '3px 8px 3px 3px',
    alignItems: 'center',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: '#8d8d8d',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    padding: 0,
    paddingLeft: 0,
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'unset',
      color: '#535D70',
    },
  }),
};

const TalentProfile = () => {
  const dropdownList = [
    {
      id: '5',
      value: EMPLOYEE_INSIGHTS_OPTIONS.TALENT_SUMMARY,
    },
    {
      id: '2',
      value: EMPLOYEE_INSIGHTS_OPTIONS.EXPERIENCES,
    },
    {
      id: '1',
      value: EMPLOYEE_INSIGHTS_OPTIONS.LEADERSHIP_COMPETENCIES,
    },
    {
      id: '6',
      value: EMPLOYEE_INSIGHTS_OPTIONS.EXTERNAL_ASSESSMENT,
    },
    {
      id: '3',
      value: EMPLOYEE_INSIGHTS_OPTIONS.TALENT_DEVELOPMENT_PLAN,
    },
    {
      id: '4',
      value: EMPLOYEE_INSIGHTS_OPTIONS.RIGHT_FIT_RECOMMENDATION,
    },
  ];
  const { firstSectionProfile } = useSelector(profileSelector);
  const [selectedEmployee, setSelectedEmployee] = useState(dropdownList[0]);
  const [talentProfileDetails, setTalentProfileDetails] = useState({});
  const [notes, setNotes] = useState({ totalNotes: 0, data: [] });
  const [allNotes, setAllNotes] = useState({ totalNotes: 0, data: [] });
  const [qualification, setQualification] = useState([]);
  const [langAndBoard, setLangAndBoard] = useState([]);
  const [lang, setLang] = useState([]);
  const [carreerAspiration, setCarreerAspiration] = useState('');
  const [personality, setPersonality] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const user = useSelector((state) => state.user);
  const [isPopupTalentComparison, setIsPopupTalentComparison] = useState(false);
  const { id } = useParams();
  const roleUser = { id, roleActive: user.roleActive, permissions: user.permissions };
  // this code of page meeting detail
  const [isMeetingPage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isHasEditPermission, setIsHasEditPermission] = useState(false);

  const [isPopupMeetingDetails, setIsPopupMeetingDetails] = useState(false);
  const [meetingDetails, setMeetingDetail] = useState({
    meetingName: '',
    date: '',
    location: '',
    startTime: '',
    endTime: '',
    category: {},
    status: '',
    chairman: '',
    committeeMembers: [],
    facilitators: [],
    secretariat: [],
  });
  const ref = useRef();
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
    { name: 'Talent Profile' },
  ]);
  const [loadingPersonality, setLoadingPersonality] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const tokenTalentProfile = useSelector((state) => state.page.tokenTalentProfile);

  useEffect(() => {
    async function fetchApi() {
      try {
        const rs = await talentProfileApi.getTokenTalentProfile(id, roleUser.roleActive.roleId);
        dispatch(setTokenTalentProfile(get(rs, 'data.result')));
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 403) history.push('/profile-block');
      }
    }
    fetchApi();
    return () => dispatch(setTokenTalentProfile(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Handle reset value of Employee Insights dropdown when changing Staff Id
  useEffect(() => {
    if (!isEmpty(id)) {
      setSelectedEmployee(dropdownList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setSelectedEmployee]);

  const { uploadDocument } = useSelector((state) => state.cancelUpload);

  useEffect(() => {
    if (!uploadDocument || uploadDocument.staff_id !== id || !uploadDocument.is_refresh) return;
    const bodyUploading = {
      staff_id: id,
      status: STATUS_UPLOAD.CANCEL,
      file_name: uploadDocument.file_name,
    };
    dispatch(clearDataCancelUploadDocument());
    cancelUploadDocument(bodyUploading);
  }, [uploadDocument, id, dispatch]);

  useEffect(() => {
    setBreadCrumbList([
      { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
      { name: `Talent Profile (${firstSectionProfile?.birthName || ''})` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSectionProfile?.birthName]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  const handleChangeDate = (e) => {
    setMeetingDetail({ ...meetingDetails, date: e._d });
    setIsActive(false);
  };

  const onSaveDetails = () => {
    setIsPopupMeetingDetails(false);
  };

  const filterColors = (inputValue) => {
    return mock_data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });
  };

  // this code of page meeting detail

  useEffect(() => {
    if (!roleUser.roleActive.roleId || !tokenTalentProfile) return;
    const getDate = async () => {
      getTalentProfileDetails();
      dispatch(getThirdProfiles(roleUser));
      dispatch(getFirstProfiles(roleUser))
        .unwrap()
        .then((_originalPromiseResult) => {
          // handle result here
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          if (rejectedValueOrSerializedError.status === 403) history.push('/profile-block');
        });
      dispatch(getSecondProfiles(roleUser));
      getTalentProfileSummary();
      getTalentProfilePersonality();
      checkPermittedStaff();
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, id, roleUser.permissions?.length, tokenTalentProfile]);

  useEffect(() => {
    if (!roleUser.roleActive.roleId || !tokenTalentProfile) return;
    fetchNotes(0, 5, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, id, roleUser.permissions?.length, tokenTalentProfile]);

  const getTalentProfileDetails = async () => {
    try {
      const response = await getTalentDetails({ staffId: id, roleUser });
      if (response.status === 200 && response?.data?.result) {
        const talentDetails = {
          ...response?.data?.result,
          id: id,
        };
        setTalentProfileDetails(talentDetails);
      }
    } catch (error) {
      console.error(error);
      setTalentProfileDetails({});
    }
  };

  const getTalentProfileSummary = async () => {
    try {
      setLoadingSummary(true);
      const response = await fetchTalentProfileSummary({ roleUser, id });
      let result = response?.data?.result;
      if (result && response.data.code === 200) {
        if (result?.resBoardMembership && Object.keys(result.resBoardMembership).length > 0) {
          setLangAndBoard(result.resBoardMembership);
        } else {
          setLangAndBoard([]);
        }
        if (result?.resLanguage && Object.keys(result.resLanguage).length > 0) {
          setLang(result.resLanguage);
        } else {
          setLang([]);
        }
        if (result?.resQualification && Object.keys(result.resQualification).length > 0) {
          setQualification(result.resQualification);
        } else {
          setQualification([]);
        }
        if (result?.careerAspirationAll && Object.keys(result.careerAspirationAll).length > 0) {
          setCarreerAspiration(result.careerAspirationAll);
        } else {
          setCarreerAspiration('');
        }
      }
    } catch (error) {
      setLangAndBoard([]);
      setLang([]);
      setQualification([]);
      setCarreerAspiration('');
      console.error(error);
    } finally {
      setLoadingSummary(false);
    }
  };

  const getTalentProfilePersonality = async () => {
    try {
      setLoadingPersonality(true);
      const response = await fetchTalentProfilePersonality({ roleUser, id, tokenTalentProfile });
      let result = response?.data?.result;
      if (result && response.data.code === 200) {
        if (Object.keys(result).length > 0) {
          const rankField = 'personality_rank';
          const sortedPersonality = sortCollectionByField(
            result,
            rankField,
            PERSONALITY_SORT_ORDER
          );
          setPersonality(sortedPersonality);
        }
      }
    } catch (error) {
      console.error(error);
      setPersonality([]);
    } finally {
      setLoadingPersonality(false);
    }
  };

  const fetchNotes = async (start, count, isViewMore) => {
    setLoadingNotes(true);
    try {
      let response = await getNotes(id, roleUser, { start, count }, tokenTalentProfile);
      let result = response?.data?.result;
      if (result && response.data.code === 200) {
        const data = { totalNotes: result?.totalNotes, data: result?.notes };
        if (!isViewMore) {
          setNotes(data);
          setAllNotes({ totalNotes: 0, data: [] });
          return;
        }
        setAllNotes(data);
      }
    } catch (error) {
      console.error(error);
      setNotes({ totalNotes: 0, data: [] });
      setAllNotes({ totalNotes: 0, data: [] });
    } finally {
      setLoadingNotes(false);
    }
  };
  const handleCheckAddTalentComparison = () => {
    let isVisible = getPermissionsByRole({
      part: 'ADD_TALENT_COMPARISION',
      permissions: user.permissions,
    });
    return Boolean(isVisible);
  };

  const [assessmentDateOptions, setAssessmentDateOptions] = useState([]);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  useEffect(() => {
    if (!tokenTalentProfile) return;
    const fetchDateOptions = async () => {
      try {
        setIsFilterLoading(true);
        const res = await profileManagementApi.getExternalAssessmentDates(
          id,
          roleUser.roleActive.roleId,
          tokenTalentProfile
        );
        if (res.data.code === 200) {
          setAssessmentDateOptions(res.data.result || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFilterLoading(false);
      }
    };

    if (!roleUser.roleActive.roleId) return;
    fetchDateOptions();
  }, [roleUser.roleActive.roleId, id, setAssessmentDateOptions, tokenTalentProfile]);

  const renderEmployeeInsight = () => {
    switch (selectedEmployee.value) {
      case EMPLOYEE_INSIGHTS_OPTIONS.TALENT_SUMMARY:
        return (
          <>
            <div className={styles.distance}>
              <CareerAspiration
                carreerAspiration={carreerAspiration}
                getTalentProfileSummary={getTalentProfileSummary}
                loading={loadingSummary}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div className={styles.distance}>
              <TalentSketch id={id} roleUser={roleUser} tokenTalentProfile={tokenTalentProfile} />
            </div>
            <div className={styles.distance}>
              <Personality
                id={id}
                personality={personality}
                loading={loadingPersonality}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div className={styles.distance}>
              <BoardMembership
                langAndBoard={langAndBoard}
                loading={loadingSummary}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div className={styles.distance}>
              <Qualification
                qualification={qualification}
                loading={loadingSummary}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div className={styles.distance}>
              <ApprovedSP id={id} roleUser={roleUser} tokenTalentProfile={tokenTalentProfile} />
            </div>
            <div className={styles.distance}>
              <SpokenLanguage
                langAndBoard={lang}
                loading={loadingSummary}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
          </>
        );
      case EMPLOYEE_INSIGHTS_OPTIONS.TALENT_DEVELOPMENT_PLAN:
        return (
          <>
            <div>
              <DevelopmentPlan profileId={id} roleUser={roleUser} />
            </div>
            <div style={{ marginTop: '40px' }}>
              <MobilityPlan
                profileId={id}
                roleUser={roleUser}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <ContractDetails
                profileId={id}
                roleUser={roleUser}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>

            <div style={{ marginTop: '40px' }}>
              <Assessment
                profileId={id}
                roleUser={roleUser}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <HistoricalTalentStatus profileId={id} tokenTalentProfile={tokenTalentProfile} />
            </div>
            <div style={{ marginTop: '40px' }}>
              <WorkPreference profileId={id} tokenTalentProfile={tokenTalentProfile} />
            </div>
          </>
        );
      case EMPLOYEE_INSIGHTS_OPTIONS.RIGHT_FIT_RECOMMENDATION:
        return (
          <div>
            <BestFitRecommendation
              profileId={id}
              roleUser={roleUser}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
        );
      case EMPLOYEE_INSIGHTS_OPTIONS.EXPERIENCES:
        return (
          <>
            <div>
              <Experiences
                profileId={id}
                roleUser={roleUser}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <ExternalExperience
                staffId={id}
                role={roleUser.roleActive}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <OtherExperience
                profileId={id}
                roleUser={roleUser}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <OtherContribution
                profileId={id}
                roleUser={roleUser}
                isPrintPdf={false}
                tokenTalentProfile={tokenTalentProfile}
              />
            </div>
          </>
        );
      case EMPLOYEE_INSIGHTS_OPTIONS.LEADERSHIP_COMPETENCIES:
        return (
          <div>
            <EDGEView id={id} roleUser={roleUser} tokenTalentProfile={tokenTalentProfile} />
          </div>
        );
      case EMPLOYEE_INSIGHTS_OPTIONS.EXTERNAL_ASSESSMENT:
        return (
          <div>
            <ExternalAssessments
              assessmentDateOptions={assessmentDateOptions}
              isFilterLoading={isFilterLoading}
              staffId={id}
              tokenTalentProfile={tokenTalentProfile}
            />
          </div>
        );
      default:
        break;
    }
  };
  const closeModal = () => {
    setIsPrinting(false);
  };

  const checkPermittedStaff = async () => {
    try {
      let response = await fetchCheckPermittedStaff({ roleUser, id, tokenTalentProfile });
      if (response?.data && response.data.code !== 200) {
        setIsHasEditPermission(false);
        return;
      }

      const rs = response.data.result;
      setIsHasEditPermission(rs);
    } catch (e) {
      setIsHasEditPermission(false);
    }
  };

  if (!tokenTalentProfile) return <></>;

  return (
    <div className={styles.maxWidth}>
      <div className={styles.control}>
        <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
        <GlobalAlertMessage style={{ margin: '25px 0 30px 0' }} />

        <h3 className={styles.title}>Talent Profile</h3>
        <div className={styles.listBtnLeft}>
          <button
            className={styles.itemBtn}
            onKeyDown={() => {}}
            onClick={() => setIsPrinting(true)}
          >
            Print Profile
          </button>
          {handleCheckAddTalentComparison() && (
            <span
              href=""
              className={styles.itemBtn}
              onKeyDown={() => {}}
              onClick={() => setIsPopupTalentComparison(true)}
            >
              Compare Talent
            </span>
          )}
        </div>
        {isPopupTalentComparison && (
          <TalentComparison
            talentProfileDetails={talentProfileDetails}
            isPopupTalentComparison={isPopupTalentComparison}
            setIsPopupTalentComparison={setIsPopupTalentComparison}
          />
        )}
      </div>

      {!isMeetingPage ? (
        <>
          <div className={styles.overallProfileUser}>
            <OverAllProfileUser />
          </div>

          <div className={styles.containerPage}>
            {selectedEmployee.value === EMPLOYEE_INSIGHTS_OPTIONS.TALENT_SUMMARY && (
              <div className={styles.Card}>
                <div className={styles.DisplayHeader}>
                  <div className={styles.titleCard}>Employee Insights</div>
                  <div className={styles.DisplayHeader}>
                    <span className={styles.TextLabel}>Show:</span>
                    <DropdownCustomEmployee
                      setSelected={setSelectedEmployee}
                      selected={selectedEmployee}
                      options={dropdownList}
                    />
                  </div>
                </div>
                {selectedEmployee?.value === EMPLOYEE_INSIGHTS_OPTIONS.RIGHT_FIT_RECOMMENDATION && (
                  <Disclaimer dataContent={TALENT_PROFILE_DISCLAIMER} />
                )}
                <div>
                  <CareerJourney
                    id={id}
                    roleUser={roleUser}
                    setCareerJourneyData={() => {}}
                    noDetail={false}
                    customStyle={{}}
                    tokenTalentProfile={tokenTalentProfile}
                  />
                </div>
              </div>
            )}
            <div className={styles.Talentprofile}>
              <div className={styles.Talentprofile__left}>
                <div className={styles.Card}>
                  {selectedEmployee.value !== EMPLOYEE_INSIGHTS_OPTIONS.TALENT_SUMMARY && (
                    <div className={styles.DisplayHeader}>
                      <div className={styles.titleCard}>Employee Insights</div>
                      <div className={styles.DisplayHeader}>
                        <span className={styles.TextLabel}>Show:</span>
                        <DropdownCustomEmployee
                          setSelected={setSelectedEmployee}
                          selected={selectedEmployee}
                          options={dropdownList}
                        />
                      </div>
                    </div>
                  )}
                  {selectedEmployee?.value ===
                    EMPLOYEE_INSIGHTS_OPTIONS.RIGHT_FIT_RECOMMENDATION && (
                    <Disclaimer dataContent={TALENT_PROFILE_DISCLAIMER} />
                  )}
                  <div style={{ marginTop: '39px' }}>{renderEmployeeInsight()}</div>
                </div>
              </div>
              <div className={styles.Talentprofile__right}>
                <Notes
                  profileId={id}
                  notes={notes}
                  allNotes={allNotes.data}
                  fetchNotes={fetchNotes}
                  roleUser={roleUser}
                  loadingNotes={loadingNotes}
                  tokenTalentProfile={tokenTalentProfile}
                />
                <TalentReview
                  profileId={id}
                  roleUser={roleUser}
                  tokenTalentProfile={tokenTalentProfile}
                />
                <Documents
                  profileId={id}
                  roleUser={roleUser}
                  isHasEditPermission={isHasEditPermission}
                  tokenTalentProfile={tokenTalentProfile}
                />
                <TalentVideo
                  profileId={id}
                  roleId={roleUser.roleActive.roleId}
                  tokenTalentProfile={tokenTalentProfile}
                />
              </div>
            </div>
          </div>
          <TalentProfilePrintPreview
            showEdge={true}
            carreerAspiration={carreerAspiration}
            personality={personality}
            langAndBoard={langAndBoard}
            qualification={qualification}
            lang={lang}
            roleUser={roleUser}
            isPrinting={isPrinting}
            closeModal={closeModal}
            tokenTalentProfile={tokenTalentProfile}
          />
        </>
      ) : (
        // this code of page meeting detail
        <>
          <div className={styles.meeting_details}>
            <div className={styles.header_meeting_details}>
              <div className={styles.text_header}>Meeting Details</div>
              <div className={styles.frame_status}>
                <div className={styles.status}></div>
                <div className={styles.text_status}>In-Planning</div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.title_body}>
                    Meeting Name<span style={{ color: 'red' }}>*</span>
                  </div>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Board Management Meeting"
                    onChange={(e) =>
                      setMeetingDetail({ ...meetingDetails, meetingName: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-3">
                  <div className={styles.title_body}>
                    Date<span style={{ color: 'red' }}>*</span>
                  </div>
                  <div className={styles.dropdown} ref={ref}>
                    <div
                      className={styles.dropdownBtn}
                      onKeyDown={() => {}}
                      onClick={(e) => setIsActive(!isActive)}
                    >
                      {meetingDetails?.date ? (
                        moment(meetingDetails?.date).format('D MMMM YYYY')
                      ) : (
                        <div className={styles.text_placeholder}>
                          {moment().format('D MMMM YYYY')}
                        </div>
                      )}

                      <div>
                        <img src={CalendarMeeting} alt="" />
                      </div>
                    </div>

                    <div className={styles.dropdownContent}>
                      {isActive && (
                        <Datetime
                          className={styles.dropdownContentDatePicker}
                          input={false}
                          timeFormat={false}
                          onChange={(e) => handleChangeDate(e)}
                          dateFormat="YYYY-MM-DD"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.title_body}>
                    Location<span style={{ color: 'red' }}>*</span>
                  </div>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Level 26, Twin Tower"
                    onChange={(e) =>
                      setMeetingDetail({ ...meetingDetails, location: e.target.value })
                    }
                  />
                </div>

                <div className="col-3">
                  <div className="row">
                    <div className="col-6">
                      <div className={styles.title_body}>
                        Start time<span style={{ color: 'red' }}>*</span>
                      </div>
                      <input
                        type="text"
                        className={styles.input}
                        placeholder="12:00 PM"
                        onChange={(e) =>
                          setMeetingDetail({ ...meetingDetails, startTime: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-6">
                      <div className={styles.title_body}>
                        End time<span style={{ color: 'red' }}>*</span>
                      </div>
                      <input
                        type="text"
                        className={styles.input}
                        placeholder="12:00 PM"
                        onChange={(e) =>
                          setMeetingDetail({ ...meetingDetails, endTime: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-6">
                  <div className={styles.title_body}>
                    Meeting Category<span style={{ color: 'red' }}>*</span>
                  </div>
                  <DropdownMeetingCategory
                    selected={meetingDetails?.category}
                    setSelected={(e) => setMeetingDetail({ ...meetingDetails, category: e })}
                    placeholder="High Level"
                    options={mock_data_category}
                  />
                </div>

                <div className="col-6">
                  <div className={styles.title_body}>
                    Status Meeting<span style={{ color: 'red' }}>*</span>
                  </div>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Active"
                    onChange={(e) =>
                      setMeetingDetail({ ...meetingDetails, status: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.title_body}>
                    Chairman<span style={{ color: 'red' }}>*</span>
                  </div>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Sani Zuhairi Bin Zainudin"
                    onChange={(e) =>
                      setMeetingDetail({ ...meetingDetails, chairman: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.title_body}>
                    Committee Members<span style={{ color: 'red' }}>*</span>
                  </div>

                  <SelectMultipleSearch
                    styles={customStyles}
                    isMulti
                    cacheOptions
                    loadOptions={promiseOptions}
                    placeholder="Committee Members"
                    onChange={(e) => setMeetingDetail({ ...meetingDetails, committeeMembers: e })}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.title_body}>
                    Meeting Facilitators<span style={{ color: 'red' }}>*</span>
                  </div>

                  <SelectMultipleSearch
                    styles={customStyles}
                    isMulti
                    cacheOptions
                    loadOptions={promiseOptions}
                    placeholder="Meeting Facilitators"
                    onChange={(e) => setMeetingDetail({ ...meetingDetails, facilitators: e })}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: '24px' }}>
              <div className="row">
                <div className="col-12">
                  <div className={styles.title_body}>
                    Meeting Secretariat<span style={{ color: 'red' }}>*</span>
                  </div>

                  <SelectMultipleSearch
                    styles={customStyles}
                    isMulti
                    cacheOptions
                    loadOptions={promiseOptions}
                    placeholder="Meeting Secretariat"
                    onChange={(e) => setMeetingDetail({ ...meetingDetails, secretariat: e })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ gap: '17px', marginBottom: '30px' }}>
            <div className={styles.btn_white_success_planning}>
              <div
                className={styles.text_btn}
                style={{ color: '#00a19c' }}
                //  onKeyDown={() => {}} onClick={() => history.push('/agenda')}
              >
                Cancel
              </div>
            </div>
            <div className={styles.btn_green_success_planning}>
              <div
                className={styles.text_btn}
                style={{ color: '#ffffff' }}
                onKeyDown={() => {}}
                onClick={() => setIsPopupMeetingDetails(true)}
              >
                Save
              </div>
            </div>
          </div>
          <PopupMeetingDetails
            isPopupMeetingDetails={isPopupMeetingDetails}
            setIsPopupMeetingDetails={setIsPopupMeetingDetails}
            onSaveDetails={onSaveDetails}
          />
        </>
      )}
    </div>
  );
};

export default TalentProfile;
