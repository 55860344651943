import { Table } from 'antd';
import styled from 'styled-components';

export const AiTable = styled(Table)`
  .ant-table-container {
    input[type='checkbox'] {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    input[type='checkbox']:checked {
      accent-color: #00a19c;
      padding: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #00a19c;
      border-color: #00a19c;
    }

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        background-color: #fff;
        border-color: #d9d9d9;

        &:after {
          background-color: #00a19c;
        }
      }

      .ant-checkbox-inner:after {
        background-color: white;
      }
    }

    th,
    td {
      border-right: solid 1px #d3dee8;
      border-bottom-color: #d3dee8;

      &.noColumn,
      &:first-child {
        border-right: none;
      }
    }

    tr.ant-table-row-selected > td {
      border-color: #d3dee8 !important;
    }
  }

  .ant-table-thead {
    tr {
      th {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #8896a7;

        &::before {
          display: none;
        }
      }
    }
  }

  .ant-table-tbody {
    .normalContent {
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #181818;
    }

    .customInput {
      border: 1px solid #d3dee8;
      border-radius: 4px;
      box-shadow: none;
      outline: none;
      padding: 10px;
      width: 100%;

      &::placeholder {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    & > tr.ant-table-row-selected > td {
      background-color: white;
    }
  }
  .ant-table-sticky-scroll {
    display: none;
  }

  .itemTitle {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    position: relative;
    cursor: pointer;

    span {
      display: block;
    }

    .title {
      font-weight: 700;
      color: #3f3c4c;
    }

    .staffId {
      font-weight: 400;
      color: #999a9c;
    }

    .avatar {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      object-fit: cover;
    }

    .expandBtn {
      background: transparent;
      border: none;
      outline: none;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .itemContent {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #575757;
  }

  .listViewItem {
    display: flex;
    flex-direction: column;

    .itemTitle {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .title {
        margin-left: 10px;
      }
    }

    .itemContent {
      padding-left: 48px;
    }
  }

  .cardViewItem {
    display: flex;
    align-items: stretch;
    border: solid 1px #e7e7f0;
    border-radius: 6px;
    min-width: 365px;

    .itemTitle {
      background-color: #d9f2f7;
      border: solid 1px #e7e7f0;
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 83px;
      text-align: center;

      .title {
        padding-top: 10px;
      }

      .itemNumber {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        letter-spacing: 0px;
        color: #3f3c4c;
        margin-bottom: 10px;
      }

      .staffId {
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        margin-bottom: 5px;
        color: #525252;
      }
    }

    .itemContent {
      padding: 10px;

      .staffName {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #50a4b9;
        margin-bottom: 10px;
      }
    }

    .positionContent {
      font-size: 10px;
      margin-top: 5px;
      text-align: center;
    }

    .roleList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 5px 10px;
      span {
        display: inline-block;
        width: 50%;
        font-family: 'Inter';
        font-size: 8px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #525252;
      }
    }
  }

  .drag-handle:hover {
    cursor: grabbing;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #fff;
  }
`;
