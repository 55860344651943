import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { edit_report_name, right_open_arrow } from '../../assets/img';
import { PERMISSION } from '../../utils/constants';
import CommonLoading from '../CommonLoading/CommonLoading';
import styles from './career-aspiration.module.scss';
import CareerAspirationEdit from './CareerAspirationEdit';
import CareerAspirationModalTable from './CareerAspirationModalTable';

const CareerAspiration = ({
  carreerAspiration,
  isFromTalentReview,
  isPrinting,
  getTalentProfileSummary,
  loading,
}) => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const roleUser = { roleActive: user.roleActive, permissions: user.permissions };
  const { desiredCareerPath, aspirationMatching } = carreerAspiration;
  const [openModal, setOpenModal] = useState(false);
  const [aspirationMatchingEdit, setAspirationMatchingEdit] = useState({
    open: false,
    mode: '',
  });

  const editPermission = useMemo(() => {
    return (
      !isEmpty(roleUser?.permissions) &&
      roleUser.permissions.includes(PERMISSION.EDIT_ASPIRATION_MATCHING)
    );
  }, [roleUser]);

  const handleOpenModalView = () => {
    setOpenModal(true);
  };

  return (
    <div
      className={styles.container}
      style={
        !(isPrinting && isFromTalentReview)
          ? { backgroundColor: '#f4f5f8' }
          : { backgroundColor: '#ffffff' }
      }
    >
      <div className={styles.careerStatement}>
        {!(isPrinting && isFromTalentReview) && (
          <h3 className={`${isFromTalentReview ? styles.title : ''}`}>Career Statement </h3>
        )}

        <div>
          {!loading ? (
            !isEmpty(desiredCareerPath) &&
            desiredCareerPath.map((item, index) => (
              <p
                className={`${styles.item} ${isFromTalentReview ? styles.content : ''}`}
                key={index}
              >
                {item.career_statement || item.aspiration || ''}
              </p>
            ))
          ) : (
            <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
              <CommonLoading />
            </div>
          )}
        </div>
      </div>
      {!isFromTalentReview && (
        <div className={styles.aspiration}>
          <Divider className={styles.divider} />
          <div className={styles.aspirationMatching}>
            <h3>Aspiration Matching</h3>
            <div className={styles.tags}>
              {!loading ? (
                !isEmpty(aspirationMatching) &&
                aspirationMatching.map((tag, index) => (
                  <div key={index} className={styles.item}>
                    {tag}
                  </div>
                ))
              ) : (
                <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
                  <CommonLoading />
                </div>
              )}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.disclaimer}>
              NOTES: Data derived from the results of AI Talent Aspiration
            </div>
            {!isPrinting && (
              <div className={styles.openModal}>
                <div className={styles.view} onKeyDown={() => {}} onClick={handleOpenModalView}>
                  <span>VIEW ASPIRATION</span>
                  <img src={right_open_arrow} alt="open" />
                </div>
                {editPermission && (
                  <img
                    src={edit_report_name}
                    alt="edit"
                    className={styles.edit}
                    onKeyDown={() => {}}
                    onClick={() =>
                      setAspirationMatchingEdit({
                        open: true,
                        mode: 'edit',
                      })
                    }
                    // onClick={() => setOpenModalEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
          {openModal && (
            <CareerAspirationModalTable openModal={openModal} setOpenModal={setOpenModal} />
          )}
          {aspirationMatchingEdit.open && (
            <CareerAspirationEdit
              aspirationMatchingEdit={aspirationMatchingEdit}
              setAspirationMatchingEdit={setAspirationMatchingEdit}
              roleId={roleUser.roleActive?.roleId}
              permissions={roleUser.permissions}
              staffId={id}
              getTalentProfileSummary={getTalentProfileSummary}
            />
          )}
        </div>
      )}
    </div>
  );
};

CareerAspiration.propTypes = {
  isFromTalentReview: PropTypes.bool,
  isPrinting: PropTypes.bool,
  getTalentProfileSummary: PropTypes.func,
  loading: PropTypes.bool,
};

export default memo(CareerAspiration);
