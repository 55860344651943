import styled from 'styled-components';
import { Input, List } from 'antd';

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  width: fit-content;
`;
export const WrapperPopUp = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const WrapperPopUpImage = styled.div`
  position: absolute;
  z-index: 1000;
  top: 30px;
  transform: translateX(-26%);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const TitleDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 311px);
  border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
  background: var(--Light-Blue-Grays-100, #f2f4f8);
  height: 44px;
  div {
    color: #181818;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 44px; /* 142.857% */
    letter-spacing: 0.235px;
  }
`;

export const ListAntd = styled(List)`
  .ant-list-items {
    li:first-child {
      height: auto !important;
    }
    .ant-list-item {
      cursor: pointer;
      display: grid;
      grid-template-columns: repeat(3, 311px);
      border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
      background: var(--Base-White, #fff);
      padding: 0;
      &:hover {
        background-color: #f2f4f8;
      }
      div {
        color: var(--Base-Black, #181818);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.235px;
        padding: 12px 0;
        padding-left: 20px;
      }
      .function-name {
        background-color: #f2f4f8;
      }
    }
  }
`;
export const InputStyle = styled(Input)`
  border-radius: 4px;
  border: 1px solid ${(props) => (props.isError ? '#DA2228' : '#d9d9d9')};
  :hover {
    border: 1px solid ${(props) => (props.isError ? '#DA2228' : '#40a9ff')} !important;
  }
  width: 450px;
  /*  border: 1px solid #d9d9d9;
  background: #fff; */
  .ant-input {
    outline: none !important;
    border: none !important;
  }
  padding: 0;
  input {
    padding: 10px 0 !important;
  }
  .ant-input-prefix {
    margin: 0 !important;
  }
  .ant-input-suffix {
    margin: 0 !important;
  }
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const Selected = styled.div`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  /* Shadow sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 250px;
  gap: 10px;
  .truncate {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
