import { Spin, Tooltip } from 'antd';
import { get, isArray, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { chevron_down_complementary } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { MODULE_FILTER_TABLE } from '../../utils/constants';
import { groupComplementary } from '../../utils/helper';
import CustomFilterTable from '../CustomFilterTable';
import {
  CustomNumber,
  Legend1,
  Legend2,
  LegendText,
  TableData,
  TableDiv,
  Text,
  Title,
  Wrapper,
  WrapperLegend,
} from './complementaryExperienceStyled';

export default function ComplementaryExperienceView(props) {
  const {
    complementary,
    setComplementary,
    roleId,
    staffId,
    filterDropdown,
    getFilterComplementaryExperience,
  } = props;
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobfunctionSelected, setJobfunctionSelected] = useState([]);

  useEffect(() => {
    if (!roleId || !staffId) return;
    getFilterComplementaryExperience(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, staffId]);

  const handleReturnFilter = (data, setListSelected, listSelected, onApply, close, filterName) => {
    return (
      <CustomFilterTable
        setSelectedKeys={setListSelected}
        selectedKeys={listSelected}
        filters={
          (!isEmpty(data) &&
            isArray(data) &&
            data.map((item) => ({
              value: item.value,
              text: item.label,
              isChecked: item.value,
            }))) ||
          []
        }
        onApplyFilters={onApply}
        isLoading={false}
        close={close}
        moduleName={MODULE_FILTER_TABLE.COMPLEMENTARY_EXPERIENCE}
        filterName={filterName}
      />
    );
  };

  const onApplyJobFunction = useCallback(
    (filters) => {
      setQuery((prev) => ({
        ...prev,
        page: 1,
      }));
      setJobfunctionSelected(filters);
    },
    [setJobfunctionSelected]
  );

  const columns = [
    {
      title: 'Job Function',
      dataIndex: 'job_function',
      key: 'job_function',
      width: '25%',
      filterIcon: <img src={chevron_down_complementary} alt="" />,
      filterDropdown: ({ _clearFilters, close }) =>
        handleReturnFilter(
          filterDropdown?.job_function,
          setJobfunctionSelected,
          jobfunctionSelected,
          onApplyJobFunction,
          close,
          'job_function'
        ),
    },
    {
      title: 'Confidence Score',
      dataIndex: 'confidence_score',
      key: 'confidence_score',
      width: '15%',
    },
    {
      title: 'Sub-Statement',
      dataIndex: 'sub_statement',
      key: 'sub_statement',
      width: '60%',
    },
  ];

  useEffect(() => {
    if (!roleId || !staffId) return;

    (async function getView() {
      setLoading(true);
      try {
        const res = await talentProfileApi.viewComplementaryExperience(
          staffId,
          { page: query.page, limit: query.limit, job_function: jobfunctionSelected || [] },
          roleId
        );
        const data = get(res, 'data.result.matchingOutput') || [];
        const total = get(res, 'data.result.total') || 0;
        setData(groupComplementary(data));
        setQuery((prev) => ({ ...prev, total }));
      } catch (error) {
        setData([]);
        setQuery({
          page: 1,
          total: 0,
          limit: 10,
        });
        console.error(error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, query.page, staffId, jobfunctionSelected]);

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  return (
    <Wrapper
      title={<Title>View Complementary Experience</Title>}
      open={complementary}
      onCancel={() => setComplementary(false)}
      width={1450}
      footer={null}
    >
      <WrapperLegend>
        <div className="d-flex justify-content-start align-items-center mr-3">
          <Legend1 />
          <LegendText>Model Output</LegendText>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Legend2 />
          <LegendText>Updated by Admin</LegendText>
        </div>
      </WrapperLegend>
      <Spin size="large" spinning={loading}>
        <TableDiv>
          <TableData
            getPopupContainer={(trigger) => trigger.parentElement}
            rowClassName={(record) => {
              return record?.isFirstRow ? 'border' : '';
            }}
            dataSource={
              !isEmpty(data) &&
              data.map((item, _index) => ({
                key: uuidv4(),
                isFirstRow: item?.isFirstRow,
                totalRowSpan: item?.totalRowSpan,
                job_function: (
                  <div className="d-flex justify-content-between align-items-center">
                    {item.is_updated === false ? <Legend1 /> : <Legend2 />}
                    <Text className="col-10">{item.job_function}</Text>
                  </div>
                ),
                confidence_score: (
                  <Text>
                    {item.confidence_score && Number.parseFloat(item.confidence_score).toFixed(2)}
                  </Text>
                ),
                sub_statement: (
                  <>
                    {item?.isFirstRow || _index % 10 === 0 ? (
                      <Tooltip
                        title={item.gtky_text && <div>GTKY Text: {item.gtky_text}</div>}
                        {...{
                          overlayInnerStyle: {
                            width: '500px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            fontFamily: '"Inter", sans-serif',
                            padding: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#000',
                          },
                        }}
                      >
                        <Text>{item.sub_statement}</Text>
                      </Tooltip>
                    ) : (
                      <div></div>
                    )}
                  </>
                ),
              }))
            }
            columns={columns}
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </TableDiv>
      </Spin>
    </Wrapper>
  );
}
