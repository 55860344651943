import { Dropdown, Space, Spin, Tooltip } from 'antd';
import { get, isArray, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { BasicButton } from '../../assets/common';
import { chevron_down_complementary, close, deleteNote, edit_delete } from '../../assets/img';
import { useShowPopupAspiration } from '../../hooks/useShowPopupAspiration';
import talentProfileApi from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  GLOBAL_ALERT_MESSAGE_CONTAINER_ID,
  MODULE_FILTER_TABLE,
} from '../../utils/constants';
import {
  convertToAddEditComplementary,
  convertToCompareComplementary,
  groupComplementary,
  insertAtIndex,
  returnContentHideStatus,
} from '../../utils/helper';
import CustomFilterTable from '../CustomFilterTable';
import DescForWithoutModalMatching from '../DescForWithoutModalMatching/DescForWithoutModalMatching';
import GlobalAlertMessage from '../GlobalAlertMessage/GlobalAlertMessage';
import { ModelTC } from '../Model';
import SearchJobFunctionComplementary from '../SearchJobFunctionComplementary/SearchJobFunctionComplementary';
import {
  CustomNumber,
  HideStatus,
  Legend1,
  Legend2,
  LegendText,
  SelectAnt,
  TableData,
  TableDiv,
  Text,
  Title,
  Wrapper,
  WrapperLegend,
  CancelButton,
} from './complementaryExperienceStyled';
import { updateIsError } from '../../store/pageSlice';
import { useShowAlertWhenRefresh } from '../../hooks/useShowAlertWhenRefresh';

const FORM_TYPES = {
  VIEW: 'view',
  EDIT: 'edit',
};

export default function ComplementaryExperienceEdit(props) {
  const {
    complementaryEdit,
    setComplementaryEdit,
    roleId,
    staffId,
    permissions,
    getCareerJourny,
    filterDropdown,
    getFilterComplementaryExperience,
  } = props;

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState();

  const thresholdVal = 0.82;
  const dataChanges = useMemo(() => {
    return convertToCompareComplementary(data, staffId);
  }, [data, staffId]);

  const {
    isShowPopupSaveAgenda,
    setIsShowPopupSaveAgenda,
    handleKeepNavigate,
    navigatingPathname,
  } = useShowPopupAspiration(dataChanges);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    setData(groupComplementary(data.map(({ isFirstRow, totalRowSpan, ...item }) => item)));
  }, [JSON.stringify(data)]);

  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [jobfunctionSelected, setJobfunctionSelected] = useState([]);
  const [hideSelected, setHideSelected] = useState([]);
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });

  const onApplyJobFunction = useCallback(
    (filters) => {
      setQuery((prev) => ({
        ...prev,
        page: 1,
      }));
      setJobfunctionSelected(filters);
    },
    [setJobfunctionSelected]
  );

  const onApplyHide = useCallback(
    (filters) => {
      setHideSelected(filters);
    },
    [setHideSelected]
  );

  const handleReturnFilter = (data, setListSelected, listSelected, onApply, close, filterName) => {
    return (
      <CustomFilterTable
        setSelectedKeys={setListSelected}
        selectedKeys={listSelected}
        filters={
          (!isEmpty(data) &&
            isArray(data) &&
            data.map((item) => ({
              value: item.value,
              text: item.label,
              isChecked: item.value,
            }))) ||
          []
        }
        onApplyFilters={onApply}
        isLoading={false}
        close={close}
        moduleName={MODULE_FILTER_TABLE.COMPLEMENTARY_EXPERIENCE}
        filterName={filterName}
      />
    );
  };

  const columns = [
    {
      title: 'Job Function',
      dataIndex: 'job_function',
      key: 'job_function',
      width: '20%',
      onCell: (record, _rowIndex) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.id ? 2 : 1,
        };
      },
      filterIcon: <img src={chevron_down_complementary} alt="" />,
      filterDropdown: ({ _clearFilters, close }) =>
        handleReturnFilter(
          filterDropdown?.job_function,
          setJobfunctionSelected,
          jobfunctionSelected,
          onApplyJobFunction,
          close,
          'job_function'
        ),
    },
    {
      title: 'Confidence Score',
      key: 'confidence_score',
      dataIndex: 'confidence_score',
      width: '10%',
      onCell: (record, _rowIndex) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.id ? 0 : 1,
        };
      },
    },
    {
      title: 'Sub-Statement',
      key: 'sub_statement',
      dataIndex: 'sub_statement',
      width: '45%',
      onCell: (record, _rowIndex) => {
        return {
          rowSpan: record?.isFirstRow ? record?.totalRowSpan : 0,
          colSpan: record?.isSearching && !record.isJobFunction ? 0 : 1,
        };
      },
    },
    {
      title: 'Hide',
      dataIndex: 'show_hide',
      key: 'show_hide',
      width: '15%',
      // onCell: (record, _rowIndex) => {
      //   return {
      //     colSpan: record?.isSearching && !record.isJobFunction ? 0 : 1,
      //   };
      // },
      filterIcon: <img src={chevron_down_complementary} alt="" />,
      filterDropdown: ({ _clearFilters, close }) =>
        handleReturnFilter(
          filterDropdown?.hide,
          setHideSelected,
          hideSelected,
          onApplyHide,
          close,
          'show_hide'
        ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      onCell: (record, _rowIndex) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.id ? 0 : 1,
        };
      },
    },
  ];

  async function getView() {
    setLoading(true);
    try {
      const res = await talentProfileApi.viewEditComplementaryExperience({
        staffId,
        roleId,
        job_function: jobfunctionSelected || [],
        hide: hideSelected || [],
      });
      const data = get(res, 'data.result.matchingOutput') || [];
      const total = get(res, 'data.result.total') || 0;
      setData(data);
      setQuery((prev) => ({ ...prev, total }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!roleId || !staffId) return;
    getFilterComplementaryExperience(true);
    getView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, staffId, jobfunctionSelected, hideSelected]);

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  async function saveComplementary() {
    dispatch(updateIsError(false));
    setLoading(true);
    setComplementaryEdit({ mode: 'view', open: true });
    try {
      // data for saving
      const body = convertToAddEditComplementary(data, staffId);
      if (isEmpty(body)) {
        setLoading(false);
        const index = data.findIndex((f) => !f.job_function);
        const lastIndex = data.length - 1;
        setQuery((prev) => ({
          ...prev,
          page: index % 10 === 0 && index === lastIndex ? prev.page - 1 : prev.page,
          total: data.filter((f) => f.job_function)?.length,
        }));
        setData((prev) => [...prev].filter((f) => f.job_function));
        return;
      }
      await talentProfileApi.saveComplementaryExperience(body, roleId);
      handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, 'The changes have been saved successfully.');
      getView();
      getCareerJourny();
      getFilterComplementaryExperience(true);
      setShowAlertRefresh(false);
    } catch (error) {
      console.error(error);
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, `An unexpected error occurred.`);
    }
  }

  function addJobFunction({ item, _index, job_function_value, _type, mode }) {
    if (data.some((d) => !d?.job_function)) {
      dispatch(updateIsError(true));
      handleShowMessage(
        ALERT_MESSAGE_TYPE.ERROR,
        `There is item that require your attention. Please fill out this field.`
      );
      return;
    }
    const { sub_statement, original_job_function } = item;
    const body = {
      id: uuidv4(),
      prev_id: item?.id ?? null,
      sub_statement,
      confidence_score: 1,
      is_updated: true,
      is_hide: false,
      original_job_function,
      job_function: job_function_value ? job_function_value : '',
      isSearching: true,
      isEditing: mode !== 'edit' ? false : true,
      edit_type: 'hide',
    };
    const newData = insertAtIndex(data, _index + 1, body);
    setData(newData);
    setQuery((prev) => ({ ...prev, total: prev.total + 1 }));
  }

  const handleShowMessage = (type, message, description) => {
    dispatch(
      pushMessage({
        type,
        message,
        description,
        timeShow: 0,
        containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_COMPLEMENTARY,
      })
    );
  };

  function handleCancel() {
    if (isEmpty(dataChanges)) {
      dispatch(updateIsError(false));
      setComplementaryEdit({ mode: 'edit', open: false });
    } else {
      setIsShowPopupSaveAgenda(true);
    }
  }

  const handleDeleteRecord = (record) => {
    setSelectedRecord(record);
    setConfirmDeleteModal(true);
  };

  const confirmDeleteRecord = async () => {
    if (!isNaN(Number(selectedRecord?.id))) {
      try {
        setLoading(true);
        const res = await talentProfileApi.deleteDevelopmentPlan(selectedRecord.id, roleId);
        if (res.status === 200) {
          resetDataAfterDeletedRecord();
          getCareerJourny();
        }
      } catch (error) {
        console.error(error);
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, `An unexpected error occurred.`);
      } finally {
        setLoading(false);
      }
    } else {
      const count = data.filter((f) => f.prev_id === selectedRecord.prev_id)?.length;
      if (count === 1) {
        setData((prev) => {
          const parentItem = prev.find((i) => i.id === selectedRecord?.prev_id);
          if (parentItem) {
            parentItem.edit_type = parentItem.originalHide?.original_edit_type;
            parentItem.is_hide = parentItem.originalHide?.value;
          }
          delete parentItem.isEditing;
          delete parentItem.originalHide;
          return [...prev];
        });
      }
      resetDataAfterDeletedRecord();
    }
  };

  const resetDataAfterDeletedRecord = () => {
    setQuery((prev) => ({
      ...prev,
      page: 1,
      total: data.filter((f) => f.id !== selectedRecord.id)?.length,
    }));
    setData((prev) => [...prev].filter((f) => f.id !== selectedRecord.id));
    handleShowMessage(
      ALERT_MESSAGE_TYPE.SUCCESS,
      `Job function “${selectedRecord?.job_function}” successfully deleted from complementary experience .`
    );
    setConfirmDeleteModal(false);
    setSelectedRecord(null);
  };

  return (
    <Wrapper
      title={
        <div>
          <div style={{ marginBottom: '20px' }}>
            <GlobalAlertMessage id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_COMPLEMENTARY} />
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <Title>Edit Complementary Experience</Title>
            <div style={{ cursor: 'pointer' }} onClick={() => handleCancel()} onKeyDown={() => {}}>
              <img src={close} alt="close" />
            </div>
          </div>
        </div>
      }
      open={complementaryEdit.open}
      onCancel={() => handleCancel()}
      width={1450}
      footer={null}
      closable={false}
    >
      <WrapperLegend>
        <div className="d-flex justify-content-start align-items-center mr-3">
          <Legend1 />
          <LegendText>Model Output</LegendText>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Legend2 />
          <LegendText>Updated by Admin</LegendText>
        </div>
      </WrapperLegend>
      <Spin size="large" spinning={loading}>
        <TableDiv>
          <TableData
            getPopupContainer={(trigger) => trigger.parentElement}
            tableEdit={complementaryEdit.mode === FORM_TYPES.EDIT && true}
            tableView={complementaryEdit.mode === FORM_TYPES.VIEW && true}
            ghost
            columns={
              complementaryEdit.mode === FORM_TYPES.VIEW
                ? columns.filter((column) => column.key !== 'show_hide' && column.key !== 'action')
                : columns
            }
            rowClassName={(record) => {
              return record?.isFirstRow ? 'border' : '';
            }}
            dataSource={
              !isEmpty(data) &&
              data
                .slice((query.page - 1) * query.limit, query.page * query.limit)
                .map((item, _index) => ({
                  key: item?.id,
                  id: item?.id,
                  isFirstRow: item?.isFirstRow,
                  totalRowSpan: item?.totalRowSpan,
                  isSearching: item?.isSearching,
                  isJobFunction: item?.job_function,
                  job_function: (
                    <>
                      {item?.isSearching ? (
                        <div className="d-flex justify-content-between align-items-center">
                          <SearchJobFunctionComplementary
                            roleId={roleId}
                            permissions={permissions}
                            item={item}
                            openDropdown={isOpen}
                            setOpenDropdown={setIsOpen}
                            setValue={({ val }) => {
                              const isExist = [...data]
                                .filter((f) => f.sub_statement === item.sub_statement)
                                .find((item) => item.job_function === val);
                              if (isExist) {
                                handleShowMessage(
                                  ALERT_MESSAGE_TYPE.WARNING,
                                  `Job Function "${val}" already exists in this sub-statement.`,
                                  'Please ensure this Job Function is not duplicated in same sub-statement in order to proceed with editing.'
                                );
                                return;
                              }

                              if (item?.isEditing) {
                                setData((prev) => {
                                  const tempData = prev.filter((i) => i.id !== item.id);
                                  const itemSelected = tempData.find((i) => i.id === item?.prev_id);
                                  itemSelected.job_function = val;
                                  itemSelected.isEditing = true;
                                  itemSelected.is_updated = true;
                                  return [...tempData];
                                });
                              } else {
                                setData((prev) => {
                                  const itemSelected = prev.find((i) => i.id === item.id);
                                  const parentItem = prev.find((i) => i.id === item?.prev_id);
                                  itemSelected.job_function = val;
                                  if (parentItem && isEmpty(parentItem?.originalHide)) {
                                    parentItem.originalHide = {
                                      value: parentItem.is_hide,
                                      original_edit_type: parentItem.edit_type,
                                    };
                                    parentItem.edit_type = 'hide';
                                    parentItem.is_hide = true;
                                    parentItem.isEditing = true;
                                  }
                                  return [...prev];
                                });
                              }
                              setShowAlertRefresh(true);
                              dispatch(updateIsError(false));
                            }}
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          {item.is_updated === false ? <Legend1 /> : <Legend2 />}
                          <Text className="col-10">{item.job_function}</Text>
                        </div>
                      )}
                    </>
                  ),
                  confidence_score: (
                    <Text>
                      {item.confidence_score && Number.parseFloat(item.confidence_score).toFixed(2)}
                    </Text>
                  ),
                  sub_statement: (
                    <>
                      <Tooltip
                        title={item.gtky_text && <div>GTKY Text: {item.gtky_text}</div>}
                        {...{
                          overlayInnerStyle: {
                            width: '500px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            fontFamily: '"Inter", sans-serif',
                            padding: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#000',
                          },
                        }}
                      >
                        <Text>{item.sub_statement}</Text>
                      </Tooltip>
                    </>
                  ),
                  group: item.group,
                  index: _index,
                  show_hide: (
                    <>
                      {!item?.job_function && isOpen !== item.id && (
                        <CancelButton
                          onClick={() => {
                            const index = data.findIndex((f) => f?.id && f.id === item.id);
                            const lastIndex = data.length - 1;
                            setQuery((prev) => ({
                              ...prev,
                              page:
                                index % 10 === 0 && index === lastIndex ? prev.page - 1 : prev.page,
                              total: data.filter((f) => f?.id && f.id !== item.id)?.length,
                            }));
                            setData((prev) => prev.filter((f) => f?.id && f.id !== item.id));
                            dispatch(updateIsError(false));
                          }}
                        >
                          Cancel
                        </CancelButton>
                      )}
                      {item?.job_function && isOpen !== item.id && (
                        <>
                          <SelectAnt
                            labelInValue
                            bordered={false}
                            value={item.is_hide}
                            style={{ width: 70 }}
                            suffixIcon={<img src={chevron_down_complementary} alt="open" />}
                            onChange={(value) => {
                              setData((prev) => {
                                const itemSelected = prev.find((i) => i.id === item.id);
                                itemSelected.is_hide = value.value;
                                if (!itemSelected?.isSearching) itemSelected.isEditing = true;
                                if (!isEmpty(item?.originalHide)) {
                                  if (!item?.originalHide?.isExistEditType) {
                                    delete itemSelected.edit_type;
                                  }
                                  delete itemSelected.isEditing;
                                  delete itemSelected.originalHide;
                                } else if (isEmpty(item?.originalHide)) {
                                  itemSelected.originalHide = {
                                    value: !value.value,
                                    isExistEditType: itemSelected.edit_type
                                      ? itemSelected.edit_type
                                      : '',
                                  };
                                  itemSelected.edit_type = 'hide';
                                }

                                return [...prev];
                              });

                              setShowAlertRefresh(true);
                            }}
                            options={[
                              { value: false, label: 'No' },
                              { value: true, label: 'Yes' },
                            ]}
                          />
                          <HideStatus>{returnContentHideStatus(item, thresholdVal)}</HideStatus>
                        </>
                      )}
                    </>
                  ),
                  action: (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: <div>Add Job Function</div>,
                            key: '0',
                            onClick: () => {
                              addJobFunction({
                                item,
                                _index: (query.page - 1) * query.limit + _index,
                                type: 'isSearching',
                                mode: 'add',
                              });
                            },
                          },
                          {
                            icon: (
                              <img
                                src={deleteNote}
                                alt="delete"
                                style={{
                                  filter:
                                    'invert(25%) sepia(80%) saturate(2560%) hue-rotate(345deg) brightness(94%) contrast(95%)',
                                  fontSize: '14px',
                                }}
                              />
                            ),
                            label: (
                              <div style={{ color: '#EC2E2E', marginLeft: '10px' }}>Delete</div>
                            ),
                            key: '1',
                            onClick: () => {
                              handleDeleteRecord(item);
                            },
                          },
                        ].filter((f) =>
                          item.is_updated === false ? f.key === '0' : f.key === '1'
                        ),
                      }}
                      trigger={['click']}
                      style={{ padding: '4px' }}
                      placement="bottomRight"
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      <Space style={{ cursor: 'pointer' }}>
                        <img src={edit_delete} alt="dot" />
                      </Space>
                    </Dropdown>
                  ),
                }))
            }
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </TableDiv>

        {!isEmpty(data) && (
          <div className="d-flex justify-content-end align-items-center pb-5">
            <BasicButton mode="cancel" className="mr-3" onClick={() => handleCancel()}>
              Cancel
            </BasicButton>
            <BasicButton
              mode="teal"
              onClick={() =>
                complementaryEdit.mode === FORM_TYPES.EDIT
                  ? saveComplementary()
                  : setComplementaryEdit({ mode: 'edit', open: true })
              }
            >
              {complementaryEdit.mode === FORM_TYPES.EDIT ? 'Save' : 'Edit'}
            </BasicButton>
          </div>
        )}
        {confirmDeleteModal && (
          <ModelTC
            info={{
              type: 'deleteRecordComplementaryExperience',
              visible: confirmDeleteModal,
              setVisible: setConfirmDeleteModal,
              handleSubmit: () => {
                confirmDeleteRecord();
              },
              loading: false,
              onClose: () => {
                setConfirmDeleteModal(false);
                setSelectedRecord(null);
              },
            }}
          />
        )}
        {isShowPopupSaveAgenda && (
          <ModelTC
            info={{
              type: 'withoutSavingAspirationMatching',
              title: `You are about to leave this page without saving.`,
              visible: isShowPopupSaveAgenda,
              desc: (
                <DescForWithoutModalMatching
                  dataChanges={dataChanges}
                  allData={data}
                  typeMatching={MODULE_FILTER_TABLE.COMPLEMENTARY_EXPERIENCE}
                />
              ),
              setVisible: setIsShowPopupSaveAgenda,
              handleSubmit: () => {
                navigatingPathname && handleKeepNavigate(navigatingPathname);
                saveComplementary();
                setIsShowPopupSaveAgenda(false);
                setComplementaryEdit({ mode: 'edit', open: false });
              },
              onClose: () => {
                dispatch(updateIsError(false));
                navigatingPathname && handleKeepNavigate(navigatingPathname);
                setIsShowPopupSaveAgenda(false);
                setComplementaryEdit({ mode: 'edit', open: false });
              },
            }}
          />
        )}
      </Spin>
    </Wrapper>
  );
}
