import { Dropdown, Space, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../assets/common';
import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MASTER_DATA_MANAGEMENT, MESSAGE_TYPES } from '../../../../../utils/constants';
import { checkInvalidContentBusiness } from '../../../../../utils/helper';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

const DataTable = ({
  data,
  deleteSelecteds,
  setDeleteSelecteds,
  isEdit,
  setIsEdit,
  query,
  setQuery,
  getBusinessList,
  handleAddBusinessExist,
  handleEditBusinessSuccess,
  setShowDeleteModal,
  setDeleteItem,
}) => {
  const [business, setBusiness] = useState('');
  const [error, setError] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  const returnMenuItems = (id, business) => {
    return {
      items: [
        {
          icon: <img src={editNote} alt="edit" />,
          label: (
            <div
              onKeyDown={() => {}}
              onClick={() => {
                setIsEdit(id);
                setBusiness(business);
                setError('');
              }}
              className={styles.editButton}
            >
              Edit
            </div>
          ),
          key: '0',
        },
        {
          icon: <img src={deleteNote} alt="delete" />,
          label: <div className={styles.deleteButton}>Delete</div>,
          key: '1',
          onClick: () => {
            setShowDeleteModal(true);
            setDeleteItem([id]);
          },
        },
      ],
    };
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(query.page - 1) * query.limit + 1 + index}</span>
      ),
      width: '10%',
    },
    {
      title: 'Business Unit',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      width: '10%',
    },
    {
      title: 'Updated By',
      dataIndex: 'birth_name',
      width: '20%',
      align: ' center',
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
      align: ' center',
    },
  ];

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = (query.page - 1) * query.limit + query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.totalPage,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > query.totalPage ? query.totalPage : toResults}</b> of&nbsp;
        <b>{query.totalPage}</b> results
      </>
    ),
  };
  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setDeleteSelecteds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    selectedRowKeys: deleteSelecteds,
  };

  async function editBusinessList(name, id) {
    if (!name.trim()) {
      handleShowMessage(
        MESSAGE_TYPES.ERROR,
        'There are items that require your attention. Please fill out this field.'
      );
      setError(true);
      return;
    }
    try {
      await masterDataManagementApi.updateBusinessUnit({ name: name.trim() }, id, roleActive);
      getBusinessList();
      handleEditBusinessSuccess();
    } catch (error) {
      console.log(error);
      const errorMsg = get(error, 'response.data.errors');
      if (errorMsg === MASTER_DATA_MANAGEMENT.ADD_BUSINESS_EXIST) {
        return handleAddBusinessExist();
      }
      handleShowMessage(MESSAGE_TYPES.ERROR, errorMsg);
    }
    setIsEdit('');
    setBusiness('');
    setDeleteSelecteds([]);
    setError('');
  }

  return (
    <div style={{ marginTop: 24 }}>
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data.map((item, _index) => ({
            key: item.id,
            name:
              isEdit === item.id ? (
                <input
                  className={`${styles.inputBusiness} ${error && styles.inputBusinessErorr}`}
                  value={business}
                  onChange={(e) => {
                    if (isEmpty(e.target.value)) setBusiness('');
                    if (!checkInvalidContentBusiness(e.target.value)) setBusiness(e.target.value);
                  }}
                  id="business-input-edit"
                />
              ) : (
                <span className={styles.positionCode}>{item.name}</span>
              ),
            updated_at: (
              <span className={styles.approvedDate}>
                {moment(item.updated_at).format('DD/MM/YYYY')}
              </span>
            ),
            birth_name: <span className={styles.positionName}>{item.birth_name}</span>,
            more:
              isEdit !== item.id ? (
                <Dropdown
                  menu={returnMenuItems(item.id, item.name)}
                  trigger={['click']}
                  style={{ minWidth: 110 }}
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <Space style={{ cursor: 'pointer' }}>
                    <img src={edit_delete} alt="dot" />
                  </Space>
                </Dropdown>
              ) : (
                <BasicButton
                  mode="teal"
                  onKeyDown={() => {}}
                  onClick={() =>
                    editBusinessList(document.getElementById('business-input-edit').value, isEdit)
                  }
                >
                  Save
                </BasicButton>
              ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array,
  deleteSelecteds: PropTypes.array,
  setDeleteSelecteds: PropTypes.func,
  isEdit: PropTypes.string,
  setIsEdit: PropTypes.func,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  getBusinessList: PropTypes.func,
  handleAddBusinessExist: PropTypes.func,
  handleEditBusinessSuccess: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  setDeleteItem: PropTypes.func,
};

export default DataTable;
