import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactExport from 'react-data-export';
import { useSelector } from 'react-redux';

import talentFinderApi from '../../../services/talentFinderApi';
import { PERMISSION } from '../../../utils/constants';
import { talentExportColumns } from './talent-export.constant';
import * as styles from './talent-export.module.scss';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../hooks/useShowAlertWhenRefresh';
import { removeQueryString } from '../../../utils/helper';

const TalentExport = (props) => {
  const { filter, keyword, role, totalData, setShowExporting } = props;
  const permissions = useSelector((state) => state.user.permissions);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const fileName = 'Talent Data';
  const [isExport, setIsExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const handleOnExport = async () => {
    setIsLoading(true);
    setShowExporting(true);
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    try {
      const res = await talentFinderApi.requestDataExport({ ...filter, keyword }, role);
      if (res.status === 200) {
        const dataURL = get(res, 'data.result.url', '');
        const blockBlob = dataURL.split('/').pop();
        const blockBlobNonToken = removeQueryString(blockBlob);
        let exportStatus;
        let timer = setInterval(() => {
          if (dataURL) {
            (async () => {
              exportStatus = await talentFinderApi.getExportStatus(blockBlobNonToken);
              if (exportStatus?.status === 200 && exportStatus?.data?.result) {
                clearInterval(timer);
                const resJSON = await talentFinderApi.getDataExport(dataURL);
                const talentFinderList = get(resJSON, 'profiles', []);
                setData(talentFinderList.length ? talentFinderList : [{}]);
                setIsExport(true);
                setTimeout(() => {
                  setIsExport(false);
                }, 300);
                talentFinderApi.confirmExport(blockBlobNonToken);
                setIsLoading(false);
                setShowExporting(false);
                setIsUploadingOrDownloading(false);
                setShowAlertRefresh(false);
              }
            })().catch(console.error);
          }
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateDataSet = (data) => {
    if (!(data || data?.length)) return;
    const dataExport = data.map((item, i) => {
      return [
        { value: item?.staff_id || '' },
        { value: item?.birth_name || '' },
        { value: item?.gender || '' },
        { value: item?.age || '' },
        { value: item?.sg || '' },
        { value: item?.job_grade || '' },
        { value: item?.position_code || '' },
        { value: item?.date_in_sg || '' },
        { value: item?.date_in_position || '' },
        { value: item?.nationality || '' },
        { value: item?.position_name || '' },
        { value: item?.division || '' },
        { value: item?.opu_name || '' },
        { value: item?.business_unit || '' },
        { value: item?.job_family || '' },
        { value: item?.sub_job_family || '' },
        { value: item?.function || '' },
        { value: item?.retirement_date || '' },
        { value: item?.top_talent_status || '' },
        { value: item?.languages || '' },
        { value: item?.assessment_date ? moment(item.assessment_date).format('YYYY/MM/DD') : '' },
        { value: item?.assessment_type || '' },
        { value: item?.assessment_result || '' },
        // Personalities
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Auditor']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Coach']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Collaborator']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Designer']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Mastermind']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Optimist']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Peacemaker']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Problem Solver']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Scientist']
              ? 'Yes'
              : 'No'
            : '',
        },
        {
          value: !isEmpty(item.personalities)
            ? item.personalities['The Service Oriented']
              ? 'Yes'
              : 'No'
            : '',
        },
        // Other Experiences
        {
          value:
            item?.other_experiences?.['Knowledge in managing P&L for specific unit/ function '] ||
            '',
        },
        {
          value:
            item?.other_experiences?.['Experience Managing Government (local or foreign)'] || '',
        },
        { value: item?.other_experiences?.['Knowledge of business value chain'] || '' },
        { value: item?.other_experiences?.['Experience working in multi country'] || '' },
        { value: item?.other_experiences?.['Experience in managing project'] || '' },
        {
          value:
            item?.other_experiences?.['Experience in Business or Strategic Negotiations'] || '',
        },
        { value: item?.other_experiences?.['Lead Medium/Large - sized function/ business'] || '' },
        {
          value: item?.other_experiences?.['Experience in business transformation/ growth '] || '',
        },
        {
          value:
            item?.other_experiences?.['Experience in Core Operational & Execution Management'] ||
            '',
        },
        {
          value:
            item?.other_experiences?.['Experience in technical or technology development'] || '',
        },
        { value: item?.other_experiences?.['Developed Strategy for Business or Function'] || '' },
        { value: item?.other_experiences?.['Multi Business Exposure'] || '' },
        // Attributes 27
        { value: item?.attr27s?.['Sociable'] || '' },
        { value: item?.attr27s?.['Reactive'] || '' },
        {
          value: item?.attr27s?.['Commercially Oriented'] || '',
        },
        {
          value: item?.attr27s?.['Forward Thinking'] || '',
        },
        {
          value: item?.attr27s?.['Service Orientation'] || '',
        },
        { value: item?.attr27s?.['Structured'] || '' },
        { value: item?.attr27s?.['Empathetic'] || '' },
        { value: item?.attr27s?.['Expressive'] || '' },
        { value: item?.attr27s?.['Openness'] || '' },
        { value: item?.attr27s?.['Risk Tolerant'] || '' },
        { value: item?.attr27s?.['Dependable'] || '' },
        {
          value: item?.attr27s?.['Learning Agility'] || '',
        },
        {
          value: item?.attr27s?.['Ambiguity and Tolerance'] || '',
        },
        { value: item?.attr27s?.['Collaborative'] || '' },
        {
          value: item?.attr27s?.['Quality Focused'] || '',
        },
        { value: item?.attr27s?.['Trusting'] || '' },
        { value: item?.attr27s?.['Conceptual'] || '' },
        { value: item?.attr27s?.['Motivating'] || '' },
        { value: item?.attr27s?.['Evaluative'] || '' },
        { value: item?.attr27s?.['Driven'] || '' },
        { value: item?.attr27s?.['Dominance'] || '' },
        {
          value: item?.attr27s?.['Problem Solving'] || '',
        },
        { value: item?.attr27s?.['Judgement'] || '' },
        { value: item?.attr27s?.['Innovative'] || '' },
        { value: item?.attr27s?.['Mood Stability'] || '' },
        { value: item?.attr27s?.['Persuasive'] || '' },
        { value: item?.attr27s?.['Ambition'] || '' },
      ];
    });
    return [
      {
        columns: [...talentExportColumns],
        data: [...dataExport],
      },
    ];
  };

  return (
    <>
      <button
        className={styles.button}
        disabled={!permissions.includes(PERMISSION.TALENT_EXPORT) || !totalData || isLoading}
        onKeyDown={() => {}}
        onClick={handleOnExport}
      >
        Export {isLoading && <Spinner animation="border" size="sm" />}
      </button>
      {isExport && (
        <ExcelFile hideElement={true} filename={fileName}>
          <ExcelSheet dataSet={generateDataSet(data)} name="Sheet1" />
        </ExcelFile>
      )}
    </>
  );
};

export default TalentExport;
