import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MODULE_FILTER_TABLE } from '../../utils/constants';

const AiContentWrapper = styled.ul`
  font-family: 'inter';
  color: #9f9eae;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 20px 0;
`;

const AiViewMore = styled.div`
  font-family: 'inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #3f3c4c;
  user-select: none;
  cursor: pointer;
`;

const DescForWithoutModalMatching = ({ dataChanges, allData, typeMatching }) => {
  const [viewmore, setViewmore] = useState(false);
  const arr1 = [];
  const arr2 = [];

  dataChanges.forEach((element) => {
    if (element?.id) {
      const isExist = arr1.find(
        (f) => f.job_function === element.job_function && f.is_hide === element.is_hide
      );
      if (
        isEmpty(isExist) &&
        allData.find((a) => a.id === element.id).originalHide?.value !== element.is_hide
      )
        arr1.push(element);
    }
    if (element?.prev_id) {
      const isExist = arr2.find((f) => f.job_function === element.job_function);
      if (isEmpty(isExist)) arr2.push(element);
    }
  });

  return (
    <>
      You have made changes on:
      <AiContentWrapper>
        {!isEmpty([...arr1, ...arr2]) &&
          [...arr1, ...arr2].map((m, index) => {
            if (viewmore === false && index > 4) return <></>;
            return (
              <>
                {typeMatching === MODULE_FILTER_TABLE.COMPLEMENTARY_EXPERIENCE && (
                  <>
                    {(!m?.id || m?.prev_id) && (m?.isEditing || m?.isSearching) && (
                      <li key={index}>Job Function - {m.job_function}</li>
                    )}
                    {m?.id && (
                      <li key={index}>
                        Hide ({m.is_hide ? 'Yes' : 'No'}) - {m.job_function}
                      </li>
                    )}
                  </>
                )}
              </>
            );
          })}
      </AiContentWrapper>
      {!isEmpty([...arr1, ...arr2]) && Array.from(new Set([...arr1, ...arr2])).length > 5 && (
        <AiViewMore onClick={() => setViewmore(!viewmore)}>
          {viewmore === false ? '...View more' : 'View less'}
        </AiViewMore>
      )}
    </>
  );
};

export default DescForWithoutModalMatching;
