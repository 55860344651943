import { AutoComplete, Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import { history } from '../../../../../utils/history';
import SelectMenu from '../SelectMenu/SelectMenu';
import styles from './menu-bar-sp-admin.module.scss';
import { uniqueId } from 'lodash';

const Loading = () => {
  return <div className={styles.dot_flasing}></div>;
};

async function getAllOptions() {
  const result = await adminApi.getOptionsCriticalPositions();
  return result;
}
async function getAllPosition({ keyword, position_labels, businesses, _limit, _page, roleActive }) {
  const result = await adminApi.searchCriticalPositions({
    keyword,
    position_labels,
    businesses,
    _limit,
    _page,
    roleActive,
  });
  return result;
}
function genLabel({ position_name, business, position_label, position_code }) {
  let result = (
    <div
      style={{
        color: '#5e6c84',
        fontWeight: 400,
        borderBottom: '1px solid #EEEEEE',
        fontSize: 14,
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      {position_name}&nbsp;,&nbsp;{business}&nbsp;{position_label}&nbsp;({position_code})
    </div>
  );
  return result;
}
export default function MenuBarSpAdmin({
  showFilter,
  setShowFilter,
  positionLabelSelecteds,
  setPositionLabelSelecteds,
  businessSelecteds,
  setBusinessSelecteds,
  filterSearch,
  setFilterSearch,
  deleteSelecteds,
  setDeleteSelecteds,
  updateStatusVisible,
  setUpdateStatusVisible,
  loading,
  deleteCriticalPosition,
}) {
  const [positionLabelOptions, setPositionLabelOptions] = useState([
    { value: 'all', label: 'All' },
  ]);
  const [businessOptions, setBusinessOptions] = useState([{ value: 'all', label: 'All' }]);
  const [positionCodeOptions, setPositionCodeOptions] = useState([]);
  const [isLoadingPostionCode, setIsLoadingPositionCode] = useState(false);
  const typingTimeoutRef = useRef(null);
  const [keywordTemp, setKeywordTemp] = useState('');
  const roleActive = useSelector((state) => state.user.roleActive);

  useEffect(() => {
    getAllOptions()
      .then((res) => {
        let positionLabels = (res?.data?.result?.position_labels || [])
          .filter((item) => item !== null)
          .map((item) => {
            return { value: item, label: item };
          });
        let businesses = (res?.data?.result?.businesses || [])
          .filter((item) => item !== null)
          .map((item) => {
            return { value: item, label: item };
          });
        positionLabels.push({ value: 'all', label: 'All' });
        businesses.push({ value: 'all', label: 'All' });

        setPositionLabelOptions(positionLabels);
        setBusinessOptions(businesses);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSearch = (value) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setKeywordTemp(value);
      if (!value) {
        setPositionCodeOptions([]);
        return;
      }
      const body = {
        keyword: value,
        _page: 1,
        _limit: 5,
        position_labels: positionLabelSelecteds.map((item) => item.value),
        businesses: businessSelecteds.map((item) => item.value),
      };
      setIsLoadingPositionCode(true);
      getAllPosition({ ...body, roleActive })
        .then((res) => {
          const data = (res?.data?.result?.data || []).map((item) => {
            return { value: item.position_code, label: genLabel(item), key: uniqueId() };
          });

          setPositionCodeOptions(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoadingPositionCode(false);
        });
    }, 500);
  };
  const onSelect = (value) => {
    setKeywordTemp(value);
  };
  const handleSearch = () => {
    setFilterSearch({
      ...filterSearch,
      _page: 1,
      keyword: keywordTemp,
    });
  };

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <ModelTC
          info={{
            type: 'deleteKeyPositionFromListOfCriticalPosition',
            visible: updateStatusVisible,
            setVisible: setUpdateStatusVisible,
            handleSubmit: () => deleteCriticalPosition(deleteSelecteds),
            loading,
            onClose: () => {
              setUpdateStatusVisible(false);
            },
          }}
        />
        <Col span={16}>
          <Row align="bottom" gutter={5}>
            <Col span={18}>
              <AutoComplete
                size="large"
                notFoundContent={isLoadingPostionCode ? <Loading /> : ''}
                options={positionCodeOptions}
                style={{
                  width: '100%',
                }}
                autoFocus={true}
                className={styles.input}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Search Position Code or Position Name"
              />
            </Col>
            <Col>
              <div
                data-testid="search-btn"
                className={styles.button}
                onKeyDown={() => {}}
                onClick={handleSearch}
              >
                Search
              </div>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <div
                onKeyDown={() => {}}
                onClick={() => deleteSelecteds.length > 0 && setUpdateStatusVisible(true)}
                className={`${styles.button} ${
                  deleteSelecteds.length === 0 ? styles.inActiveDelBtn : styles.activeDelBtn
                }`}
              >
                Confirm Delete Position
              </div>
            </Col>
            <Col>
              <div
                data-testid="add-new-btn"
                className={`${styles.button} ${styles.btnAdd}`}
                onKeyDown={() => {}}
                onClick={() => history.push(`/admin/sp-management/managing-critical-position/add`)}
              >
                Add New
              </div>
            </Col>
            <Col>
              <div className={styles.icons}>
                <img
                  data-testid="show-filter-btn"
                  src={showFilter ? finder_filter_enable : finder_filter_disable}
                  alt="Filter"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <Row style={{ marginTop: 22 }}>
          <Col>
            <Row gutter={16}>
              <Col>
                <SelectMenu
                  selectedOptions={positionLabelSelecteds}
                  setSelectedOptions={setPositionLabelSelecteds}
                  allOptions={positionLabelOptions}
                  placeholder="Position Label"
                />
              </Col>
              <Col>
                <SelectMenu
                  selectedOptions={businessSelecteds}
                  setSelectedOptions={setBusinessSelecteds}
                  allOptions={businessOptions}
                  placeholder="Business"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
