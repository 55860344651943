import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TimePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { END_GROUP_ROW_CLASS_NAME } from '../../utils/constants';

const { TextArea } = Input;
const { Panel } = Collapse;

export const font = {
  inter: `'Inter', sans-serif`,
  rubik: `'Rubik', sans-serif`,
};

export const color = {
  brightGray: '#e7e7f0',
  teal: '#00a19c',
  loading: '#8896a7',
  darkGray: '#5e6c84',
  darkBlue: '#344563',
  brightGray2: '#3F3C4C',
  froly: '#ff787f',
  geyser: '#d3dee8',
  mySin: '#fdb924',
  white: '#ffffff',
  monza: '#bf0013',
  camarone: '#00580f',
  santasGray: '#9f9eae',
  gallery: '#e9edf0',
  danger: 'red',
  darkBlue2: '#003F9A',
  disable: '#e0e0e0',
  darkGreen: '#4CAF50',
  frostee: '#e5f4e7',
  funGreen: '#007724',
  halfDutchWhite: '#fdf7e0',
  tangerine: '#f08800',
};

export const fontSize = {
  small: '12px',
  normal: '14px',
  medium: '16px',
  sizable: '18px',
  large: '24px',
  superLarge: '32px',
};

export const AiAlert = styled(Alert)`
  height: 100px;
`;

export const AiSpin = styled(Spin)`
  .ant-spin-text {
    color: ${color.loading};
    font-family: ${font.inter};
    font-size: 12px;
  }
  .ant-spin-dot-item {
    background-color: ${(props) => (props.color ? props.color : color.teal)};
  }
`;

export const Aih3 = styled.h3`
  font-family: ${font.inter};
  font-size: ${fontSize.sizable};
  color: ${color.brightGray2};
  font-weight: 600;
`;

export const Aip = styled.p`
  font-family: ${font.inter};
  font-size: ${fontSize.small};
  color: ${color.brightGray2};
  font-weight: 500;
`;

export const AiButton = styled(Button)`
  border-radius: 6px;
  padding: 0px 20px;
  font-weight: 500;
  font-size: ${fontSize.small};
  font-family: ${font.inter};
  margin-right: 8px;
  border: ${(props) =>
    props?.border ? `1px solid ${props?.borderColor || color.gallery} !important` : 'none'};
  &:last-child {
    margin-right: 0;
  }
  ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
          background-color: ${color.froly};
          border-color: ${color.froly};
          color: white;
        `;
      case 'teal':
        return css`
          background-color: ${color.teal};
          border-color: ${color.teal};
          color: white;
        `;
      case 'EC2E2E':
        return css`
          background-color: #ec2e2e;
          border-color: #ec2e2e;
          color: white;
        `;
      case 'geyser':
        return css`
          background-color: ${color.white};
          border-color: ${color.geyser};
          color: black;
        `;
      case 'exit':
        return css`
          width: fit-content;
          min-width: 100px;
          background-color: ${color.white};
          border-color: #00a19c !important;
          color: ${color.teal};
        `;
      default:
        return css`
          background-color: ${color.white};
          border-color: ${color.white};
          color: ${color.teal};
        `;
    }
  }}

  &:disabled {
    cursor: no-drop;
    opacity: 0.7;
    pointer-events: none;
    background-color: #c9d5e3;
    color: white;
  }

  &:hover {
    ${(props) => {
      switch (props.mode) {
        case 'danger':
          return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
        case 'teal':
          return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
        case 'EC2E2E':
          return css`
            background-color: #ec2e2e;
            border-color: #ec2e2e;
            color: white;
          `;
        default:
          return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
      }
    }}
  }
  &:focus {
    ${(props) => {
      switch (props.mode) {
        case 'danger':
          return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
        case 'teal':
          return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
        default:
          return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
      }
    }}
  }
  cursor: none;
`;

export const AiLoading = styled.div`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  }
`;

export const BasicButton = styled.button`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: ${({ round }) => round || 4}px;
  padding: ${({ padding }) => padding || '7px 17px'};
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || 12}px;
  height: ${({ height }) => height || 32}px;
  border: ${({ border = '1px solid' }) => border};
  line-height: 10px;
  ${({ width }) => (width ? `width: ${width};` : '')}

  ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
          background-color: ${color.froly};
          border-color: ${color.froly};
          color: ${color.white};
        `;
      case 'teal':
        return css`
          background-color: ${color.teal};
          border-color: ${color.teal};
          color: ${color.white};
          &:disabled {
            background: #6ac2be !important;
            cursor: no-drop;
          }
        `;
      case 'cancel':
        return css`
          background-color: ${color.white};
          border-color: #e9edf0;
          color: ${color.teal};
        `;
      case 'border-teal':
        return css`
          background-color: ${color.white};
          border-color: ${color.teal};
          color: ${color.teal};
        `;
      case 'remove':
        return css`
          background-color: #da2228;
          border-color: #da2228;
          color: ${color.white};
        `;
      default:
        return css`
          background-color: ${color.white};
          color: ${color.teal};
        `;
    }
  }}

  &:disabled {
    background: ${color.brightGray};
    color: ${color.white};
    border: 1px solid ${color.brightGray};
    cursor: no-drop;
    pointer-events: none;
  }

  &:not(:disabled):active {
    transform: translateY(2px);
  }
`;

export const BasicTable = styled(Table)`
  ${({ styles = {}, groupRow }) => {
    const {
      headerBackground = 'white',
      headerColor = '#8896a7',
      headerWeight = 700,
      selectPadding = 50,
      borderRadius = 2,
      firstColumnGap = 16,
    } = styles;
    return css`
      width: 100%;
      margin-top: 20px;

      input[type='checkbox'] {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      input[type='checkbox']:checked {
        accent-color: #00a19c;
        padding: 2px;
      }

      .ant-table-container table tr {
        th:first-child,
        td:first-child {
          padding-left: ${firstColumnGap}px;
        }
      }

      .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: ${borderRadius}px;
      }

      .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: ${borderRadius}px;
      }

      .ant-table-container table > tbody > tr:last-child td:first-child {
        border-bottom-left-radius: ${borderRadius}px;
      }

      .ant-table-container table > tbody > tr:last-child td:last-child {
        border-bottom-right-radius: ${borderRadius}px;
      }

      .ant-table-thead {
        .ant-table-cell {
          &::before {
            content: none !important;
          }
        }
      }

      .ant-pagination {
        position: relative;
        align-items: center;
      }
      .ant-pagination-total-text {
        position: absolute;
        left: 0;
        height: fit-content;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        button {
          border: none;
          background-color: transparent;
        }
      }

      .ant-pagination-item-active {
        background: #00a19c;
        color: #fff;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #00a19c;
        border-color: #00a19c;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #fff;
        border-color: #00a19c;

        &:after {
          background-color: #00a19c;
        }
      }

      .ant-table-tbody > tr.ant-table-row-selected > td {
        background-color: white;
      }

      .ant-table-thead > tr > th {
        background: ${headerBackground};
      }

      .ant-table-thead .ant-table-cell {
        color: ${headerColor};
        font-size: 14px;
        font-weight: ${headerWeight};
      }

      table tr td.ant-table-selection-column {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 23px;
        padding-right: ${selectPadding}px;
      }

      table tr th.ant-table-selection-column {
        padding-right: ${selectPadding}px;
        padding-left: 23px;
        border-left: 1px solid #d3dee8;
      }

      table tr td.ant-table-selection-column {
        border-left: 1px solid #d3dee8;
      }

      .ant-table-thead > tr > th {
        border-bottom: 1px solid #d3dee8;
        border-top: 1px solid #d3dee8;
      }

      .ant-table-tbody > tr > td:first-child {
        border-left: 1px solid #d3dee8;
      }

      .ant-table-tbody > tr > td:last-child {
        border-right: 1px solid #d3dee8;
      }

      .ant-table-thead .ant-table-cell {
        &:last-child {
          border-right: 1px solid #d3dee8;
        }
        &:first-child {
          border-left: 1px solid #d3dee8;
        }
      }

      .ant-table-tbody > tr > td {
        border-bottom: ${groupRow ? 'none' : '1px solid #d3dee8'};
      }

      .ant-table-tbody tr:last-child > td {
        border-bottom: 1px solid #d3dee8;
      }

      .ant-table-tbody > tr.${END_GROUP_ROW_CLASS_NAME} > td {
        border-bottom: 1px solid #d3dee8;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        border-color: #d9d9d9;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: white;
      }

      .pagination-custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
      }

      .ant-pagination-item-active {
        border-color: ${color.teal};
      }

      .ant-pagination-item:hover {
        border-color: ${color.teal};
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${color.teal};
      }

      .avatar {
        text-align: right;
        img {
          width: 50px;
          height: 50px;
          boder-radius: 50%;
        }
      }
    `;
  }}
`;

export const BasicPopover = styled(Popover)`
  ${({ styles = {} }) => {
    const {
      hideArrow = true,
      w,
      py = 16,
      px = 16,
      rounded = 4,
      bg = '#fff',
      color = '#000',
      minWidth,
    } = styles;
    return css`
      .ant-popover {
        padding-top: ${hideArrow ? 0 : 15}px;
        min-width: ${minWidth ? minWidth + 'px' : 'auto'};
        width: ${w ? w + 'px' : '100%'};
        z-index: 100;
      }

      .ant-popover-arrow {
        display: ${hideArrow ? 'none' : 'block'};
      }

      .ant-popover-arrow-content::before {
        background: ${bg};
      }

      .ant-popover-inner {
        border-radius: ${rounded}px;
        background: ${bg};
        color: ${color};
      }

      .ant-popover-inner-content {
        padding-top: ${py}px;
        padding-bottom: ${py}px;
        padding-left: ${px}px;
        padding-right: ${px}px;
        color: ${color};
      }
    `;
  }}
`;

export const BasicSelect = styled(Select)`
  ${({ height, error, no_border, font_color, checkbox }) => {
    return css`
      display: flex;
      align-items: center;
      width: 100%;
      height: auto !important;
      min-height: ${height || 42}px !important;

      .ant-select-dropdown {
        min-width: 100px !important;
      }

      .ant-select-selector {
        width: 100%;
        height: auto !important;
        min-height: ${height || 42}px !important;
        border-color: ${error && '#da1e28 !important'};
        ${font_color ? `color: ${font_color}` : ''}
      }

      .ant-select-selection-placeholder {
        color: #8d8d8d;
      }

      ${no_border &&
      `
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border: none !important;
          box-shadow: none !important;
          padding: 0 !important;
          background: transparent !important;
        }
        .ant-select-item-option {
          border: none;
        }
      `}

      &:not(.ant-select-disabled) {
        &:hover,
        &:focus {
          .ant-select-selector {
            border-color: ${error ? '#F36568' : '#9fb0c2'};
            box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
          }
        }
      }

      &.ant-select-multiple {
        .ant-select-selection-overflow {
          padding-left: 12px;
        }

        .ant-select-selector .ant-select-selection-search-input {
          margin-top: 8px;
          margin-left: -16px;
        }

        .ant-select-selection-item {
          margin-top: 0;
          border-radius: 12px;
          border: 1px solid #bdbdbd;
          background-color: ${color.white};
          font-size: 12px;
          display: flex;
          flex-direction: row-reverse;
          gap: 6px;
          align-items: center;
        }

        .ant-select-selection-item-remove {
          padding-bottom: 3px;
        }
      }

      ${checkbox &&
      `.ant-select-item-option-content {
        padding-left: 40px;

        &::before {
          content: '';
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
          height: 17px;
          border: solid 1px #c9d5e3;
          border-radius: 4px;
        }
      }

      .ant-select-item-option-state {
        position: absolute;
        left: 16px;
        top: 44%;
        transform: translateY(-50%);

        .anticon-check {
          border: solid 1px #00a19c;
          border-radius: 4px;
          padding: 1px;
          background: #00a19c;

          svg {
            fill: #fff;
          }
        }
      }

      .ant-select-item {
        ${no_border ? 'border: none' : ''};

        &:hover {
          background-color: #e0f3f3;
        }
      }

      .ant-select-item-option-selected {
        > .ant-select-item-option-content {
          &::before {
            display: none;
          }
        }
      }`}
    `;
  }}
`;

const formInput = css`
  font-family: ${font.inter};
  background: #ffffff;
  border: 1px solid #d3dee8;
  border-color: ${(props) => props?.error && '#ff4d4f'};
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &::placeholder {
    color: ${({ plhColor = '#8d8d8d' }) => plhColor};
  }

  &:hover,
  &:focus {
    border-color: ${(props) => (props?.error ? '#ff4d4f' : color.teal)};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
  &:disabled {
    color: #787587;
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

export const BasicInput = styled.input`
  ${({ height = 42, width }) => {
    return css`
      ${formInput}
      height: ${height}px;
      ${width && `width: ${width}px`};
    `;
  }}
`;

export const BasicTextArea = styled.textarea`
  ${({ height = 94, resize }) => {
    return css`
      height: ${height}px;
      resize: ${resize};
      ${formInput};
    `;
  }}
`;

export const BasicLabel = styled.label`
  ${({ size = 12, weight = 500, color = '#3f3c4c', required, margin_top }) => {
    return css`
      font-family: ${font.inter};
      font-style: normal;
      font-weight: ${weight};
      font-size: ${size}px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: ${color};
      margin-bottom: 4px;
      ${margin_top && `margin-top: ${margin_top}px`}

      ${required &&
      css`
        &::after {
          content: '*';
          color: #ff787f;
        }
      `}
    `;
  }}
`;

export const BasicErrorText = styled.div`
  ${({ size = 10, weight = 500, show }) => {
    return css`
      font-size: ${size}px;
      font-weight: ${weight};
      line-height: ${show ? '12px' : '0'};
      color: #da1e28;
      margin-top: ${show ? '4px' : '0'};
      visibility: ${show ? 'visible' : 'hidden'};
      opacity: ${show ? '1' : '0'};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    `;
  }}
`;

export const BasicAutoComplete = styled(AutoComplete)`
  ${({ height = 42 }) => {
    return css`
      height: ${height}px !important;
      width: 100%;

      input {
        height: ${height}px !important;
        padding-left: 0 !important;
      }

      .ant-select-selection-placeholder {
        color: #8d8d8d;
      }

      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: #dcdce6;
      }

      &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #dcdce6;
      }

      .rc-virtual-list-holder {
        overflow-y: scroll !important;
      }

      .rc-virtual-list-scrollbar {
        width: 5px !important;

        .rc-virtual-list-scrollbar-thumb {
          background: ${color.teal} !important;
        }
      }
    `;
  }}
`;

export const BasicLoading = styled(Spin)`
  ${({ pxsize = 30, inline }) => {
    const itemSize = (pxsize - 2) / 2;
    return css`
      margin-left: ${inline ? '1ch' : 0};

      .ant-spin-dot-spin {
        font-size: ${pxsize}px;
      }

      .ant-spin-dot-item {
        width: ${itemSize}px;
        height: ${itemSize}px;
      }

      &.ant-spin {
        max-height: none !important;
      }
    `;
  }}
`;

export const BasisSwitch = styled(Switch)`
  ${({ bgColor = color.teal, opacity = 0.4 }) => {
    return css`
      background-color: #c9d5e3;

      &.ant-switch-checked {
        background-color: ${bgColor};
      }

      &.ant-switch-disabled {
        opacity: ${opacity};
      }
    `;
  }}
`;

export const BasicRadio = styled(Radio.Group)`
  ${() => {
    return css`
      display: block;

      .ant-radio-checked .ant-radio-inner {
        border-color: ${color.teal};
      }

      .ant-radio-inner::after {
        background-color: ${color.teal};
      }

      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${color.teal};
      }

      .ant-radio-wrapper {
        color: #181818;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
      }
    `;
  }}
`;

export const BasicTimepicker = styled(TimePicker)`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${color.geyser};
  border-radius: 4px;

  input {
    &::placeholder {
      color: #8d8d8d;
    }
  }

  &:hover {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }

  .ant-picker-ok button:not(:disabled) {
    background-color: ${color.teal} !important;
    color: white !important;
    border: none;
  }
`;

export const AiTextarea = styled(TextArea)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:hover,
  &:focus {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
  &:disabled {
    color: #787587;
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

export const AiForm = styled(Form)`
  .ant-form-item-explain-error {
    font-family: ${font.inter};
    font-size: ${fontSize.small};
  }
`;

export const AiInput = styled(Input)`
  padding: 9px 11px;
  font-family: ${font.inter};
  border-radius: 4px;
  &::placeholder {
    font-family: ${font.inter};
    font-size: ${fontSize.small};
  }
  &:focus,
  &:hover {
    border-color: ${color.teal};
    box-shadow: none;
  }
`;

export const AiHeader = styled(Row)`
  border: 1px solid #d9d9d9;
  border-bottom: none;
  padding: 12px 0;
  font-family: ${font.inter};
  color: #8896a7;
  font-size: ${fontSize.normal};
  font-weight: 700;
`;

export const AiPanel = styled(Panel)`
  .ant-collapse-header {
    display: block !important;
  }
`;

export const AiBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  background-color: ${(props) => (props.form1 ? color.teal : 'white')};
  border: 1px solid #00a19c;
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => (props.form1 ? 'white' : color.teal)};
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;
`;

export const AiSpan = styled.span`
  font-family: ${font.inter};
  font-size: ${fontSize.normal};
  font-weight: 700;
  color: ${(props) => (props.title ? color.loading : color.brightGray2)};
`;

export const AiCheckBox = styled(Checkbox)`
  font-size: ${fontSize.normal};
  color: ${color.brightGray2};
  font-weight: 700;
  font-family: ${font.inter};
  text-transform: capitalize;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${color.white};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked::after,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${color.teal};
  }
`;

export const AiTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: ${font.rubik};
    font-size: 24px;
    font-weight: 700;
    color: ${color.brightGray2};
    margin-bottom: 0;
    margin-right: 15px;
  }
  div {
    padding: 5px 10px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const AiError = styled.div`
  font-family: ${font.inter};
  font-weight: 400;
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

export const Container = styled.div`
  width: 1287px;
  margin: auto;

  .ant-table-expanded-row > .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-expanded-row .ant-table {
    margin-left: 0 !important;
  }

  .ant-table {
    margin: 0 !important;
  }

  .ant-table-expanded-row .ant-table-thead {
    display: none;
  }

  .ant-table-expanded-row .ant-table-cell {
    vertical-align: top;
  }
`;

export const Navigate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #00a19c;
  }

  img {
    transform: translateY(-40%);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  .left {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 40px;
      font-family: ${font.rubik};
      font-weight: 700;
      font-size: 24px;
      color: #4a4a4a;
    }

    .sub-title {
      margin-top: 5px;
      font-family: ${font.rubik};
      font-weight: 400;
      font-size: 14px;
      color: #999a9c;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      cursor: pointer;
      &:active {
        transform: translate(0, 1px);
      }
    }
    .button {
      cursor: pointer;
      color: white;
      font-family: ${font.inter};
      font-weight: 600;
      font-size: 12px;
      padding: 5px 20px;
      border-radius: 6px;
      background-color: #e7e7f0;
    }

    .btn-delete-user {
      background-color: #e7e7f0;

      &_active {
        background-color: #ff787f;
        cursor: pointer;

        &:active {
          transform: translate(0, 1px);
        }
      }
    }

    .button-active {
      background-color: #00a19c;
      cursor: pointer;

      &:active {
        transform: translate(0, 1px);
      }
    }

    .button-back {
      background-color: #ffffff;
      border: 1px solid #00a19c;
      color: #00a19c;
    }
  }
`;

export const TableManagement = styled.div`
  width: 100%;
  margin-top: 20px;

  .ant-spin-text {
    color: ${color.loading};
    font-family: ${font.inter};
    font-size: 12px;
  }
  .ant-spin-dot-item {
    background-color: ${color.teal};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${color.teal};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.teal};
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;

      &::before {
        content: none !important;
      }
    }
  }

  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
      background-color: transparent;
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }

  .avatar {
    text-align: right;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
`;

export const AiLabel = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  color: ${color.brightGray2};
  margin-bottom: 4px;
  position: relative;
  &::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

export const AiLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  img {
    width: 13.5px;
    height: 13.5px;
    margin-right: 12.3px;
  }
  span {
    font-family: ${font.inter};
    font-weight: 400;
    font-size: ${fontSize.small};
    color: ${color.brightGray2};
  }
`;

export const WrapperBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${(props) => {
    if (props.isLoading) {
      return '21px';
    } else {
      return '0px';
    }
  }};
  padding-bottom: 64px;
`;

export const BasicDatePicker = styled(DatePicker)`
  ${({ h = 42 }) => {
    return css`
      width: 100%;
      padding: 10px 16px;
      border: 1px solid ${color.geyser};
      border-radius: 4px;
      height: ${h}px;

      input {
        &::placeholder {
          color: #8d8d8d;
        }
      }

      &:hover {
        border-color: ${color.teal};
        box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: ${color.teal} !important;
      }

      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${color.teal};
      }

      .ant-picker-today-btn {
        color: ${color.teal};
      }
    `;
  }}
`;

export const BasicSwitch = styled(Switch)`
  ${({ bgColor = color.teal, opacity = 0.4 }) => {
    return css`
      background-color: #c9d5e3;

      &.ant-switch-checked {
        background-color: ${bgColor};
      }

      &.ant-switch-disabled {
        opacity: ${opacity};
      }
    `;
  }}
`;
