import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import talentProfileApi from '../../../../services/talentProfiles';
import { CURRENT_YEAR } from '../../../../utils/constants';
import styles from './historical-talent-status-print.module.scss';

const HistoricalTalentStatusPrint = (props) => {
  const { profileId, tokenTalentProfile } = props;
  const user = useSelector((state) => state.user);
  const [historical, setHistorical] = useState([]);

  useEffect(() => {
    async function fectchApi() {
      try {
        const response = await talentProfileApi.fetchHistoricalTalentStatus({
          profileId,
          roleUser: user,
          tokenTalentProfile,
        });
        if (response && response.data && response.data.result.length > 0) {
          const newArr = [];
          response.data.result.forEach((item) => {
            if (
              !item.platform === false ||
              !item.start_date === false ||
              !item.end_date === false ||
              !item.status === false
            ) {
              newArr.push(item);
            }
          });
          setHistorical(newArr);
        }
      } catch (error) {
        setHistorical([]);
      }
    }
    if (user.roleActive) {
      fectchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive.roleId, profileId]);

  return (
    <div className={styles.SectionPlan} data-testid="historical-talent">
      <div className={styles.SectionName}>Historical Talent Status</div>
      <div className={styles.tablePlan}>
        <table className="table">
          <thead>
            <tr className={styles.tablePlanThead}>
              <th scope="col">Status</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Platform</th>
            </tr>
          </thead>
          <tbody>
            {!!historical &&
              historical.length > 0 &&
              historical?.map((data, index) => (
                <tr data-testid="list-historical" key={index} className={styles.tablePlanBody}>
                  <td data-testid="talent-status" className={styles.tablePlanBodyStatus}>
                    {!data.status ? '-' : data.status}
                  </td>
                  <td data-testid="talent-start-date">
                    {moment(data.start_date).format('D MMMM YYYY') !== 'Invalid date'
                      ? moment(data.start_date).format('D MMMM YYYY')
                      : '-'}
                  </td>
                  <td data-testid="talent-end-date">
                    {moment(data.end_date).format('D MMMM YYYY') !== 'Invalid date' &&
                    !data.end_date.includes(CURRENT_YEAR)
                      ? moment(data.end_date).format('D MMMM YYYY')
                      : '-'}
                  </td>
                  <td data-testid="talent-platform">{!data.platform ? '-' : data.platform}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoricalTalentStatusPrint;
