import { List, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { chevron_down_arrow, lookup } from '../../assets/img';
import useCallbackClickOutside from '../../hooks/useCallbackClickOutside';
import { fetchFunctionExperiences } from '../../services/talentProfiles';
import {
  InputStyle,
  ListAntd,
  Selected,
  TitleDiv,
  Wrapper,
  WrapperPopUp,
} from './SearchJobFunctionComplementaryStyled';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const SearchJobFunctionComplementary = (props) => {
  const { roleId, permissions, item, setValue, openDropdown, setOpenDropdown } = props;
  const [loading, setLoading] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useCallbackClickOutside({
    initialIsVisible:
      (openDropdown === item.id || openDropdown === item.ids) && openDropdown ? true : false,
    cbFunction: () => setOpenDropdown(false),
  });
  const [hasMore, setHasmore] = useState(true);
  const [query, setQuery] = useState({ search: '', page: 1, data: [] });
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [isMount, setIsMount] = useState(false);
  const isError = useSelector((state) => state.page.isError);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  useEffect(() => {
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: 0,
      });
      setQuery(() => ({ page: 1, search: debouncedInputValue, data: [] }));
    }
  }, [debouncedInputValue]);

  const loadMoreData = async (page, keyword) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      let res = await fetchFunctionExperiences({
        roleId,
        permissions,
        search: keyword,
        page: page,
        limit: 15,
      });
      const result = res.data.result;
      if (page > 1) {
        setHasmore(true);
        setQuery((prev) => ({ ...prev, data: [...prev.data, ...result] }));
      } else {
        setQuery(() => ({ page: 1, search: keyword, data: !isEmpty(result) ? result : [] }));
      }
    } catch (error) {
      console.error(error);
      setQuery(() => ({ page: 1, search: '', data: [] }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isComponentVisible && isMount) {
      loadMoreData(query.page, query.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.search, isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && !isMount) {
      loadMoreData(1, '');
      setIsMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMount, isComponentVisible]);

  useEffect(() => {
    if (openDropdown === false) return setIsComponentVisible(false);
    setIsComponentVisible(
      (openDropdown === item.id || openDropdown === item.ids) && openDropdown ? true : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  useEffect(() => {
    if (isComponentVisible === false) return setOpenDropdown(false);
  }, [isComponentVisible]);

  return (
    <Wrapper ref={ref}>
      <div>
        {item.job_function && !isComponentVisible && (
          <Selected
            onClick={() => {
              setOpenDropdown(item.id || item.ids);
            }}
          >
            <img src={lookup} alt="" style={{ cursor: 'pointer' }} />
            <div className="truncate">{item.job_function}</div>
            <img src={chevron_down_arrow} alt="" style={{ cursor: 'pointer' }} />
          </Selected>
        )}
        {((!item.job_function && !isComponentVisible) || isComponentVisible) && (
          <InputStyle
            onClick={() => {
              setOpenDropdown(item.id || item.ids);
            }}
            isError={isError}
            prefix={
              <img
                src={lookup}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(56%) sepia(1%) saturate(0%) hue-rotate(229deg) brightness(99%) contrast(98%)',
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  padding: '0 5px',
                }}
                alt="chevron_down_arrow"
                onClick={() => {
                  setIsComponentVisible(true);
                }}
              />
            }
            suffix={
              <img
                src={chevron_down_arrow}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(56%) sepia(1%) saturate(0%) hue-rotate(229deg) brightness(99%) contrast(98%)',
                  pointerEvents: 'all',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  padding: '0 5px',
                }}
                alt="chevron_down_arrow"
                onClick={() => {
                  setIsComponentVisible(true);
                }}
              />
            }
            placeholder="Search by Function, Job Family & Sub-Job Family"
            onChange={(e) => setInputValue(e.target.value)}
            defaultValue={query.search || item.val}
          />
        )}
      </div>
      {isComponentVisible && (
        <WrapperPopUp>
          <TitleDiv>
            <div>Job Family</div>
            <div>Sub Job Family</div>
            <div style={{ backgroundColor: '#C9D5E3' }}>Function</div>
          </TitleDiv>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: '264px',
              overflow: 'auto',
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <InfiniteScroll
              dataLength={query.data.length}
              next={() => {
                setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
              hasMore={hasMore}
              loader={
                loading && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50px',
                    }}
                  >
                    <Spin loading={loading} />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              <ListAntd
                dataSource={query.data}
                split={false}
                renderItem={(item) => (
                  <List.Item
                    key={uuidv4()}
                    onClick={() => {
                      setOpenDropdown(false);
                      setValue({ val: item.name });
                    }}
                  >
                    <div>{item.job_family}</div>
                    <div>{item.sub_job_family}</div>
                    <div className="function-name">{item.name}</div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </WrapperPopUp>
      )}
    </Wrapper>
  );
};
export default SearchJobFunctionComplementary;
