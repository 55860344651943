import React, { useEffect, useState } from 'react';
import { Spin, Pagination } from 'antd';

import { BreadCrumb } from '../../components';
import { getBreadCrumbList } from '../../utils/helper';
import NewsCard from '../../components/NewsAnnouncements/components/NewsCard';
import { adminApi } from '../../services/admin';

function NewsAndAnnouncements() {
  const [loading, setLoading] = useState(false);
  const [newsAnnouncementList, setNewsAnnouncementList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const getViewNewsAnnouncementList = async () => {
    setLoading(true);
    try {
      const res = await adminApi.getViewNewsAnnouncementList();
      if (res.status === 200) {
        setNewsAnnouncementList(res.data.result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getViewNewsAnnouncementList();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNewsList = newsAnnouncementList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const startRecordNumber = indexOfFirstItem + 1;
  const endRecordNumber = Math.min(
    indexOfLastItem,
    newsAnnouncementList.length
  );
  const totalResult = newsAnnouncementList.length;

  return (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <Spin spinning={loading} size="large">
        <BreadCrumb
          level={2}
          breadCrumbList={getBreadCrumbList([`Home`, `News And Announcement`])}
        />
        <div
          style={{ fontWeight: '600', fontSize: '24px', color: '#3D3D3D' }}
        >{`News and Announcements`}</div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: '20px',
            width: '1290px',
            borderRadius: '4px',
            padding: '32px 24px',
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridAutoRows: 'auto',
          }}
        >
          {currentNewsList.map((newsItem, index) => (
            <NewsCard key={index} item={newsItem} />
          ))}
        </div>
        <div style={{ marginTop: '16px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            style={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              color: '#374151',
            }}
          >
            Showing <b>&nbsp;{startRecordNumber}&nbsp;</b> to <b>{endRecordNumber}</b>{' '}
            of <b>{totalResult}</b> results
          </div>
          <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={newsAnnouncementList.length}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Spin>
    </div>
  );
}

export default NewsAndAnnouncements;
