import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { profileManagementApi } from '../../../../services/profileManagementApi';
import DerailmentRisksAssessments from '../DerailmentRisksAssessments/DerailmentRisksAssessments';
import ExternalAssessmentsFilter from '../ExternalAssessmentsFilter/ExternalAssessmentsFilter';
import GeneralExternalAssessments from '../GeneralExternalAssessments/GeneralExternalAssessments';
import LearningAgilitiesAssessments from '../LearningAgilitiesAssessments/LearningAgilitiesAssessments';
import DriversAssessments from '../DriversAssessments/DriversAssessments';
import {
  ASSESSMENT_FILTER_TYPES,
  ASSESSMENT_STATUS_TYPES,
  ASSESSMENT_TYPE_VALUES,
  ASSESSMENT_TYPES,
} from './constant_assessments';
import styles from './external-assessments.module.scss';
import { AssessmentStatus } from './ExternalAssessmentStyled';
import TopTalentPotential from '../TopTalentPotential/TopTalentPotential';
import ExternalAssessmentEdge from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentEdge';
import ExternalAssessmentTraits from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentTraits';
import ExternalAssessmentCompetencies from '../../../../components/ExternalAssessmentEdge/ExternalAssessmentCompetencies';
import AllExternalAssessments from '../AllExternalAssessments/AllExternalAssessments';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import PersonalityArchetypesAssessments from '../PersonalityArchetypesAssessments/PersonalityArchetypesAssessments';

const ExternalAssessments = (props) => {
  const { assessmentDateOptions, isFilterLoading, staffId, tokenTalentProfile } = props;
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const roleUser = { id, roleActive: user.roleActive, permissions: user.permissions };
  const [query, setQuery] = useState({
    filterDate: {},
    filterType: {
      value: ASSESSMENT_TYPES[0].value,
      label: ASSESSMENT_TYPES[0].label,
    },
    filterStatus: '',
  });
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assessmentStatus, setAssessmentStatus] = useState(null);

  useEffect(() => {
    if (isEmpty(assessmentDateOptions)) return;
    setQuery((prev) => ({
      ...prev,
      filterDate: {
        value: !isEmpty(assessmentDateOptions[0].assessmentDate)
          ? assessmentDateOptions[0].assessmentDate
          : assessmentDateOptions[0].statusOaCompletion,
        label: !isEmpty(assessmentDateOptions[0].assessmentDate)
          ? moment(assessmentDateOptions[0].assessmentDate).format(DATE_TIME_FORMAT.SHORT_DAY)
          : assessmentDateOptions[0].statusOaCompletion,
      },
      filterStatus: assessmentDateOptions[0].statusOaCompletion,
    }));
  }, [assessmentDateOptions, isFilterLoading, setQuery]);

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  // Get data by Type
  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        setLoading(true);
        let queryParams = {
          type: query.filterType.value || ASSESSMENT_TYPE_VALUES.All,
          status_oa_completion: query.filterStatus ?? '',
        };
        const isCheckDate =
          isValidDate(new Date(query.filterDate.value)) && query.filterDate.value !== 'N/A';
        if (isCheckDate) {
          queryParams = {
            ...queryParams,
            assessment_date: query.filterDate.value,
          };
        }
        const res = await profileManagementApi.getExternalAssessmentsByType(
          id,
          roleUser.roleActive.roleId,
          queryParams,
          tokenTalentProfile
        );
        const data = res?.data?.result;
        if (res.status === 200) {
          setAssessmentData(data || null);
          setAssessmentStatus(!isEmpty(data) && isArray(data) ? data[0]?.status : data?.status);
        }
      } catch (error) {
        console.error(error);
        setAssessmentData({ data: [], type: query.filterType.value || ASSESSMENT_TYPE_VALUES.All });
      } finally {
        setLoading(false);
      }
    };

    if (!roleUser.roleActive.roleId) return;
    fetchAssessments();
  }, [roleUser.roleActive.roleId, query, staffId, id]);

  const returnBgdAssessmentStatus = () => {
    let tempBgd;
    switch (assessmentStatus) {
      case ASSESSMENT_STATUS_TYPES.NO_ASSESSMENT:
        tempBgd = '#A3A3A3';
        break;
      case ASSESSMENT_STATUS_TYPES.IN_PROGRESS:
      case ASSESSMENT_STATUS_TYPES.IN_PROGRESS_1:
        tempBgd = '#FDB924';
        break;
      case ASSESSMENT_STATUS_TYPES.INCOMPLETE:
        tempBgd = '#FDB924';
        break;
      case ASSESSMENT_STATUS_TYPES.COMPLETE:
      case ASSESSMENT_STATUS_TYPES.COMPLETED:
        tempBgd = '#0B882E';
        break;
      default:
        tempBgd = '#A3A3A3';
        break;
    }
    return tempBgd;
  };

  const returnStatusValue = (value) => {
    let tempVal;
    switch (value) {
      case ASSESSMENT_STATUS_TYPES.NO_ASSESSMENT:
        tempVal = ASSESSMENT_STATUS_TYPES.NO_ASSESSMENT;
        break;
      case ASSESSMENT_STATUS_TYPES.IN_PROGRESS:
      case ASSESSMENT_STATUS_TYPES.IN_PROGRESS_1:
        tempVal = ASSESSMENT_STATUS_TYPES.IN_PROGRESS;
        break;
      case ASSESSMENT_STATUS_TYPES.INCOMPLETE:
        tempVal = ASSESSMENT_STATUS_TYPES.INCOMPLETE;
        break;
      case ASSESSMENT_STATUS_TYPES.COMPLETE:
      case ASSESSMENT_STATUS_TYPES.COMPLETED:
        tempVal = ASSESSMENT_STATUS_TYPES.COMPLETED;
        break;
      default:
        tempVal = ASSESSMENT_STATUS_TYPES.NO_ASSESSMENT;
        break;
    }
    return tempVal;
  };

  return (
    <div className={styles.assessmentWrapper}>
      <div className={styles.assessmentHeader}>
        <h3 className={styles.mainTitle}>{query.filterType.label}</h3>
        <div className={styles.assessmentFilterList}>
          <ExternalAssessmentsFilter
            dataOptions={
              !isEmpty(assessmentDateOptions)
                ? assessmentDateOptions.map((item) => ({
                    value: item.assessmentDate ?? item.statusOaCompletion,
                    label: item.assessmentDate
                      ? moment(item.assessmentDate).format(DATE_TIME_FORMAT.SHORT_DAY)
                      : returnStatusValue(item.statusOaCompletion),
                    status: item.statusOaCompletion,
                  }))
                : []
            }
            setQuery={setQuery}
            filterType={ASSESSMENT_FILTER_TYPES.DATE}
            defaultValue={query.filterDate?.value}
            setAssessmentStatus={setAssessmentStatus}
          />
          <ExternalAssessmentsFilter
            dataOptions={ASSESSMENT_TYPES}
            setQuery={setQuery}
            filterType={ASSESSMENT_FILTER_TYPES.TYPE}
            defaultValue={query.filterType.value}
          />
          <AssessmentStatus>
            <h3 className="status-title">Assessment Status</h3>
            <div className="status-content">
              <span
                className="status-bgd"
                style={{ backgroundColor: returnBgdAssessmentStatus() }}
              ></span>
              <span className="status-label">{returnStatusValue(assessmentStatus)}</span>
            </div>
          </AssessmentStatus>
        </div>
      </div>
      <div className={styles.assessmentContent}>
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.All && (
          <AllExternalAssessments assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.GENERAL && (
          <GeneralExternalAssessments assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.LEARNING_AGILITIES && (
          <LearningAgilitiesAssessments assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.EDGE && (
          <ExternalAssessmentEdge assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL && (
          <TopTalentPotential
            assessmentData={assessmentData}
            assessmentType={query.filterType.value}
            loading={loading}
          />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.TRAITS && (
          <ExternalAssessmentTraits assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.DRIVERS && (
          <DriversAssessments assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.DERAILMENT_RISKS && (
          <DerailmentRisksAssessments assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.COMPETENCIES && (
          <ExternalAssessmentCompetencies assessmentData={assessmentData} loading={loading} />
        )}
        {query.filterType.value === ASSESSMENT_TYPE_VALUES.PERSONALITY_ARCHETYPES && (
          <PersonalityArchetypesAssessments assessmentData={assessmentData} loading={loading} />
        )}
      </div>
    </div>
  );
};

export default ExternalAssessments;
