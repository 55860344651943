import { Input, Modal, Pagination, Table, Select, Button } from 'antd';
import styled from 'styled-components';
import { font } from '../../assets/common';

export const SelectAnt = styled(Select)`
  color: var(--Base-Black, #181818);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.235px;

  .ant-select-selector {
    padding: 0 !important;
  }
`;

export const TitleDiv = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
`;

export const Title = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
  margin-bottom: 36px;
`;

export const Wrapper = styled(Modal)`
  .ant-modal-header {
    padding: 40px 40px 0 40px;
    border: none;
  }
  .ant-modal-close-x {
    margin-right: 20px;
    margin-top: 22px;
  }
  .ant-modal-body {
    padding: 0 40px;
  }
  .ant-dropdown-menu-item {
    padding: 10px 38px;
  }
`;

export const TableDiv = styled.div`
  padding-bottom: 40px;
`;

export const TableData = styled(Table)`
  .ant-table {
    border-radius: 6px;
    border: 1px solid rgba(151, 151, 151, 0.5);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    padding: ${(props) => props?.tableEdit && '6px 16px'};
    border-bottom: none;
  }
  .ant-table-tbody > tr[class~='border'] > td {
    border-top: 1px solid #f0f0f0 !important;
  }

  .ant-table-thead > tr > th {
    background-color: #fff;
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: ${font.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .textListPosition {
    color: #00908c;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.235px;
    cursor: pointer;

    &.disable {
      pointer-events: none;
      opacity: 0.75;
    }
  }

  .align_top_content {
    vertical-align: top;
    padding-top: 20px !important;
  }
`;

export const ColumnName = styled.div`
  color: #000;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

export const CustomNumber = styled.span`
  color: var(--gray-700, #374151);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const Text = styled.span`
  color: '#3F3C4C';
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 0;
`;

export const ButtonMore = styled.div`
  color: #00908c;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const ShowMoreLess = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  img {
    transform: ${(props) => (props?.readmore ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition-duration: 500ms;
    transition-property: transform;
  }
`;

export const TablePagination = styled(Pagination)`
  .ant-pagination-item {
    color: #00645c;
    border: none;
  }
  .ant-pagination-item-active {
    color: #00645c;
    border-radius: 4px;
    background: var(--Primary-100, #afdddc);
  }
`;

export const Legend2 = styled.div`
  width: 8px;
  height: 8px;
  padding: 5px;
  border-radius: 100%;
  background: var(--Light-Info-500, #005cb9);
  margin-right: 10px;
`;
export const Legend1 = styled.div`
  width: 8px;
  height: 8px;
  padding: 5px;
  border-radius: 100%;
  background: var(--Light-Info-500, #00a19c);
  margin-right: 10px;
`;

export const LegendText = styled.div`
  color: var(--Light-Gray-800, #3d3d3d);
  text-align: right;
  font-family: ${font.inter};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.173px;
`;

export const WrapperLegend = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  background: var(--Light-Gray-100, #f5f5f5);
  margin-bottom: 20px;
  width: fit-content;
`;

export const InputStyle = styled(Input)`
  border-radius: 4px;
  padding: 10px;
  width: 450px;
  /*  border: 1px solid #d9d9d9;
  background: #fff; */
  .ant-input {
    outline: none !important;
    border: none !important;
  }
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const WrapperFilter = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 8px;
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
`;

export const CustomFilterLabel = styled(Button)`
  display: 'block';
  background: transparent;
  border: none;
  outline: none;
  padding: '5px 10px';
  width: 100%;
  text-align: left;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.235px;
    color: #3f3c4c;
  }
`;

export const HideStatus = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #3f3c4c;
`;

export const CancelButton = styled.div`
  width: 108px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  color: #00a19c;
  padding: 8px 30px;
  margin-right: 150px;
  user-select: none;
  cursor: pointer;
`;
