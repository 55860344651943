import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import * as styles from './create-edit-news-announcement.module.scss';
import { BreadCrumb, ModelTC } from '../../../../../components';
import {
  formatDisplayTime,
  getBreadCrumbList,
  getFileExtension,
} from '../../../../../utils/helper';
import {
  BasicButton,
  BasicDatePicker,
  BasicInput,
  BasicLabel,
  BasicSwitch,
  BasicTextArea,
} from '../../../../../assets/common';
import {
  DATE_TIME_FORMAT,
  MESSAGE_TYPES,
  USER_ACCESS_MESSAGE,
} from '../../../../../utils/constants';
import {
  arrow_point_down,
  arrow_point_up,
  delete_mobility_plan,
  edit_green_icon,
  image_icon,
  play_video,
  plus_green_icon,
  upload_icon,
  x_delete_file,
} from '../../../../../assets/img';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { adminApi } from '../../../../../services/admin';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { Spin } from 'antd';

const BLOCK_TYPE = {
  NOT_UPLOAD: 'not upload',
  CONTENT: 'text',
  IMAGE: 'img',
  VIDEO: 'mp4',
};

const INIT_FILE_UPLOAD = {
  fileName: '',
  content: null,
  uploadedUrl: '',
};

const INIT_BLOCKS = [
  {
    id: '1',
    type: BLOCK_TYPE.NOT_UPLOAD,
    ...INIT_FILE_UPLOAD,
  },
  {
    id: '2',
    type: BLOCK_TYPE.CONTENT,
    content: '',
  },
];

const INIT_THUMBNAIL = {
  type: BLOCK_TYPE.NOT_UPLOAD,
  ...INIT_FILE_UPLOAD,
};

const EXIT_DISCARD_URL = '/admin/home-management/news-announcement';
const SIZE_100 = 100 * 1024 * 1024;
const SIZE_500 = 500 * 1024 * 1024;

const CreateEditNewsAnnouncement = ({ isEdit }) => {
  const [blocks, setBlocks] = useState(INIT_BLOCKS);
  const [thumbnail, setThumbnail] = useState(INIT_THUMBNAIL);
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [isSpotlight, setIsSpotlight] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      title: '',
      publish_date: null,
      expiry_date: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      publish_date: Yup.date().nullable().required(),
      expiry_date: Yup.date().nullable(),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const isDisableSaveBtn = useMemo(() => {
    const hasNonInputField = !formik.values.title || !formik.values.publish_date;
    const spotLightNonThumbnail = isSpotlight ? !thumbnail.content : false;
    const isNotInputContent = !blocks.find((b) => b.type === BLOCK_TYPE.CONTENT)?.content;

    return hasNonInputField || spotLightNonThumbnail || isNotInputContent;
  }, [formik.values.title, formik.values.publish_date, isSpotlight, thumbnail.content, blocks]);

  const typeDisplay = useMemo(() => {
    const text = isEdit ? 'Edit News and Announcement' : 'Create News and Announcement';
    return {
      breadCrumbList: getBreadCrumbList(['Dashboard', 'Admin', 'News and Announcement', text]),
      title: text,
    };
  }, [isEdit]);

  const handleClickDiscard = () => {
    if (isEdit) return; // TODO: implement edit
    const hasInputField = Object.values(formik.values).some((value) => value);
    const hasInputContent = blocks.map((b) => b.content).some((c) => c);

    if (hasInputField || isSpotlight || thumbnail.content || blocks.length > 2 || hasInputContent)
      setShowDiscardPopup(true);
    else history.push(EXIT_DISCARD_URL);
  };

  const handleClickSave = async (isDraft) => {
    try {
      const body = {
        ...formik.values,
        thumbnail_url: thumbnail.uploadedUrl,
        file_name_thumbnail: thumbnail.fileName,
        is_spotlight: isSpotlight,
        is_draft: isDraft,
        contents: blocks.map((b, idx) => ({
          type: b.type,
          content: b.type === BLOCK_TYPE.CONTENT ? b.content : b.uploadedUrl,
          file_name_attachment: b.fileName,
          attachment_order: idx + 1,
        })),
      };
      setLoading(true);
      const res = await adminApi.addNewsAndAnnouncement(body, roleId);
      if (res.status === 200) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `News and Announcement has been saved${isDraft && ' as draft'}.`,
          })
        );
        history.push(EXIT_DISCARD_URL);
      }
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleExitWithoutSave = () => {
    history.push(EXIT_DISCARD_URL);
  };

  const handleClickAddAttachment = () => {
    setBlocks((prev) => [
      ...prev,
      {
        id: uuidv4(),
        type: BLOCK_TYPE.NOT_UPLOAD,
        ...INIT_FILE_UPLOAD,
      },
    ]);
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.wrapper}>
        <BreadCrumb level={4} breadCrumbList={typeDisplay.breadCrumbList} />
        <div className={styles.title}>{typeDisplay.title}</div>
        <div className={styles.btnRow}>
          <BasicButton onClick={handleClickDiscard}>Discard</BasicButton>
          <BasicButton onClick={() => handleClickSave(true)} disabled={isDisableSaveBtn}>
            Save as Draft
          </BasicButton>
          <BasicButton mode="teal" onClick={() => handleClickSave()} disabled={isDisableSaveBtn}>
            Save
          </BasicButton>
        </div>
        <ModelTC
          info={{
            type: 'withoutSavingNewsAndAnnouncement',
            visible: showDiscardPopup,
            disableSubmit: isDisableSaveBtn,
            setVisible: setShowDiscardPopup,
            handleSubmit: () => handleClickSave(true),
            onClose: handleExitWithoutSave,
          }}
        />

        <div className={styles.firstForm}>
          <div className="w-100">
            <div className={styles.baseForm}>
              <div>
                <BasicLabel>Announcement Title</BasicLabel>
                <BasicInput
                  placeholder="Announcement Title"
                  maxLength={250}
                  onBlur={(e) => {
                    const value = e.target.value;
                    formik.setFieldValue('title', value);
                  }}
                />
              </div>
              <div className={styles.dateInputRow}>
                <div>
                  <BasicLabel>Publish Date</BasicLabel>
                  <BasicDatePicker
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    placeholder="Date"
                    getPopupContainer={(trigger) => trigger}
                    onChange={(date) => {
                      formik.setFieldValue('publish_date', date);
                    }}
                  />
                </div>
                <span className={styles.dateDash}>-</span>
                <div>
                  <BasicLabel>Expiry Date</BasicLabel>
                  <BasicDatePicker
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    placeholder="Date"
                    getPopupContainer={(trigger) => trigger}
                    disabledDate={(current) => current < formik.values.publish_date}
                    onChange={(date) => {
                      formik.setFieldValue('expiry_date', date);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.thumbnailForm}>
            <BasicLabel>Thumbnail/Banner</BasicLabel>
            <div className={styles.thumbnailUpload}>
              <UploadImgVideo onlyImg data={thumbnail} setData={setThumbnail} />
              <div className={styles.spotlight}>
                <BasicSwitch
                  id="spotlight"
                  checked={isSpotlight}
                  onChange={(checked) => {
                    setIsSpotlight(checked);
                  }}
                />
                <BasicLabel hmtlFor="spotlight">Set image as Spotlight</BasicLabel>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.customizeBlog}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className={styles.subTitle}>Customise your blog here</div>
            <BasicButton className={styles.addAttachBtn} onClick={handleClickAddAttachment}>
              <img alt="add-attachment" src={plus_green_icon} /> Add attachment
            </BasicButton>
          </div>
          {blocks.map((block, index) => (
            <SortableBlock
              key={block.id}
              block={block}
              index={index}
              blocks={blocks}
              setBlocks={setBlocks}
            />
          ))}
        </div>
      </div>
    </Spin>
  );
};

CreateEditNewsAnnouncement.propTypes = {
  isEdit: PropTypes.bool,
};
export default CreateEditNewsAnnouncement;

const SortableBlock = ({ block, blocks, setBlocks, index }) => {
  const isContent = useMemo(() => {
    return block.type === BLOCK_TYPE.CONTENT;
  }, [block.type]);

  const blockLeftStyle = useMemo(() => {
    return { padding: `${isContent ? 0 : 16}px` };
  }, [isContent]);

  const handleChangeContent = (e) => {
    const value = e.target.value;
    setBlocks((prev) => {
      return [
        ...prev.map((i) => ({ ...i, content: i.type === BLOCK_TYPE.CONTENT ? value : i.content })),
      ];
    });
  };

  const setFileData = (data) => {
    setBlocks((prev) => {
      return [...prev.map((i) => (i.id === data.id ? data : i))];
    });
  };

  const handleMoveDown = () => {
    setBlocks((prev) => {
      const newBlocks = [...prev];
      [newBlocks[index + 1], newBlocks[index]] = [
        { ...newBlocks[index] },
        { ...newBlocks[index + 1] },
      ];
      return newBlocks;
    });
  };

  const handleMoveUp = () => {
    setBlocks((prev) => {
      const newBlocks = [...prev];
      [newBlocks[index - 1], newBlocks[index]] = [
        { ...newBlocks[index] },
        { ...newBlocks[index - 1] },
      ];
      return newBlocks;
    });
  };

  const handleDelete = () => {
    setBlocks((prev) => {
      const newBlocks = prev.filter((i) => i.id !== prev[index].id);
      return newBlocks;
    });
  };

  return (
    <div className={styles.sortableBlock}>
      {isContent && <div className={styles.subTitle}>Content</div>}
      <div className={styles.block}>
        <div className={styles.left} style={blockLeftStyle}>
          {isContent ? (
            <BasicTextArea rows={14} resize="none" onChange={handleChangeContent} />
          ) : (
            <UploadImgVideo data={block} setData={setFileData} />
          )}
        </div>
        <div className={styles.right}>
          <BasicButton
            className={styles.iconBtn}
            disabled={blocks.length - 1 === index}
            onClick={handleMoveDown}
          >
            <img alt="move-down" src={arrow_point_down} />
          </BasicButton>
          <BasicButton className={styles.iconBtn} disabled={!index} onClick={handleMoveUp}>
            <img alt="move-up" src={arrow_point_up} />
          </BasicButton>
          {!isContent && (
            <BasicButton
              className={styles.iconBtn}
              disabled={blocks.length === INIT_BLOCKS.length}
              onClick={handleDelete}
            >
              <img alt="delete" src={delete_mobility_plan} />
            </BasicButton>
          )}
        </div>
      </div>
    </div>
  );
};
SortableBlock.propTypes = {
  block: PropTypes.object,
  blocks: PropTypes.array,
  setBlocks: PropTypes.func,
  index: PropTypes.number,
};

const UploadImgVideo = React.memo(({ data, setData, onlyImg }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const contianerRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState('');
  const containerRef = useRef();

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;
    const fileType = file.type.startsWith('image') ? BLOCK_TYPE.IMAGE : BLOCK_TYPE.VIDEO;

    setIsUploading(true);
    const sasUrl = await getLinkUpload(file);
    const isUploadSuccess = await uploadFile(sasUrl, file);
    if (isUploadSuccess) {
      const newData = {
        ...data,
        type: fileType,
        content: sasUrl,
        fileName: file.name,
        uploadedUrl: sasUrl.split('?')[0],
      };
      setData(newData);
    }
    setIsUploading(false);
  }, []);

  const getLinkUpload = async (file) => {
    try {
      const fileName = `${uuidv4()}.${getFileExtension(file.name)}`;
      const res = await adminApi.getUploadUrlAnnouncement(fileName);
      if (res.status === 200) return res.data.result;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (sasUrl, file) => {
    try {
      setFileName(file.name);
      const processBarEl = containerRef.current.querySelector('progress');
      const remainTimeEl = containerRef.current.querySelector('span');
      const startTime = Date.now();
      const formData = new FormData();
      formData.append('file', file);

      const res = await axios.put(sasUrl, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
            processBarEl.value = percentComplete;
            const elapsedTime = (Date.now() - startTime) / 1000; // Convert to seconds
            const uploadSpeed = progressEvent.loaded / elapsedTime;
            const remainingBytes = progressEvent.total - progressEvent.loaded;
            const remainingTime = remainingBytes / uploadSpeed;
            remainTimeEl.textContent = formatDisplayTime(remainingTime);
          }
        },
      });
      if (res.status === 201) return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const videoType = useMemo(() => {
    return onlyImg ? {} : { 'video/mp4': ['.mp4'] };
  }, [onlyImg]);

  const handlePlayClick = () => {
    setIsVideoPlaying(true);
    const video = contianerRef.current.getElementsByTagName('video')[0];
    video.play();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      ...videoType,
    },
    noClick: true,
    maxSize: onlyImg ? SIZE_100 : SIZE_500, // 500 MB
  });

  const displayVideoDuration = useMemo(() => {
    return formatDisplayTime(videoDuration);
  }, [videoDuration]);

  const handleDelete = () => {
    const newData = { ...data, type: BLOCK_TYPE.NOT_UPLOAD, content: null };
    setData(newData);
  };

  return (
    <div ref={containerRef} className={styles.uploadContainer}>
      {isUploading ? (
        <div className={styles.uploading}>
          <img src={image_icon} alt="image-icon" />
          <div className="w-100">
            <div className={styles.info}>
              <div className={styles.fileName}>{fileName}</div>
              <div className={styles.remainTime}>
                Time Remaining: <span></span>
              </div>
            </div>
            <progress value="0" max="100" />
          </div>
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {data.content ? (
            <div className={styles.fileContainer} ref={contianerRef}>
              {data.type === BLOCK_TYPE.VIDEO ? (
                <>
                  <video
                    id="video-preview"
                    src={data.content}
                    className={styles.videoElement}
                    onPlay={() => setIsVideoPlaying(true)}
                    onPause={() => setIsVideoPlaying(false)}
                    onLoadedMetadata={(e) => setVideoDuration(e.target.duration)}
                    controls={isVideoPlaying}
                  />

                  {!isVideoPlaying && (
                    <button className={styles.overlay} onClick={handlePlayClick}>
                      <div className={styles.playButton}>
                        <img src={play_video} alt="play" />
                      </div>
                      <div className={styles.durationLabel}>{displayVideoDuration}</div>
                    </button>
                  )}
                </>
              ) : (
                <img src={data.content} alt="attachment" />
              )}

              {!isVideoPlaying && (
                <div className={onlyImg ? styles.actionButtonsThumbnail : styles.actionButtons}>
                  <BasicButton className={styles.iconBtn} onClick={open}>
                    <img src={edit_green_icon} alt="edit_file" />
                  </BasicButton>
                  <BasicButton className={styles.iconBtn} onClick={handleDelete}>
                    <img src={x_delete_file} alt="remove_file" />
                  </BasicButton>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.uploadFile}>
              <div className={styles.uploadIcon}>
                <img src={upload_icon} alt="upload_icon" />
              </div>
              <div className={styles.boldText}>Drag and drop your image here</div>
              <div className={styles.uploadInstruction}>
                {!onlyImg ? (
                  <span>
                    • Limit 500MB per file <br />
                    e.g. : .jpeg, .png, .mp4
                  </span>
                ) : (
                  <span>Limit 100MB .jpeg, .png</span>
                )}
              </div>
              {!onlyImg && <div className={styles.boldText}>OR</div>}
              <BasicButton className={styles.browseBtn} onClick={open}>
                Browse File
              </BasicButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
UploadImgVideo.displayName = 'UploadImgVideo';
UploadImgVideo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  onlyImg: PropTypes.bool,
};
