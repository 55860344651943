import { Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BasicButton } from '../../../../../../assets/common';
import { adminApi } from '../../../../../../services/admin';
import * as styles from './data-table.module.scss';
import PopoutSetName from './PopoutSetName/PopoutSetName';
import * as SC from './styled';

const SORT_FIELD = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

const statusStyle = {
  Failed: {
    backgroundColor: '#FBE2E2',
    color: '#A91A1F',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
  Running: {
    backgroundColor: '#E1F2FE',
    color: '#00488F',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
  Completed: {
    backgroundColor: '#E7F4E8',
    color: '#096924',
    height: '30px',
    padding: '5px 10px',

    borderRadius: '6px',
  },
  Queue: {
    backgroundColor: '#FEF2DC',
    color: '#65490E',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
};
export default function DataTable({ data, query, page, setPage, totalPage, setSortStartDateAndEndDate }) {
  const { limit } = query;
  const roleActive = useSelector((state) => state.user.roleActive);
  const history = useHistory();
  const [currentSetDetail, setCurrentSetDetail] = useState(null);
  const [popoutSetName, setPopoutSetName] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleViewDetails = (item) => {
    fetchSetMatchingDetails(item?.id);
  };

  const fetchSetMatchingDetails = async (run_id) => {
    try {
      setLoading(true);
      const res = await adminApi.getSetMatchingDetails(roleActive.roleId, run_id);
      if (res.status === 200) {
        setCurrentSetDetail(res.data.result);
        setPopoutSetName(true);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>,
      width: '5%',
      align: 'center',
    },
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      width: '10%',
    },
    {
      title: 'Position Name',
      dataIndex: 'position_name',
      width: '15%',
    },
    {
      title: 'Matching Criteria Set',
      dataIndex: 'matching_criteria_set',
      width: '20%',
    },
    {
      title: 'Start Date & Time',
      dataIndex: 'start_date',
      width: '120',
      align: 'left',
      className: `customDateColumn`,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.start_date - b.start_date,
    },
    {
      title: 'End Date & Time',
      dataIndex: 'end_date',
      width: '120',
      align: 'center',
      className: `customDateColumn`,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.end_date - b.end_date,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      align: 'left',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'x',
      align: ' center',
      width: '20%',
    },
  ];

  const fromResult = (page - 1) * limit + 1;
  const toResults = (page - 1) * limit + limit;

  const paginationConfig = {
    current: page,
    total: totalPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && setPage(pageNum),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > totalPage ? totalPage : toResults}</b> of&nbsp;
        <b>{totalPage}</b> results
      </>
    ),
  };

  const onChange = (pagination, filters, sorter) => {
    setSortStartDateAndEndDate((prev) => ({
      ...prev,
      startDate: sorter?.field === SORT_FIELD.START_DATE ? sorter?.order : null,
      endDate: sorter?.field === SORT_FIELD.END_DATE ? sorter?.order : null,
    }));
  };

  return (
    <div style={{ marginTop: 24, marginBottom: 150 }}>
      <SC.TableManagement>
        <Table
          columns={columns}
          dataSource={data.map((item, _index) => ({
            key: `${item.position_code}-${item.number}`,
            position_code: item.position_code,
            position_name: <span className={styles.positionName}>{item.position_name}</span>,
            matching_criteria_set: (
              <span
                data-testid={`item-${item.id}`}
                className={styles.matchingCriteria}
                onKeyDown={() => {}}
                onClick={() => {
                  handleViewDetails(item);
                }}
                style={loading ? { cursor: 'no-drop', pointerEvents: 'none', opacity: 0.5 } : { cursor: 'pointer' }}
              >
                {item.matching_criteria_set}
              </span>
            ),
            start_date: <div className={styles.font}>{!item.start_date ? '--' : moment(item.start_date).format('DD/MM/yyyy HH:mm')}</div>,
            end_date: (
              <div className={styles.font}>
                {item.end_date === '--' || !item.end_date ? item.end_date : moment(item.end_date).format('DD/MM/yyyy HH:mm')}
              </div>
            ),
            status: (
              <>
                <span className={styles.font} style={statusStyle[item.status]}>
                  {item.status}
                </span>
                {item.status === 'Failed' && item.reason && <div style={{ marginTop: 4, fontSize: 12 }}>{item.reason}</div>}
              </>
            ),

            action: (
              <BasicButton
                mode={item?.latest_item ? 'border-teal' : ''}
                style={{
                  paddingLeft: 7,
                  paddingRight: 7,
                  width: 100,
                }}
                onKeyDown={() => {}}
                onClick={
                  item?.latest_item
                    ? () => {
                        history.push(`/admin/sp-management/view-result-matching/${item.position_code}/${item.id}?type=manual`);
                      }
                    : () => {}
                }
                disabled={!item?.latest_item}
              >
                View Result
              </BasicButton>
            ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
          onChange={onChange}
        />
      </SC.TableManagement>
      {popoutSetName && <PopoutSetName popoutSetName={popoutSetName} setPopoutSetName={setPopoutSetName} currentSetDetail={currentSetDetail} />}
    </div>
  );
}
