import { map } from 'lodash';
import moment from 'moment';
import { AGENDA_SHORT_TYPES, DATE_TIME_FORMAT, PERMISSION } from '../utils/constants';
import axiosClient from './axiosClient';
import queryString from 'query-string';

const getTime = (dateInput) => {
  if (!dateInput) return '';
  const date = moment(dateInput).local().toDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  return `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
};

export const meetingApi = {
  editMeetingFromCancel: (role, meeting) => {
    const url = `/meetings/${meeting.meeting_id}/submit-to-edit-cancelled`;
    return axiosClient.patch(
      url,
      {},
      {
        headers: {
          role: role.roleId,
        },
      }
    );
  },
  getVersionHistory: (id, role, agendaId, params) => {
    const url = `/agendas/${agendaId}/versions`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
      params,
    });
  },
  deleteVersionHistory: (id, role, angedaId, params) => {
    const url = `/agendas/${angedaId}/versions`;
    return axiosClient.delete(url, {
      headers: {
        role: role.roleId,
      },
      params,
    });
  },
  setAsMain: (params) => {
    const { agendaId, version, role } = params;
    const url = `agendas/${agendaId}/versions/${version}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  addRemarks: (headers, agendaId, staffId, body) => {
    const { role } = headers;
    const url = `/agendas/${agendaId}/add-remarks/${staffId}`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  getDetailMeetings: (headers, meetingId) => {
    const { role } = headers;
    const url = `/get-details-meeting/${meetingId}`;
    const options = {
      headers: {
        role: role.roleId,
      },
    };
    return axiosClient.get(url, options);
  },

  getTalentFromAi: (roleId, params) => {
    const {
      postions,
      line,
      _limit,
      setId,
      is_save,
      is_search_by_click,
      agenda_id,
      salary_grade,
      birth_name,
      match_from,
      match_to,
    } = params;
    const url = `/succession-planning-recommendation/from-ai`;
    const options = {
      headers: {
        role: roleId,
      },
      params: {
        position_codes: postions,
        line: line,
        _limit: _limit,
        setId,
        is_save,
        is_search_by_click,
        agenda_id,
        salary_grade,
        birth_name,
        match_from,
        match_to,
      },
    };
    return axiosClient.get(url, options);
  },

  saveTableBFromAi: (roleId, body) => {
    const url = `succession-planning-recommendation/from-ai/result`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  saveTableBFromTalentReview: (roleId, body) => {
    const url = `agendas/talents-review-recommendation/result`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getTalentDetailsFromAi: (staff_id, position_codes) => {
    const url = `/succession-planning-recommendation/from-ai/talent-details`;
    return axiosClient.get(url, {
      params: {
        staff_id,
        position_codes,
      },
    });
  },

  getRemarks: (params) => {
    const { role, agenda_id, version, position_codes, type } = params;
    let url;
    if (type === AGENDA_SHORT_TYPES.SUCCESSION_PLANNING) {
      url = `/agendas/${agenda_id}/${version}/talent-remarks?position_codes=${position_codes}`;
    } else {
      url = `/agendas/${agenda_id}/${version}/talent-remarks`;
    }

    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
  searchUserSync: (params) => {
    const { role, keyword, agenda_id, version } = params;
    const url = `/agendas/${agenda_id}/${version}/users`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
      params: {
        keyword,
      },
    });
  },
  sendSyndication: (params) => {
    const { body, role, agenda_id, version } = params;
    const url = `/agendas/${agenda_id}/${version}/send-syndications`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  updateRemarks: (params) => {
    const { role, remark_id, body } = params;
    const url = `/agendas/remarks/${remark_id}`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  deleteRemarks: (params) => {
    const { role, agenda_id, remark_id } = params;
    const url = `/agendas/${agenda_id}/remarks/${remark_id}`;
    return axiosClient.delete(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  saveVersion: (params, body, headers) => {
    const { meeting_id, agenda_id } = params;
    const url = `/meetings/${meeting_id}/agendas/succession-planning/${agenda_id}/save-version`;
    return axiosClient.post(url, body, {
      headers: {
        role: headers && headers.role && headers.role.roleId,
        permission: PERMISSION.CREATE_MEETING,
      },
    });
  },

  getAgendaList: (params, roleId) => {
    const { meetingID, business } = params;
    const url = `/meetings/${meetingID}/agendas`;
    return axiosClient.get(url, {
      params: {
        business,
      },
      headers: {
        role: roleId,
      },
    });
  },

  getAgendaDetail: (params) => {
    const { agendaId, roleId, isHistorical, version } = params;
    const url = version ? `/agendas/${agendaId}/${version}` : `/agendas/${agendaId}/0`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: { is_historical_approved_sp: isHistorical },
    });
  },

  getPositionRatio: ({ agendaId, version, roles }) => {
    const { role } = roles;
    return axiosClient.get(`/succession-planning-ratio/${agendaId}/${version}`, {
      headers: {
        role: role.roleId,
      },
    });
  },

  saveTemplate: (params, roles) => {
    const { role } = roles;
    const { body, meetingID } = params;
    const url = `/meetings/${meetingID}/agendas/succession-planning`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.CREATE_MEETING,
      },
    });
  },
  saveAsDraftTemplate: (params, roles) => {
    const { role } = roles;
    const { body, meetingID, agendaId } = params;
    const url = `/meetings/${meetingID}/agendas/${agendaId}/update-template`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  addTalentOutOfAi: (params, roleId) => {
    const url = `/succession-planning-recommendation/out-of-ai-and-talent-reviews`;

    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.CREATE_MEETING,
      },
      params,
    });
  },

  addPosition: (params, roles) => {
    const { role } = roles;
    const { search } = params;
    const url = `/succession-planning/get-search-incumbent?search=${search}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  getAgendaDetailByVersion: (params, roleId) => {
    const { agendaId, version } = params;
    const url = `agendas/${agendaId}/versions/${version}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  updateTemplate: (params, roles) => {
    const { role } = roles;
    const { body, meetingID, agendaId } = params;
    const url = `/meetings/${meetingID}/agendas/${agendaId}/update-template`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  agendaApprove: (params, roles) => {
    const { role } = roles;
    const { agendaId, body } = params;
    const url = `/agendas/${agendaId}/approve`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  agendaDisapprove: (params, roles) => {
    const { role } = roles;
    const { agendaId } = params;
    const url = `/agendas/${agendaId}/disapprove`;
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          role: role.roleId,
        },
      }
    );
  },
  getPersonBelongPositions: ({ positionCode }) => {
    const url = `succession-planning-recommendation/populated-talents`;
    return axiosClient.get(url, {
      params: {
        position_codes: positionCode,
      },
    });
  },
  createOrUpdateAgenda: ({ body, roleUser, meetingID }) => {
    const { roleActive } = roleUser;
    const url = `/meetings/${meetingID}/agendas`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  createAgendaMS: ({ body, roleUser }) => {
    const { roleActive } = roleUser;
    const url = `/mobility-simulations-agenda`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  getAgendaMS: (agendaId, roleId) => {
    const url = `/mobility-simulations-agenda/${agendaId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
  updateAgendaMs: (agendaId, body, roleId) => {
    const url = `/mobility-simulations-agenda/${agendaId}`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },
  updateStatusOfMeeting: ({ meetingId, roleUser }) => {
    const { roleActive } = roleUser;
    return axiosClient.post(`/meetings/${meetingId}/publish`, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  createMeeting: (data, roleActive) => {
    const headerApi = {
      headers: {
        role: roleActive.roleId,
      },
    };
    return axiosClient.post('/meetings', data, headerApi);
  },

  updateMeeting: (meetingData, roleActive) => {
    const { idMeeting, ...data } = meetingData;
    const headerApi = {
      headers: {
        role: roleActive.roleId,
      },
    };
    return axiosClient.put(`/meetings/update/${idMeeting}`, data, headerApi);
  },

  getMeeting: ({ idMeeting, roleUser }) => {
    const { roleActive } = roleUser;
    const url = `/meeting/${idMeeting}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
      transformResponse: (resRaw) => {
        const res = JSON.parse(resRaw);
        const {
          name,
          start_at,
          end_at,
          roles: { chairman, committee, secretariat, facilitator },
          meeting_committee_ids,
          meeting_facilitator_ids,
          ...rest
        } = res.result;
        return {
          ...res,
          result: {
            ...rest,
            meeting_name: name,
            date: moment(new Date(start_at)).format(DATE_TIME_FORMAT.DATE_SPACE),
            start_time: getTime(start_at),
            end_time: getTime(end_at),
            chairman: map(chairman, (it) => ({ label: it.name, value: it.id })),
            meeting_committee: map(committee, ({ name, id }) => ({
              label: name,
              value: id,
            })),
            meeting_facilitator: map(facilitator, ({ name, id }) => ({
              label: name,
              value: id,
            })),
            meeting_secretariats: map(secretariat, (it) => ({
              business_type: it.business_type,
              role_id: it.role_id,
              bu_unit_id: it.business_value,
            })),
          },
        };
      },
    });
  },
  deleteAgendas: ({ agenda_ids, roleUser }) => {
    const { roleActive } = roleUser;
    const url = `/delete/agendas`;
    return axiosClient.delete(url, {
      headers: {
        role: roleActive.roleId,
      },
      params: {
        agenda_id: agenda_ids,
      },
    });
  },

  getMeetingDetailById: (params, roles) => {
    const { role } = roles;
    const { meetingId } = params;
    const url = `/meeting/${meetingId}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  getAgendaByMeetingId: (params, roles) => {
    const { role } = roles;
    const { meetingId, status } = params;
    const url = `invitee-agendas/${meetingId}?statuses=${status}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
  getAgendas: ({ idMeeting, roleUser, status }) => {
    const { roleActive } = roleUser;
    const url = status
      ? `invitee-agendas/${idMeeting}?statuses=${status}`
      : `invitee-agendas/${idMeeting}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  addExistedAgendas: ({ idMeeting, roleUser, body }) => {
    const { roleActive } = roleUser;
    const url = `/meetings/${idMeeting}/add-existed-agendas`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleActive.roleId,
      },
      params: {
        meeting_id: idMeeting,
      },
    });
  },
  countNotifications: (roleActive) => {
    const url = `/notifications/total-uncheck`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
      params: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  },

  getNotifications: (roleActive) => {
    const url = `/notifications`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
      params: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  },

  getTasks: (role) => {
    const url = `/notifications/tasks`;
    return axiosClient.get(url, {
      headers: {
        role,
      },
    });
  },

  cleanTotalNotifications: () => {
    const url = `/notifications/clean-total-uncheck`;
    return axiosClient.patch(url);
  },

  updateSeenNotification: (id) => {
    const url = `/mark-notification-seen/${id}`;
    return axiosClient.patch(url);
  },
  getAgendasExisted: (params) => {
    const { keyword, roleActive } = params;
    const url = keyword ? `/agendas/existed?keyword=${keyword}` : `/agendas/existed`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  updateAgendaExisted: ({ agendaId, roleUser, type }) => {
    const { roleActive } = roleUser;
    const url = `/agendas/${agendaId}/bring-to-forward?type=${type}`;
    return axiosClient.patch(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  deleteMeeting: ({ idMeeting }) => {
    const url = `/meetings/${idMeeting}`;
    return axiosClient.delete(url, {});
  },
  resendInvitationsByMeetingId: (meetingId) => {
    return axiosClient.post(`/meetings/${meetingId}/resend-invitations`);
  },
  startMeetingById: (meetingId) => {
    return axiosClient.patch(`/meetings/${meetingId}/start`);
  },
  cancelMeetingById: (meetingId) => {
    return axiosClient.patch(`/meetings/${meetingId}/cancel`);
  },
  getRoleInMeeting: ({ idMeeting, roleUser }) => {
    const url = `/meetings/${idMeeting}/my-roles`;
    return axiosClient.get(url, {
      headers: {
        role: roleUser && roleUser.roleActive && roleUser.roleActive.roleId,
      },
    });
  },
  updateStatusAgenda: ({ agendaId, ...body }) => {
    return axiosClient.patch(`/agendas/${agendaId}/status`, body);
  },
  changeAgendaStatusById: (agendaId) => {
    return axiosClient.put(`/agenda/change-paper-ready/${agendaId}`);
  },
  updateStepMeeting: ({ idMeeting, body }) => {
    return axiosClient.patch(`/meetings/${idMeeting}/step`, body);
  },
  finalizeSPAgenda: (agendaId) => {
    return axiosClient.patch(`/agendas/${agendaId}/finalize`);
  },
  unfinalizeSPAgenda: (agendaId) => {
    return axiosClient.patch(`/agendas/${agendaId}/unfinalize`);
  },
  cancelUploadMoM: (body) => {
    return axiosClient.post('/meetings/add-file-mom', body);
  },
  uploadMoMForMeeting: (data, source) => {
    const formData = new FormData();
    formData.append('meeting_id', data.meeting_id);
    formData.append('file', data.file);
    return axiosClient.post(`/meeting/upload-MoM`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: source.token,
    });
  },
  generateMoM: (data, roleId) => {
    return axiosClient.post(`/meeting/generate-MoM`, data, {
      headers: {
        role: roleId,
        permission: PERMISSION.GENERATE_MOM_FILE,
      },
    });
  },

  fetchGenerateStatus: (blockBlob) => {
    return axiosClient.get(`/meeting/generate-MoM/status`, {
      params: { blockBlob },
    });
  },

  confirmGenerate: (blockBlob) => {
    return axiosClient.delete(`/meeting/generate-MoM/confirm-generate`, {
      params: { blockBlob },
    });
  },

  deleteMoMForMeeting: (id) => {
    const url = `/meeting/${id}/MoM`;
    return axiosClient.delete(url);
  },
  completeMeeting: (meetingId) => {
    return axiosClient.put(`/meetings-complete/${meetingId}`);
  },
  notifyFacilitator: (meetingId, roleId) => {
    return axiosClient.post(
      `/meetings/${meetingId}/notify-facilitator`,
      {},
      {
        headers: {
          role: roleId,
        },
      }
    );
  },
  concludeMeeting: (meetingId, roleActive) => {
    return axiosClient.put(
      `/meetings/${meetingId}/conclude`,
      {},
      {
        headers: {
          role: roleActive.roleId,
        },
      }
    );
  },
  getSetListSPMatchingCriteria: (positionCode, roleId) => {
    return axiosClient.get(
      `/succession-planning-recommendation/filter-matching-criteria?positionCode=${positionCode}`,
      {},
      {
        headers: {
          role: roleId,
        },
      }
    );
  },

  getTalentToBuilder: (params, roleActive) => {
    const stringified = queryString.stringify(params);
    return axiosClient.get(`agendas-list/pagination?${stringified}`, {
      headers: {
        role: roleActive.roleId,
        permission: PERMISSION.REPORT_BUILDER,
      },
    });
  },

  getAgendaTalentBuilder: (agenda_id, roleActive) => {
    return axiosClient.get(`/agenda-talent/${agenda_id}`, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getListConcludedAgendas: (searchParams, roleId) => {
    const url = '/agenda-talent-review/list';
    return axiosClient.get(url, {
      params: searchParams,
      headers: {
        role: roleId,
      },
    });
  },

  getSPAgendaDetail: (params) => {
    const { roleId, agendaId, version } = params;
    const url = `/agendas/sp-details/${agendaId}/${version}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
  getSPAgendaReadiness: (params) => {
    const { roleId, agendaId, version } = params;
    const url = `/agendas/sp-readiness/${agendaId}/${version}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
  getSPAgendaIncumbent: (params) => {
    const { roleId, agendaId, version } = params;
    const url = `/agendas/sp-incumbent/${agendaId}/${version}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
};
