import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { ModelTC, TalentReviewModal } from '..';
import talentProfileApi, { getTalentReview } from '../../services/talentProfiles';
import { BUSINESS_NAME_TALENT_REVIEW, PERMISSION } from '../../utils/constants';
import AddTalentReviewModal from '../TalentReviewModal/AddTalentReviewModal';
import styles from './talent-review.module.scss';
import { pushMessage } from '../../store/alertMessageSlice';
import { useDispatch } from 'react-redux';

const TalentReviewList = (props) => {
  const { data } = props;

  const listItems = data.map((item, index) => {
    const business = BUSINESS_NAME_TALENT_REVIEW.find((value) => value.name === item?.business);
    const styleBackGround = { backgroundColor: `${business?.color || 'gray'}` };
    return (
      index < 6 && (
        <li className={styles.content} key={index}>
          <div className={styles.platform} style={styleBackGround}>
            {business?.symbol}
          </div>
          <div className={styles.right}>
            <p className={styles.date}>{moment(item.reviewDate).format('D MMMM YYYY') || '-'}</p>
            <p>{item.platform}</p>
          </div>
        </li>
      )
    );
  });
  return (
    <ul className={styles.menu} data-testid="list">
      {listItems}
    </ul>
  );
};

const TalentReview = (props) => {
  const { profileId, roleUser, tokenTalentProfile } = props;
  const dispatch = useDispatch();
  const { permissions } = roleUser;
  const [isOpen, setIsOpen] = useState(false);
  const [talentReviewList, setTalentReviewList] = useState([]);
  const [talentReviewObj, setTalentReviewObj] = useState({});
  const [isOpenAddTalentReview, setIsOpenAddTalentReview] = useState(false);
  const [isOpenDeleteTalentReview, setIsOpenDeleteTalentReview] = useState(false);

  const isHasEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_TALENT_REVIEW);
  }, [permissions]);

  const showViewNote = () => {
    setIsOpen(true);
  };

  const handleDelete = async () => {
    try {
      const res = await talentProfileApi.deleteTalentReview(
        talentReviewObj.id,
        roleUser?.roleActive
      );
      if (res.status === 200) {
        await fetchApi();
        setIsOpenDeleteTalentReview(false);
        dispatch(
          pushMessage({
            type: 'success',
            message: 'Talent Review successfully deleted ',
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchApi() {
    try {
      const response = await getTalentReview({ roleUser, id: profileId, tokenTalentProfile });
      if (response.status === 200) {
        setTalentReviewList(response.data.result || []);
      }
    } catch (error) {
      console.error(error);
      setTalentReviewList([]);
    }
  }

  useEffect(() => {
    if (roleUser.roleActive.roleId && profileId) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive, profileId]);

  return (
    <div className={styles.talent_review} data-testid="talent-review">
      <h1>Talent Review</h1>
      <TalentReviewList data={talentReviewList} />
      <p
        className={styles.view_more}
        onKeyDown={() => {}}
        onClick={showViewNote}
        data-testid="click"
      >
        View more Talent review
      </p>
      {isOpen && (
        <TalentReviewModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={talentReviewList}
          setIsOpenAddTalentReview={setIsOpenAddTalentReview}
          setTalentReviewObj={setTalentReviewObj}
          setIsOpenDeleteTalentReview={setIsOpenDeleteTalentReview}
          isHasEditPermission={isHasEditPermission}
        />
      )}
      {isOpenAddTalentReview && (
        <AddTalentReviewModal
          show={isOpenAddTalentReview}
          setShow={setIsOpenAddTalentReview}
          fetchApi={fetchApi}
          talentReviewObj={talentReviewObj}
          setTalentReviewObj={setTalentReviewObj}
        />
      )}
      <ModelTC
        info={{
          type: 'deleteTalentReview',
          visible: isOpenDeleteTalentReview,
          setVisible: setIsOpenDeleteTalentReview,
          handleSubmit: () => handleDelete(),
          onClose: () => setIsOpenDeleteTalentReview(false),
        }}
      />
    </div>
  );
};

export default TalentReview;
