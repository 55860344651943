import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './talent-video.module.scss';
import TalentVideoItem from './components/TalentVideoItem/TalentVideoItem';
import { pets_chevron_right, plus_green_icon } from '../../../../assets/img';
import talentProfileApi from '../../../../services/talentProfiles';
import { Spin } from 'antd';
import AddTalentVideo from './components/AddTalentVideo/AddTalentVideo';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import ViewMoreVideoModal from './components/ViewMoreVideoModal/ViewMoreVideoModal';

const INIT_DATA = { videos: [], total: 0 };
const MODAL_STYLE = { closePosition: 30 };

const TalentVideo = (props) => {
  const { profileId, roleId, tokenTalentProfile } = props;
  const [data, setData] = useState(INIT_DATA);
  const [loading, setLoading] = useState(false);
  const [openPopupAdd, setOpenPopupAdd] = useState({
    open: false,
    isViewMore: false,
    isAdded: false,
  });
  const [openPopupView, setOpenPopupView] = useState(false);
  const [refreshAfterDelete, setRefreshAfterDelete] = useState(false);

  const handleClickAdd = () => {
    setOpenPopupAdd({ open: true, isViewMore: false, isAdded: false });
  };

  const handleSetOpenPopupAdd = (state) => {
    setOpenPopupAdd((prev) => ({ ...prev, open: state }));
  };

  const handleClickViewMore = () => {
    setOpenPopupView(true);
  };

  const getVideos = useCallback(
    async (limit, page, setDataState, setLoadingState) => {
      try {
        setLoadingState(true);
        const res = await talentProfileApi.getTalentVideos(
          profileId,
          roleId,
          { limit, page },
          tokenTalentProfile
        );
        if (res.status === 200) {
          setDataState(res.data.result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingState(false);
      }
    },
    [profileId, roleId, tokenTalentProfile]
  );

  useEffect(() => {
    getVideos(3, 1, setData, setLoading);
  }, [getVideos]);

  useEffect(() => {
    if (openPopupAdd.isAdded || refreshAfterDelete) getVideos(3, 1, setData, setLoading);
    setRefreshAfterDelete(false);
    setOpenPopupAdd((prev) => ({ ...prev, isAdded: false }));
  }, [getVideos, openPopupAdd.isAdded, refreshAfterDelete]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Talent Video</div>
      <Spin spinning={loading}>
        {data.total === 0 ? (
          <div className={styles.content}>
            <button className={styles.add} onClick={handleClickAdd}>
              <img src={plus_green_icon} alt="add-icon" />
              <span>Add video link</span>
            </button>
            <div className={styles.text}>There is no talent video in this section.</div>
            <div className={styles.text}>Please add your talent video now.</div>
          </div>
        ) : (
          <>
            {data.videos.map((video) => (
              <TalentVideoItem key={video.id} data={video} />
            ))}
            <div className={styles.viewMore}>
              <button onClick={handleClickViewMore}>
                VIEW MORE VIDEO <img src={pets_chevron_right} alt="chevron_right" />
              </button>
            </div>
          </>
        )}
      </Spin>
      <CustomAntModal
        hideDefaultBtn
        styles={MODAL_STYLE}
        open={openPopupAdd.open}
        setOpen={handleSetOpenPopupAdd}
        title="Add Video Link"
        width={834}
      >
        <AddTalentVideo
          setOpenPopupAdd={setOpenPopupAdd}
          profileId={profileId}
          roleId={roleId}
          isViewMore={openPopupAdd.isViewMore}
        />
      </CustomAntModal>
      <CustomAntModal
        hideDefaultBtn
        styles={MODAL_STYLE}
        closeType={1}
        open={openPopupView}
        setOpen={setOpenPopupView}
        title="Talent Video"
        width={1007}
      >
        <ViewMoreVideoModal
          setRefreshAfterDelete={setRefreshAfterDelete}
          roleId={roleId}
          openPopupAdd={openPopupAdd}
          setOpenPopupAdd={setOpenPopupAdd}
          getVideos={getVideos}
          profileId={profileId}
        />
      </CustomAntModal>
    </div>
  );
};

TalentVideo.propTypes = {
  profileId: PropTypes.string,
  roleId: PropTypes.number,
};

export default TalentVideo;
