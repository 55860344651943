import React, { useEffect, useState } from 'react';

import { fetchOtherExperiences } from '../../../../services/talentProfiles';
import styles from './other-experience-print.module.scss';

const OtherExperiencePrint = (props) => {
  const { profileId, roleUser, isPrintPdf, tokenTalentProfile } = props;
  const {
    roleActive: { roleId },
    permissions,
  } = roleUser;
  const [otherExperience, setOtherExperience] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = {
      page: 1,
      limit: isPrintPdf && 1000,
      isCheck: true,
      is_print_pdf: isPrintPdf ? true : false,
    };
    if (roleId && profileId) {
      const fetchApi = async () => {
        setLoading(true);

        const response = await fetchOtherExperiences(
          params,
          roleId,
          permissions,
          profileId,
          tokenTalentProfile
        );
        if (response.status === 200) {
          setOtherExperience(response?.data?.result?.experiences);
        }
        setLoading(false);
      };
      fetchApi();
    }
  }, [profileId, isPrintPdf, setOtherExperience, setLoading, roleId, permissions]);

  return (
    <div className={styles.OtherExperience} data-testid="other-experiences">
      <div className={styles.OtherExperience__header}>
        <h3 className={styles.OtherExperience__h3}>Other Experience</h3>
      </div>
      <div className={styles.OtherExperience__table}>
        <table>
          <thead>
            <tr>
              <th>
                <p>Experience</p>
              </th>
              <th>
                <p>Remarks</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td align="center" colSpan="2">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              (otherExperience && otherExperience.length > 0 ? (
                otherExperience.map((item, idx) => {
                  return (
                    <tr key={item.id || idx}>
                      <td>
                        <p>{item.experience || '-'}</p>
                      </td>
                      <td>
                        <p>{item.remarks || '-'}</p>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr key="0">
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OtherExperiencePrint;
