import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Pagination } from '..';
import { fetchOtherContribution } from '../../services/talentProfiles';
import styles from './other-contribution.module.scss';
import ProjectInvolvement from '../ProjectInvolvement/ProjectInvolvement';

const OtherContribution = (props) => {
  const { profileId, isPrintPdf, tokenTalentProfile } = props;
  const user = useSelector((state) => state.user);
  const [otherContribution, setOtherContribution] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setCurrentItems] = useState(1);
  const limit = 4;
  const [totalResults, setTotalResults] = useState(1);

  useEffect(() => {
    async function fetchApi(params) {
      try {
        setLoading(true);
        const response = await fetchOtherContribution({
          params,
          roleUser: user,
          profileId,
          tokenTalentProfile,
        });
        if (
          !isEmpty(response) &&
          response?.data &&
          response.data.result?.data &&
          response?.data?.result?.data?.length > 0
        ) {
          setOtherContribution(response.data.result.data);
          setTotalResults(response?.data?.result?.total);
        }
      } catch (error) {
        setOtherContribution([]);
        setTotalResults(0);
      } finally {
        setLoading(false);
      }
    }
    if (user.roleActive.roleId) {
      if (!isPrintPdf) {
        fetchApi({ page, limit });
      } else {
        fetchApi({ page: 1, limit, isPrintPdf });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive.roleId, profileId, page, isPrintPdf]);

  return (
    <div className={styles.OtherContribution} data-testid="other-contribution">
      <h3 className={styles.OtherContribution__h3}>Other Contribution / Involvement </h3>
      <Spin spinning={loading}>
        <div className={styles.OtherContribution__table}>
          <table>
            <thead>
              <tr>
                <th>
                  <p>Description</p>
                </th>
                <th className="text-center">
                  <p>Roles</p>
                </th>
                <th className="text-center">
                  <p>Level</p>
                </th>
                <th className="text-center">
                  <p>Years</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {!!otherContribution && otherContribution.length > 0 && (
                <>
                  {otherContribution?.map((data, idx) => (
                    <tr key={idx} data-testid="data-contribution">
                      <td>
                        {data.description === 'NULL' || data.description === ''
                          ? '-'
                          : data.description}
                      </td>
                      <td className="text-center">
                        {data.role === 'NULL' || data.role === '' ? '-' : data.role}
                      </td>
                      <td className="text-center">
                        {data.contribution === 'NULL' || data.contribution === ''
                          ? '-'
                          : data.contribution}
                      </td>
                      <td className="text-center">
                        {data.year_contribution === 'NULL' || data.year_contribution === ''
                          ? '-'
                          : data.year_contribution}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      {otherContribution && otherContribution.length > 0 && !isPrintPdf && (
        <Pagination
          page={page}
          rowPerPages={4}
          totalResults={totalResults}
          setPage={setCurrentItems}
          data-testid="pagination"
        />
      )}
      <div className="mt-4">
        <ProjectInvolvement
          staffId={profileId}
          role={user.roleActive}
          tokenTalentProfile={tokenTalentProfile}
        />
      </div>
    </div>
  );
};
export default OtherContribution;
