import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 14px;
  font-weight: 400;
  color: #3f3c4c;

  .date {
    font-weight: 700;
    font-size: 18px;
  }
  .day-of-the-week {
    font-weight: 700;
    font-size: 12px;
  }

  .meeting-name {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-decoration: underline;
    margin-top: 10px;
    color: #3f3c4c;
    cursor: pointer;
    word-break: break-word;
  }

  .meeting-time {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    margin-top: 10px;
    color: #3f3c4c;
    cursor: pointer;
  }

  .add-more {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    margin-top: 10px;
    color: #3f3c4c;
    text-decoration: underline;
    cursor: pointer;
  }

  .container-popup {
    position: relative;
  }

  .meeting-path {
    transform: translate(-36px, -50%);
    position: absolute;
    top: -200px;
  }
`;
