import { generateFilterUrl } from '../utils/helper';
import axiosClient from './axiosClient';
import queryString from 'query-string';

export const talentReviewApi = {
  createTalentReviewAgenda: ({ body, roleUser }) => {
    const { roleActive } = roleUser;
    const url = `/talent-review/agenda`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },
  getDevelopmentPlan: ({ page = 1, limit = 20 }) => {
    const url = `/agenda-talent-review/development-plan`;
    return axiosClient.get(url, { page, limit });
  },

  createDevelopmentPlan: ({ category, details, agendaId }) => {
    const url = `/agenda-talent-review/development-plan`;
    return axiosClient.post(url, { category, details, agendaId });
  },

  getTalentReviewAgenda: (agendaId, roleId, version) => {
    const url = `/talent-review/get-talent-review-agenda?agenda_id=${agendaId}&version=${
      version || 0
    }`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getMobilityPositions: (query) => {
    const stringified = queryString.stringify(query);
    const url = `/talent-review/get-position-in-mobility?${stringified}`;
    return axiosClient.get(url);
  },

  removeTalentReviewFromTalentAgenda: (agendaId, staffId, roleId) => {
    const url = `/talent-review/remove-talent/${staffId}/${agendaId}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getRecommendationTalentReview: (staff_id, searchParams, agendaId) => {
    const url = `/agenda-talent-review/get-recommended-positions`;
    const params = generateFilterUrl({ staff_id, agenda_id: agendaId, ...searchParams });
    return axiosClient.get(`${url}${params}`);
  },

  getTalentReviewMobilityPlan: (staffId) => {
    const url = `/agenda-talent-review/mobility-plan/${staffId}`;
    return axiosClient.get(url);
  },

  deleteDevelopmentPlan: ({ id }) => {
    const url = `/agenda-talent-review/development-plan/${id}`;
    return axiosClient.delete(url);
  },

  editDevelopmentPlan: ({ id, category, details, agendaId }) => {
    const url = `/agenda-talent-review/development-plan/${id}`;
    return axiosClient.put(url, { category, details, agendaId });
  },

  getRecommendationDetailFromTalentReview: (staffId, positionCodes) => {
    const url = `/agendas/get-detail-talent-review/${staffId}?positionCodes=${positionCodes}`;
    return axiosClient.get(url);
  },

  getRecommendationFromTalentReview: (
    isSearchByClick,
    position_codes,
    isSave,
    agenda_id,
    birth_name,
    from_date,
    to_date
  ) => {
    const url = `/agendas/talents-review-recommendation?is_search_by_click=${isSearchByClick}&position_codes=${position_codes}&is_save=${isSave}&agenda_id=${agenda_id}&birth_name=${birth_name}&from_date=${from_date}&to_date=${to_date}`;
    return axiosClient.get(url);
  },

  getSuccessionPlanForTalentReview: (staffId) => {
    const url = `/agenda-talent-review/sucession-plan/${staffId}`;
    return axiosClient.get(url);
  },

  getRecommendationFilter: () => {
    const url = '/agenda-talent-review/filter-recommendation/';
    return axiosClient.get(url);
  },

  getFinalizedRecommendations: (staff_id, agenda_id) => {
    const url = '/agenda-talent-review/get-recommended-finalise';
    const params = generateFilterUrl({ staff_id, agenda_id });
    return axiosClient.get(`${url}${params}`);
  },

  finalizeRecommendations: (body) => {
    const url = '/agenda-talent-review/create-recommended-finalise';
    return axiosClient.post(url, body);
  },

  removeRecommendationsFinalized: (ids) => {
    const url = '/agenda-talent-review/delete-recommended-finalise';
    return axiosClient.delete(url, {
      params: {
        ids: ids.join(','),
      },
    });
  },

  getEndStateRecommendation: (staffId) => {
    const url = `/agenda-talent-review/mobility-plan/end-state-recommendation/${staffId}`;
    return axiosClient.get(url);
  },

  getNextMobilityRecommendation: (params) => {
    const url = `/agenda-talent-review/mobility-plan/next-mobility-recommendation`;
    return axiosClient.get(url, {
      params: {
        staff_id: params?.staff_id,
        position_code: params?.position_code,
        category: params?.category,
      },
    });
  },

  getCriteriaFulfilment: (params) => {
    const url = `/agenda-talent-review/mobility-plan/criteria-fullilment`;
    return axiosClient.get(url, {
      params: {
        staff_id: params?.staff_id,
        position_code: params?.position_code,
        next_mobility_codes: params?.next_mobility_codes,
        next_2_mobility_codes: params?.next_2_mobility_codes,
      },
    });
  },
};
