import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { history } from '../utils/history';

export const useShowPopupAspiration = (fields) => {
  const [isShowPopupSaveAgenda, setIsShowPopupSaveAgenda] = useState(false);
  const [navigatingPathname, setNavigatingPathname] = useState('');

  const handleKeepNavigate = (pathName) => {
    if (pathName) {
      history.push(pathName);
    } else {
      history.push(navigatingPathname);
    }
  };

  useEffect(() => {
    if (isEmpty(fields)) return;
    if (isShowPopupSaveAgenda === false && navigatingPathname) {
      setNavigatingPathname('');
    }
    const unblock = history.block((location) => {
      if (isShowPopupSaveAgenda) {
        return true;
      }
      setNavigatingPathname(location.pathname);
      setIsShowPopupSaveAgenda(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [history, fields, isShowPopupSaveAgenda]);

  return {
    isShowPopupSaveAgenda,
    handleKeepNavigate,
    setIsShowPopupSaveAgenda,
    navigatingPathname,
  };
};
