const { createSlice } = require('@reduxjs/toolkit');

const initialValue = {
  statusLeftMenu: true,
};

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    statusLeftMenu: initialValue.statusLeftMenu,
  },
  reducers: {
    saveStatusLeftMenu: (state, action) => {
      state.statusLeftMenu = action.payload;
    },
    updateIsError: (state, action) => {
      state.isError = action.payload;
    },
    setTokenTalentProfile: (state, action) => {
      state.tokenTalentProfile = action.payload;
    },
  },
});

const { reducer, actions } = pageSlice;
export const { saveStatusLeftMenu, updateIsError, setTokenTalentProfile } = actions;
export default reducer;
