import { Spin, Tooltip } from 'antd';
import { isEmpty, isNaN, orderBy } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AiButton, BasicButton } from '../../../assets/common';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import useConditionalTalentReviewRemark from '../../../hooks/useConditionalTalentReviewRemark';
import { useGetRoleInMeeting } from '../../../hooks/useGetRoleInMeeting';
import { getStaffAgendaTalentReview } from '../../../services/talentProfiles';
import { talentReviewApi } from '../../../services/talentReviewApi';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage, resetGlobalMessage } from '../../../store/alertMessageSlice';
import {
  AGENDA_FIELD_CHANGES,
  AGENDA_STATUS,
  AGENDA_TYPES,
  DATE_TIME_FORMAT,
  MEETING_DETAIL_STATUS,
  MESSAGE_TYPES,
  ROUTE_BACK_HISTORY,
} from '../../../utils/constants';
import {
  convertDataToSetAgendaAsMain,
  convertDataToUpdateTalentReviewAgenda,
  convertDataToViewTalentReviewAgenda,
  getDescForWithoutModalTalentReview,
  getDevelopmentPlanningData,
} from '../../../utils/helper';
import PopupListConcludedAgenda from '../../Meeting/AgendaDetails/components/PopupListConcludedAgenda/PopupListConcludedAgenda';
import {
  clearTemporaryTalentReviewAgenda,
  removeActionType,
  talentReviewAgendaSave,
} from '../../Meeting/redux/agendaSlice';
import { clearRemarks, getRemarks, saveRemarks } from '../../Meeting/redux/remarksSlice';
import AddTalentDetail from '../components/AddTalentDetail/AddTalentDetail';
import TalentReviewAgendaForm from '../components/TalentReviewAgendaForm/TalentReviewAgendaForm';
import TalentReviewAgendaPrintPreview from '../components/TalentReviewAgendaPrintPreview/TalentReviewAgendaPrintPreview';
import RemarkTableDialog from '../components/TalentReviewAndInfoSP/Remarks/ViewRemarks/RemarkTableDialog';
import TalentSearchForm from '../components/TalentSearchForm/TalentSearchForm';
import {
  SP_DASHBOARD_TALENT_REVIEW_BREADCRUMB,
  TALENT_REVIEW_AGENDA_BREADCRUMB,
  TALENT_REVIEW_MESSAGE,
} from '../constants';
import * as styles from './add-edit-talent-review-agenda.module.scss';
import { profileSelector } from '../../../pages/TalentProfilePage/selector';
import ReactToPrint from 'react-to-print';
import { remarksRemainingSelector } from '../../Meeting/selector';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';

const initTalentDetail = {
  top_talent: {
    top_talent_status: '',
    line: '',
    remarks: '',
    is_updated: false,
  },
  succession_planning: {
    first_line: [],
    second_line: [],
    third_line: [],
    agreed_to_drop: [],
  },
  mobility_plan: [
    {
      next_mobility: { manual: '', selection: [] },
      next_2_mobility: { manual: '', selection: [] },
      end_state: { manual: '', selection: [] },
      idx: uuidv4(),
    },
  ],
  development_plan: [],
  end_state_recommendation: undefined,
};

const initPrevAgendaDetail = {
  date: '',
  title: '',
  start_time: undefined,
  end_time: undefined,
  advocators: [],
  hr_partners: [],
  version: null,
  type_agenda: undefined,
  user_id: '',
  set_main_at: '',
  version_name: '',
  approved_status: '',
  business: '',
  status: '',
  action_type: '',
  meeting_id: null,
  meeting_status: '',
  meeting_name: '',
  agenda_id: null,
  ...initTalentDetail,
};
const HIDDEN_FIELD = ['option_action', 'is_has_staff'];

const AddEditTalentReviewAgenda = () => {
  const history = useHistory();
  const printRef = useRef(null);
  const { agendaId, idMeeting } = useParams();
  const { search } = useLocation();
  const objSearch = queryString.parse(search);
  const dispatch = useDispatch();
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });
  const { data: meetingDetail } = useGetMeeting({ idMeeting });
  const { secondSectionProfile } = useSelector(profileSelector);
  const roleActive = useSelector((state) => state.user.roleActive);
  const { createdAgenda, temporaryIdTalentReviewAgenda } = useSelector(
    (state) => state.app.meetingModule.agenda
  );
  const user = useSelector((state) => state.user);
  const [agendaDetail, setAgendaDetail] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const pageHistory = new URLSearchParams(window.location.search).get('history');
  const [talentSearchDetail, setTalentSearchDetail] = useState({});
  const [addTalent, setAddTalent] = useState(initTalentDetail);
  const [isShowTalentDetail, setIsShowTalentDetail] = useState(false);
  const [staffTalentReview, setStaffTalentReview] = useState({});
  const [isPrinting, setIsPrinting] = useState(false);
  //Modal state
  const [showChangeToPaperReady, setShowChangeToPaperReady] = useState(false);
  const [showModalEditPaper, setShowModalEditPaper] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);
  const [showUnFinalize, setShowUnFinalize] = useState(false);
  const [showAllRemarks, setShowAllRemarks] = useState(false);
  const [isShowTalentSearchModal, setIsShowTalentSearchModal] = useState(false);
  const [isShowRemoveTalentModal, setIsShowRemoveTalentModal] = useState(false);
  const [agendaAsMain, setAgendaAsMain] = useState(null);
  const [remarksOfConcludedAgenda, setRemarksOfConcludedAgenda] = useState(null);
  const [prevAgendaDetail, setPrevAgendaDetail] = useState(initPrevAgendaDetail);
  const [formMobilityPlan, setFormMobilityPlan] = useState([]);
  // Remark Selector
  const remarks = useSelector(remarksRemainingSelector);

  const validationSchema = yup.object(
    {
      title: yup.string().required('Talent Review Name is required'),
      start_time: yup
        .string()
        .required('Start Time is required.')
        .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
          const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
          const endTimeMoment = moment(formik.values.end_time, DATE_TIME_FORMAT.TIME);
          if (isEmpty(formik.values.end_time) || formik.values.end_time === value) return true;
          return endTimeMoment.isAfter(startTimeMoment);
        }),
      end_time: yup.string().required('End Time is required.'),
      advocators: yup.array().min(1, 'Advocators is required'),
      option_action: yup.array(),
      is_has_staff: yup.boolean(),
      action_type: yup.string().when(['option_action', 'is_has_staff'], {
        is: (option_action, is_has_staff) => isEmpty(option_action) || is_has_staff === false,
        then: yup.string().nullable(),
        otherwise: yup.string().required('Action type is required'),
      }),
    },
    ['option_action', 'is_has_staff']
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      title: agendaDetail?.title || '',
      start_time: agendaDetail?.start_time,
      end_time: agendaDetail?.end_time,
      advocators: agendaDetail?.advocators || [],
      hr_partners: agendaDetail?.hr_partners || [],
      action_type: agendaDetail?.action_type || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useEffect(() => {
    formik.setValues({
      title: agendaDetail?.title || '',
      start_time: agendaDetail?.start_time,
      end_time: agendaDetail?.end_time,
      advocators: agendaDetail?.advocators || [],
      hr_partners: agendaDetail?.hr_partners || [],
      action_type: agendaDetail?.action_type || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail]);

  const onlyViewContent = useMemo(() => {
    return objSearch?.type === 'view';
  }, [objSearch]);

  const onlyViewTalent = useMemo(() => {
    return pageHistory === ROUTE_BACK_HISTORY.SP_DASHBOARD;
  }, [pageHistory]);

  const { isCreateOrEditRemarks } = useConditionalTalentReviewRemark({
    meetingRole,
    meetingStatus: agendaDetail?.meeting_status,
    agendaStatus: agendaDetail?.status,
    staffTalentReview,
  });
  const { createList, editList, deleteList, prevRemarks } = useSelector(
    (state) => state.app.meetingModule.remarks
  );

  const isShowPaperReady = useMemo(
    () =>
      !isNaN(Number(agendaId)) &&
      agendaDetail?.status === AGENDA_STATUS.PAPER_IN_PROGRESS &&
      meetingRole?.is_secretariat_or_facilitator,
    [agendaId, agendaDetail, meetingRole]
  );
  const isShowEditPaper = useMemo(
    () =>
      !isNaN(Number(agendaId)) &&
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) &&
      agendaDetail?.status === AGENDA_STATUS.PAPER_READY &&
      meetingRole?.is_secretariat_or_facilitator,
    [agendaId, agendaDetail, meetingRole]
  );

  const isShowFinalizeAndUnFinalize = useMemo(
    () =>
      agendaDetail?.meeting_status === MEETING_DETAIL_STATUS.IN_PROGRESS &&
      meetingRole?.is_secretariat_or_facilitator &&
      [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status),
    [agendaDetail, meetingRole]
  );

  const isShownSaveAsDraftBtn = useMemo(() => {
    return (
      ((([MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) &&
        ![AGENDA_STATUS.PAPER_READY].includes(agendaDetail?.status)) ||
        ([MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.IN_PROGRESS].includes(
          agendaDetail?.meeting_status
        ) &&
          ![AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status))) &&
        ![AGENDA_STATUS.PAPER_BRING].includes(agendaDetail?.status) &&
        meetingRole?.is_secretariat_or_facilitator) ||
      isNaN(Number(agendaId))
    );
  }, [agendaDetail, meetingRole, agendaId]);

  const isShownSaveBtn = useMemo(() => {
    return (
      [MEETING_DETAIL_STATUS.IN_REVIEW].includes(agendaDetail?.meeting_status) &&
      [AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status) &&
      meetingRole?.is_secretariat_or_facilitator &&
      !isNaN(Number(agendaId))
    );
  }, [agendaDetail, meetingRole, agendaId]);

  // As Meeting Secretariat/Meeting Facilitator
  const isShownByMeetingAgendaStatus = useMemo(() => {
    return (
      (meetingRole?.is_secretariat_or_facilitator &&
        ![MEETING_DETAIL_STATUS.CONCLUDED].includes(agendaDetail?.meeting_status) &&
        (([MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
          agendaDetail?.meeting_status
        ) &&
          ![AGENDA_STATUS.PAPER_READY].includes(agendaDetail?.status)) ||
          ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(agendaDetail?.meeting_status) &&
            ![AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status)) ||
          ([MEETING_DETAIL_STATUS.IN_REVIEW].includes(agendaDetail?.meeting_status) &&
            [AGENDA_STATUS.PAPER_FINALIZED].includes(agendaDetail?.status))) &&
        ![AGENDA_STATUS.PAPER_BRING].includes(agendaDetail?.status)) ||
      isNaN(Number(agendaId))
    );
  }, [agendaDetail, meetingRole, agendaId]);

  // As Chairman/ Committee Members/ Talent Review's Advocators and HR Partners
  const isShowBtnTalentReview = useMemo(() => {
    return (
      (!isNaN(Number(agendaId)) &&
        ((![MEETING_DETAIL_STATUS.IN_PLANNING].includes(agendaDetail?.meeting_status) &&
          !meetingRole?.is_secretariat_or_facilitator &&
          (meetingRole.is_advocator ||
            meetingRole.is_commitee ||
            meetingRole.is_chairman ||
            meetingRole.is_hr_partners) &&
          [
            MEETING_DETAIL_STATUS.PUBLISHED,
            MEETING_DETAIL_STATUS.IN_PROGRESS,
            MEETING_DETAIL_STATUS.IN_REVIEW,
            MEETING_DETAIL_STATUS.CONCLUDED,
          ].includes(agendaDetail?.meeting_status)) ||
          (meetingRole?.is_secretariat_or_facilitator &&
            ![MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
              agendaDetail?.meeting_status
            )))) ||
      [AGENDA_STATUS.PAPER_BRING].includes(agendaDetail?.status)
    );
  }, [agendaDetail, meetingRole, agendaId]);

  const handleShowMessage = useCallback(
    async (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
        })
      );
    },
    [dispatch]
  );

  const fetchTalentReviewAgenda = useCallback(
    async (agenda_id, isAsMainAgenda) => {
      try {
        setLoadingPage(true);
        setLoadingButton(true);
        dispatch(clearRemarks());
        const response = await talentReviewApi.getTalentReviewAgenda(agenda_id, roleActive?.roleId);
        if (response.status === 200) {
          let revertData = {};
          if (!isAsMainAgenda) {
            revertData = convertDataToViewTalentReviewAgenda(response.data.result);
            setAgendaDetail(revertData);
            setPrevAgendaDetail(revertData);
            dispatch(
              getRemarks({
                role: roleActive,
                agenda_id: agendaId,
                version: agendaDetail?.version || 0,
              })
            );
          } else {
            revertData = convertDataToSetAgendaAsMain(response.data.result);
            setAgendaDetail((prev) => {
              return {
                ...prev,
                ...revertData,
              };
            });
          }
          setLoadingButton(false);

          if (!isEmpty(revertData?.top_talent)) {
            setIsShowTalentDetail(true);
          } else {
            setIsShowTalentDetail(false);
          }
        }
      } catch (error) {
        setLoadingButton(false);
        return console.error(error);
      } finally {
        setLoadingPage(false);
      }
    },
    [roleActive, dispatch]
  );

  useEffect(() => {
    if (!agendaId) return;

    if (!isNaN(Number(agendaId))) {
      fetchTalentReviewAgenda(agendaId);
    }
  }, [agendaId, createdAgenda, fetchTalentReviewAgenda]);

  useEffect(() => {
    if (!agendaId || !isNaN(Number(agendaId))) return;
    const tempTalent = createdAgenda.find((item) => item.agenda_id === agendaId);
    const talentInRedux = convertDataToViewTalentReviewAgenda(tempTalent);
    setAgendaDetail(talentInRedux);
    setPrevAgendaDetail(talentInRedux);
    setIsShowTalentDetail(talentInRedux?.top_talent?.staff_id);
  }, []);

  const handleSaveAsDraft = async () => {
    const formValue = await formik.submitForm();
    if (!formValue) {
      await handleShowMessage('error', TALENT_REVIEW_MESSAGE.IN_VALID_FORM);
      return false;
    }
    setAddTalent((prev) => ({
      ...prev,
      top_talent: {
        ...prev.top_talent,
        old_top_talent_status: undefined,
      },
    }));
    if (!agendaId) {
      // Handle add new agenda when clicking save as draft button more than 2 times.
      dispatch(
        talentReviewAgendaSave({
          ...formValue,
          ...addTalent,
          agenda_id: temporaryIdTalentReviewAgenda || '',
          staff_id: addTalent?.top_talent?.staffId || addTalent?.top_talent?.staff_id,
          agenda_as_main: agendaAsMain?.agenda_id,
          agenda_as_main_type: agendaAsMain?.action_type,
          agenda_as_main_platform: agendaAsMain?.meeting_name,
          talent_review_date: agendaDetail?.talent_review_date,
        })
      );
      setPrevAgendaDetail((prev) => ({
        ...prev,
        ...formValue,
        ...addTalent,
        agenda_id: temporaryIdTalentReviewAgenda || '',
        staff_id: addTalent?.top_talent?.staffId || addTalent?.top_talent?.staff_id,
        agenda_as_main: agendaAsMain?.agenda_id,
        agenda_as_main_type: agendaAsMain?.action_type,
        agenda_as_main_platform: agendaAsMain?.meeting_name,
        talent_review_date: agendaDetail?.talent_review_date,
      }));
      await handleShowMessage('success', TALENT_REVIEW_MESSAGE.AGENDA_SAVE_SUCCESS);
      return true;
    } else if (isNaN(Number(agendaId))) {
      // Handle update in redux
      const savedData = {
        ...formValue,
        ...addTalent,
        agenda_id: agendaId,
        staff_id: addTalent?.top_talent?.staffId || addTalent?.top_talent?.staff_id,
        agenda_as_main: agendaAsMain?.agenda_id,
        agenda_as_main_type: agendaAsMain?.action_type,
        agenda_as_main_platform: agendaAsMain?.meeting_name,
        talent_review_date: agendaDetail?.talent_review_date,
      };
      dispatch(talentReviewAgendaSave(savedData));
      setAgendaDetail(savedData);
      setPrevAgendaDetail(savedData);
      await handleShowMessage('success', TALENT_REVIEW_MESSAGE.AGENDA_SAVE_SUCCESS);
      return true;
    } else {
      // Handle update agenda
      return await fetchUpdateTalentReviewAgenda();
    }
  };

  // Set talent data
  useEffect(() => {
    if (!isEmpty(talentSearchDetail)) {
      setAddTalent((prevState) => {
        if (!isEmpty(prevState)) {
          return {
            ...prevState,
            top_talent: {
              ...talentSearchDetail?.talent,
              ...prevState?.top_talent,
              staff_id: talentSearchDetail?.talent?.staff_id || talentSearchDetail?.talent?.staffId,
            },
          };
        } else {
          return {
            ...initTalentDetail,
            top_talent: {
              ...talentSearchDetail?.talent,
              top_talent_status: '',
              remarks: '',
              staff_id: talentSearchDetail?.talent?.staff_id || talentSearchDetail?.talent?.staffId,
            },
          };
        }
      });
      setIsShowTalentDetail(true);
      handleShowMessage('success', TALENT_REVIEW_MESSAGE.SAVE_TALENT_SUCCESS);
    }

    return () => {
      setAddTalent(initTalentDetail);
    };
  }, [handleShowMessage, talentSearchDetail]);

  const fetchUpdateTalentReviewAgenda = async () => {
    setLoadingButton(true);
    const formValue = await formik.submitForm();
    const newFormData = !isEmpty(formValue) ? { ...agendaDetail, ...formValue } : agendaDetail;
    const body = convertDataToUpdateTalentReviewAgenda({
      ...newFormData,
    });
    const developmentPlanningData = getDevelopmentPlanningData(
      !isEmpty(addTalent?.succession_planning)
        ? addTalent?.succession_planning
        : body?.succession_planning
    );
    const dataSource = {
      mobility_plan: !isEmpty(addTalent?.mobility_plan)
        ? addTalent.mobility_plan
        : body?.mobility_plan,
      development_plan: !isEmpty(addTalent?.development_plan)
        ? addTalent?.development_plan
        : body?.development_plan,
    };
    try {
      const response = await talentReviewApi.createTalentReviewAgenda({
        body: [
          {
            ...body,
            top_talent: !isEmpty(addTalent?.top_talent)
              ? {
                  ...addTalent?.top_talent,
                  top_talent_status_previous:
                    addTalent?.top_talent?.top_talent_status_previous ||
                    addTalent?.top_talent?.old_top_talent_status,
                  image: '',
                }
              : {
                  ...body?.top_talent,
                  top_talent_status_previous:
                    body?.top_talent?.top_talent_status_previous ||
                    body?.top_talent?.old_top_talent_status,
                  image: '',
                },
            succession_planning: developmentPlanningData,
            mobility_plan: dataSource.mobility_plan.map((item) => ({
              next_mobility: {
                ...item?.next_mobility,
                selection:
                  item?.next_mobility?.selection?.map((p, idx) => ({
                    position_code: p.position_code,
                    priority: p.priority,
                    sort_order: idx,
                  })) || [],
              },
              next_2_mobility: {
                ...item?.next_2_mobility,
                selection:
                  item?.next_2_mobility?.selection?.map((p, idx) => ({
                    position_code: p.position_code,
                    priority: p.priority,
                    sort_order: idx,
                  })) || [],
              },
              end_state: {
                ...item?.end_state,
                selection:
                  item?.end_state?.selection?.map((p, idx) => ({
                    position_code: p.position_code,
                    priority: p.priority,
                    sort_order: idx,
                  })) || [],
              },
            })),
            development_plan: dataSource.development_plan,
            staff_id:
              addTalent?.top_talent?.staffId || body?.top_talent?.staff_id || body?.staff_id,
            agenda_as_main: agendaAsMain?.agenda_id || agendaDetail?.agenda_as_main,
            agenda_as_main_type: agendaAsMain?.action_type || agendaDetail?.agenda_as_main_type,
            agenda_as_main_platform:
              agendaAsMain?.meeting_name || agendaDetail?.agenda_as_main_platform,
          },
        ],
        roleUser: user,
      });

      if (!isEmpty(createList)) {
        const headers = { role: roleActive };
        const remarkList = createList.map((item) => ({
          staff_id: item?.staffId,
          remark: item?.remarks,
          category: item?.remarksCategory,
          version: item?.version,
          remark_type: item?.remarkType,
        }));
        await meetingApi.addRemarks(
          headers,
          createList[0].agendaId,
          createList[0].staffId,
          remarkList
        );
      }
      if (!isEmpty(editList)) {
        const apiList = editList.map((item) => {
          return meetingApi.updateRemarks({
            role: roleActive,
            remark_id: item.remarkId,
            body: item.body,
          });
        });
        await Promise.allSettled(apiList);
      }
      if (!isEmpty(deleteList)) {
        const apiList = deleteList.map((item) => {
          return meetingApi.deleteRemarks({
            role: roleActive,
            agenda_id: agendaId,
            remark_id: item.remarkId,
          });
        });
        await Promise.allSettled(apiList);
      }
      dispatch(clearRemarks());
      dispatch(
        getRemarks({
          role: roleActive,
          agenda_id: agendaId,
          version: agendaDetail?.version || 0,
        })
      );
      if (response.status === 200) {
        await fetchTalentReviewAgenda(agendaId);
        await handleShowMessage('success', TALENT_REVIEW_MESSAGE.AGENDA_UPDATE_SUCCESS);
        return true;
      }
    } catch (error) {
      return console.error(error);
    } finally {
      setLoadingButton(false);
      setRemarksOfConcludedAgenda(null);
    }
  };

  const handleBack = () => {
    if (isEmpty(fieldChanges)) {
      dispatch(resetGlobalMessage());
      dispatch(clearTemporaryTalentReviewAgenda());
      dispatch(clearRemarks());
    }
    switch (pageHistory) {
      case ROUTE_BACK_HISTORY.MEETING_DETAIL:
        history.push(`/meeting/detail/${idMeeting}`);
        break;
      case ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2:
        history.push(`/meeting/${idMeeting}/agendas`);
        break;
      default:
        history.goBack();
        break;
    }
  };

  const handleChangePaperReady = async () => {
    try {
      let response = await meetingApi.changeAgendaStatusById(agendaId);
      if (response.status === 200) {
        await fetchTalentReviewAgenda(agendaId);
        await handleShowMessage('success', TALENT_REVIEW_MESSAGE.PAPER_READY_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      handleShowMessage('error', TALENT_REVIEW_MESSAGE.AN_UNEXPECTED_ERROR);
    }
    setShowChangeToPaperReady(false);
  };

  const handleEditPaper = async () => {
    setShowModalEditPaper(false);
    try {
      const res = await meetingApi.updateStatusAgenda({
        agendaId,
        status: AGENDA_STATUS.PAPER_IN_PROGRESS,
      });
      if (res.status === 200) {
        await fetchTalentReviewAgenda(agendaId);
        await handleShowMessage('success', TALENT_REVIEW_MESSAGE.EDIT_PAPER_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      await handleShowMessage('error', TALENT_REVIEW_MESSAGE.AN_UNEXPECTED_ERROR);
    }
  };

  const handleClickFinalizeOrUnFinalize = () => {
    if (agendaDetail.status !== AGENDA_STATUS.PAPER_READY) {
      setShowUnFinalize(true);
      return;
    }
    setShowFinalize(true);
  };

  const handleFinalize = async () => {
    try {
      let response = await meetingApi.finalizeSPAgenda(agendaId);
      if (response.status === 200) {
        await fetchTalentReviewAgenda(agendaId);
        await handleShowMessage(
          'success',
          `${agendaDetail?.title} has been successfully finalized`
        );
      }
    } catch (error) {
      console.error(error);
    }
    setShowFinalize(false);
  };

  const handleUnFinalize = async () => {
    try {
      let response = await meetingApi.unfinalizeSPAgenda(agendaId);
      if (response.status === 200) {
        await fetchTalentReviewAgenda(agendaId);
        await handleShowMessage(
          'success',
          `${agendaDetail?.title} has been successfully unfinalized`
        );
      }
    } catch (error) {
      console.error(error);
    }
    setShowUnFinalize(false);
  };

  const handleRemoveAllRemarks = async () => {
    if (!isEmpty(remarks)) {
      const apiList = remarks.map((item) => {
        return meetingApi.deleteRemarks({
          role: roleActive,
          agenda_id: agendaId,
          remark_id: item.remarkId,
        });
      });
      await Promise.allSettled(apiList);
      dispatch(clearRemarks());
    }
  };

  const handleRemoveTalent = async () => {
    setIsShowRemoveTalentModal(false);
    setAgendaAsMain(null);
    await handleRemoveAllRemarks();
    if (!isNaN(Number(agendaId))) {
      try {
        setLoadingPage(true);
        const response = await talentReviewApi.removeTalentReviewFromTalentAgenda(
          agendaId,
          addTalent?.top_talent?.staff_id ||
            addTalent?.top_talent?.staffId ||
            agendaDetail?.top_talent?.staff_id,
          roleActive?.roleId
        );
        if (response.status === 200) {
          await handleResetTalentReview(false);
          setPrevAgendaDetail((prev) => ({
            ...prev,
            action_type: '',
            staff_id: '',
            agenda_as_main: '',
            agenda_as_main_type: '',
            agenda_as_main_platform: '',
            top_talent: null,
            ...initTalentDetail,
          }));
        }
      } catch (error) {
        await handleShowMessage('error', TALENT_REVIEW_MESSAGE.AN_UNEXPECTED_ERROR);
      } finally {
        setLoadingPage(false);
      }
    } else {
      await handleResetTalentReview(false);
    }
  };

  const handleResetTalentReview = async (isAsMain) => {
    const tempTalent = createdAgenda.find((item) => item.agenda_id === agendaId);
    setTalentSearchDetail({});
    setStaffTalentReview({});
    setAddTalent(initTalentDetail);
    setAgendaDetail((prev) => ({
      ...prev,
      staff_id: '',
      agenda_as_main: '',
      agenda_as_main_type: '',
      agenda_as_main_platform: '',
      top_talent: null,
      ...formik.values,
      action_type: '',
      ...initTalentDetail,
    }));
    if (temporaryIdTalentReviewAgenda) {
      dispatch(removeActionType(temporaryIdTalentReviewAgenda));
    }
    if (!isEmpty(tempTalent) && !isEmpty(tempTalent?.title)) {
      dispatch(
        talentReviewAgendaSave({
          ...tempTalent,
          action_type: '',
          top_talent: {},
          succession_planning: {},
          mobility_plan: [],
          development_plan: [],
        })
      );
      setPrevAgendaDetail((prev) => ({
        ...prev,
        action_type: '',
        staff_id: '',
        agenda_as_main: '',
        agenda_as_main_type: '',
        agenda_as_main_platform: '',
        top_talent: null,
        ...initTalentDetail,
      }));
    }
    setIsShowTalentDetail(false);
    setIsShowRemoveTalentModal(false);
    if (!isAsMain) {
      await handleShowMessage('success', TALENT_REVIEW_MESSAGE.DELETE_TALENT_SUCCESS);
    }
  };

  const fetchAgendaTalentReviewByStaffId = useCallback(async () => {
    const tempStaffId =
      talentSearchDetail?.talent?.staffId ||
      agendaDetail?.top_talent?.staff_id ||
      agendaDetail?.top_talent?.staffId;

    if (isEmpty(tempStaffId)) return;

    try {
      const response = await getStaffAgendaTalentReview({
        staff_id: tempStaffId,
        agenda_id: !isNaN(Number(agendaDetail?.agenda_id)) ? agendaDetail?.agenda_id : '',
      });
      if (response.status === 200) {
        setStaffTalentReview({
          ...response?.data?.result,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [agendaDetail, talentSearchDetail]);

  useEffect(() => {
    if (!isEmpty(talentSearchDetail) || !isEmpty(agendaDetail)) {
      fetchAgendaTalentReviewByStaffId();
    }
  }, [talentSearchDetail, agendaDetail, fetchAgendaTalentReviewByStaffId]);

  const closeModal = () => {
    setIsPrinting(false);
  };

  const fetchRemarks = async (prams) => {
    try {
      const res = await meetingApi.getRemarks(prams);
      const data = res?.data?.result;
      if (res.status === 200 && !isEmpty(data)) {
        setRemarksOfConcludedAgenda(
          data.map((item) => ({
            birthName: item.birth_name,
            meetingDate: item.meeting_date,
            meetingId: item.meeting_id,
            meetingName: item.meeting_name,
            remarks: item.remarks,
            remarksCategory: item.category,
            staffId: item.staff_id,
            remarkId: item.remark_id,
          }))
        );

        // Re-init remarks
        data.forEach((item) => {
          const params = {
            staffId: item.staff_id,
            agendaId: agendaDetail?.agenda_id || agendaId,
            remarks: item.remarks,
            remarksCategory: item.category,
            version: 0,
            birthName: item.birth_name,
            meetingDate: new Date(),
            remarkId: uuidv4(),
            type: 'create',
            meetingName: agendaDetail?.meeting_name,
          };
          dispatch(saveRemarks(params));
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetAsMain = async (record) => {
    setRemarksOfConcludedAgenda(null);
    await handleResetTalentReview(true);
    await fetchTalentReviewAgenda(record.agenda_id, true);
    await fetchRemarks({
      role: roleActive,
      agenda_id: record.agenda_id,
      version: agendaDetail?.version || 0,
    });
    dispatch(
      pushMessage({
        message: `${record.agenda_name} has been set as main agenda.`,
        type: MESSAGE_TYPES.SUCCESS,
      })
    );
  };

  const isShowTalentType = useMemo(() => {
    return agendaAsMain?.meeting_name || agendaDetail?.agenda_as_main_platform;
  }, [agendaAsMain, agendaDetail]);

  const fieldChanges = useMemo(() => {
    let result = [];
    //change form info
    Object.keys(formik.values).forEach((key) => {
      if (
        JSON.stringify(formik.values[key]) !== JSON.stringify(prevAgendaDetail[key]) &&
        !HIDDEN_FIELD.includes(key)
      ) {
        result.push('Talent Review Session Details - ' + AGENDA_FIELD_CHANGES[key]);
      }
    });

    //Change talent
    if (
      addTalent?.top_talent?.staff_id &&
      addTalent?.top_talent?.staff_id !== prevAgendaDetail.staff_id
    ) {
      result.push(AGENDA_FIELD_CHANGES['talent']);
      return result;
    }

    //Change Top Talent Category
    if (
      addTalent.top_talent.old_top_talent_status &&
      addTalent.top_talent.old_top_talent_status !== addTalent.top_talent.top_talent_status
    ) {
      result.push(AGENDA_FIELD_CHANGES['top_talent_category']);
    }

    //change remarks
    if (JSON.stringify(remarks.map(({ no, ...item }) => item)) !== JSON.stringify(prevRemarks)) {
      let categories = [];
      remarks.forEach((ad) => {
        if (isNaN(Number(ad.remarkId)) && categories.indexOf(ad.remarksCategory) === -1)
          categories.push(ad.remarksCategory);
      });
      //check is exist data edit
      remarks.forEach((ad) => {
        if (!isNaN(Number(ad.remarkId))) {
          const isExist = prevRemarks.find((item) => item.remarkId === ad.remarkId);
          if (
            !isEmpty(isExist) &&
            (ad.remarksCategory !== isExist.remarksCategory || ad.remarks !== isExist.remarks)
          ) {
            if (categories.indexOf(ad.remarksCategory) === -1) categories.push(ad.remarksCategory);
          }
        }
      });
      //check is exist data delete
      prevRemarks.forEach((pd, index) => {
        const isExist = remarks.find((item) => item.remarkId === pd.remarkId);
        if (isEmpty(isExist) && categories.indexOf(pd.remarksCategory) === -1)
          categories.push(pd.remarksCategory);
      });
      categories.forEach((ctg) => {
        result.push('Key Strength and Development Area - ' + ctg);
      });
    }

    // changes SuccessionPlanning
    Object.keys(addTalent.succession_planning).forEach((key) => {
      const field = addTalent.succession_planning[key];
      const prevField = prevAgendaDetail?.succession_planning?.[key];
      if (field?.length !== prevField?.length) {
        result.push('Succession Planning - ' + AGENDA_FIELD_CHANGES[key]);
      } else {
        const prevPositionCode = prevField.map((item) => item.position_code);
        let isChange = false;
        for (let idx = 0; idx < field?.length; idx++) {
          const position = field[idx];
          if (!prevPositionCode.includes(position.position_code)) {
            isChange = true;
            break;
          }
        }
        if (isChange) result.push('Succession Planning - ' + AGENDA_FIELD_CHANGES[key]);
      }
    });

    //Changes Mobility Plan
    if (
      JSON.stringify(addTalent.mobility_plan.map(({ idx, ...item }) => item)) !==
      JSON.stringify(prevAgendaDetail.mobility_plan.map(({ idx, ...item }) => item))
    ) {
      const newArr = [];
      addTalent.mobility_plan.forEach((ad, index) => {
        if (!ad?.plan) {
          newArr.push('Mobility Plan - Plan ' + (index + 1));
        }
      });

      //check is exist data edit
      addTalent.mobility_plan.forEach((ad) => {
        if (ad?.plan) {
          const isExist = prevAgendaDetail.mobility_plan.find((item) => item.plan === ad.plan);
          if (
            !isEmpty(isExist) &&
            (JSON.stringify(ad.end_state) !== JSON.stringify(isExist.end_state) ||
              JSON.stringify(ad.next_2_mobility) !== JSON.stringify(isExist.next_2_mobility) ||
              JSON.stringify(ad.next_mobility) !== JSON.stringify(isExist.next_mobility))
          ) {
            newArr.push('Mobility Plan - Plan ' + ad.plan);
          }
        }
      });

      //check is exist data delete
      prevAgendaDetail.mobility_plan.forEach((pd) => {
        const isExist = addTalent.mobility_plan.find((item) => item.plan === pd.plan);
        if (isEmpty(isExist)) {
          newArr.push('Mobility Plan - Plan ' + pd.plan);
        }
      });
      let mySet = new Set(newArr);
      result = result.concat(Array.from(mySet));
    }

    //Changes Development Plan
    if (
      JSON.stringify(addTalent.development_plan.map(({ no, ...item }) => item)) !==
      JSON.stringify(prevAgendaDetail.development_plan.map(({ no, ...item }) => item))
    ) {
      let isExistCreate = false;
      addTalent.development_plan.forEach((ad) => {
        if (isNaN(Number(ad.id))) isExistCreate = true;
      });
      if (isExistCreate) result.push('Development Plan - Development Plan List');
      //check is exist data edit
      addTalent.development_plan.forEach((ad) => {
        if (!isNaN(Number(ad.id))) {
          const isExist = prevAgendaDetail.development_plan.find((item) => item.id === ad.id);
          if (
            !isEmpty(isExist) &&
            (ad.category !== isExist.category || ad.details !== isExist.details)
          ) {
            result.push('Development Plan List - No.' + ad.no);
          }
        }
      });

      //check is exist data delete
      prevAgendaDetail.development_plan.forEach((pd, index) => {
        const isExist = addTalent.development_plan.find((item) => item.id === pd.id);
        if (isEmpty(isExist)) {
          result.push('Development Plan List - No.' + (index + 1));
        }
      });
    }
    return result;
  }, [prevAgendaDetail, formik.values, addTalent, remarks, prevRemarks]);

  const messageChanges = useMemo(() => {
    return getDescForWithoutModalTalentReview(fieldChanges, agendaId);
  }, [fieldChanges, agendaId]);

  const { isShowPopupSaveAgenda, setIsShowPopupSaveAgenda, handleKeepNavigate } =
    useShowPopupSaveAgenda(!isEmpty(fieldChanges) || !isEmpty(formMobilityPlan));

  useEffect(() => {
    if (!isEmpty(formMobilityPlan) && isShowPopupSaveAgenda) {
      showMessagePreventRedirect();
      setIsShowPopupSaveAgenda(false);
    }
  }, [isShowPopupSaveAgenda]);

  function showMessagePreventRedirect() {
    dispatch(
      pushMessage({
        message:
          "Please complete your editing by clicking 'Save' button in " +
          orderBy(formMobilityPlan)
            .map((f) => 'Mobility Plan ' + f)
            .join(', ') +
          '.',
        type: MESSAGE_TYPES.WARNING,
        timeShow: 3000, //time ms before the message auto close, default is 5000, 0 mean do not auto close
        isScroll: true, //make your page scroll to the message auto, default is false
      })
    );
  }

  const handleNavigate = () => {
    dispatch(resetGlobalMessage());
    dispatch(clearTemporaryTalentReviewAgenda());
    dispatch(clearRemarks());
    handleKeepNavigate();
  };

  return (
    <div className="max-width">
      <BreadCrumb
        level={onlyViewTalent ? 3 : 4}
        breadCrumbList={
          onlyViewTalent ? SP_DASHBOARD_TALENT_REVIEW_BREADCRUMB : TALENT_REVIEW_AGENDA_BREADCRUMB
        }
      />
      <h3 className={styles.title}>Talent Review</h3>

      <GlobalAlertMessage style={{ margin: '25px 0 30px 0' }} />

      {!onlyViewContent && !onlyViewTalent && (
        <div className={styles.controlBar}>
          {isShowBtnTalentReview ? (
            <AiButton disabled={loadingButton} onClick={handleBack}>
              Back
            </AiButton>
          ) : (
            <AiButton disabled={loadingButton} onClick={handleBack}>
              Back to Agenda
            </AiButton>
          )}
          {isShownSaveAsDraftBtn && (
            <AiButton
              disabled={loadingButton}
              onClick={() =>
                !isEmpty(formMobilityPlan) ? showMessagePreventRedirect() : handleSaveAsDraft()
              }
            >
              <span data-testid="save-as-draft-btn">Save as Draft</span>
            </AiButton>
          )}
          {isShownSaveBtn && (
            <AiButton
              disabled={loadingButton}
              onClick={() =>
                !isEmpty(formMobilityPlan) ? showMessagePreventRedirect() : handleSaveAsDraft()
              }
            >
              Save
            </AiButton>
          )}
          {isShowPaperReady && (
            <AiButton
              disabled={loadingButton}
              mode="teal"
              onClick={() => setShowChangeToPaperReady(true)}
            >
              Paper Ready
            </AiButton>
          )}
          {isShowEditPaper && (
            <AiButton disabled={loadingButton} onClick={() => setShowModalEditPaper(true)}>
              Edit Paper
            </AiButton>
          )}
          {isShowFinalizeAndUnFinalize && (
            <AiButton
              disabled={loadingButton}
              mode={agendaDetail.status === AGENDA_STATUS.PAPER_READY ? 'teal' : ''}
              className="ml-2"
              onClick={handleClickFinalizeOrUnFinalize}
            >
              {agendaDetail.status === AGENDA_STATUS.PAPER_READY ? `Finalize` : `Unfinalized`}
            </AiButton>
          )}
        </div>
      )}

      {!onlyViewTalent && (
        <Spin spinning={loadingPage} size="large">
          <TalentReviewAgendaForm
            formik={formik}
            agendaDetail={agendaDetail}
            line={addTalent?.top_talent?.line}
            type={addTalent?.top_talent?.top_talent_status || secondSectionProfile.topTalentStatus}
            role={addTalent?.top_talent?.role}
            isShowTalentDetail={isShowTalentDetail}
            addTalent={addTalent}
            setAddTalent={setAddTalent}
          />
        </Spin>
      )}

      {!isShowTalentDetail && (
        <Spin spinning={loadingPage} size="large">
          <div className={`${styles.createMobilityBar} ${styles.noContent}`}>
            <div className={styles.title}>Add Talent for Talent Review</div>
            <div className={styles.controlButton}>
              <AiButton
                border={`true`}
                mode={'teal'}
                onClick={() => setIsShowTalentSearchModal(true)}
              >
                Add Talent
              </AiButton>
              {/* {isShownByMeetingAgendaStatus && (
                <PopupListConcludedAgenda
                  type={AGENDA_TYPES.TR_AGENDA}
                  agendaDetail={agendaDetail}
                  setAgendaAsMain={setAgendaAsMain}
                  agendaAsMain={agendaAsMain}
                  handleSetAsMain={handleSetAsMain}
                  meetingId={idMeeting}
                  addedStaffId={talentSearchDetail?.talent?.staffId || agendaDetail?.staff_id}
                />
              )} */}
            </div>
          </div>
        </Spin>
      )}

      {isShowTalentDetail && (
        <>
          {!onlyViewContent && !onlyViewTalent && (
            <div className={styles.controlButton}>
              {/* {isShownByMeetingAgendaStatus && (
                <PopupListConcludedAgenda
                  type={AGENDA_TYPES.TR_AGENDA}
                  agendaDetail={agendaDetail}
                  setAgendaAsMain={setAgendaAsMain}
                  agendaAsMain={agendaAsMain}
                  handleSetAsMain={handleSetAsMain}
                  meetingId={idMeeting}
                  addedStaffId={talentSearchDetail?.talent?.staffId || agendaDetail?.staff_id}
                />
              )} */}
              <AiButton border={`true`} onClick={() => setIsPrinting(true)}>
                Print
              </AiButton>
              {isShownByMeetingAgendaStatus && (
                <AiButton mode={'danger'} onClick={() => setIsShowRemoveTalentModal(true)}>
                  Remove Talent
                </AiButton>
              )}
            </div>
          )}
          <div className={styles.createMobilityBar} ref={printRef}>
            {isShowTalentType && (
              <div className={styles.talentType}>
                <Tooltip
                  className={styles.customTooltip}
                  overlayClassName={'customTooltip'}
                  overlayStyle={{ minWidth: '181px' }}
                  title={
                    <div className={styles.tooltipContent}>
                      <div>
                        <span>Platform Name: </span>
                        {`${
                          agendaAsMain?.meeting_name ||
                          agendaDetail?.agenda_as_main_platform ||
                          'N/A'
                        }`}
                      </div>
                      <div>
                        <span>Date: </span>
                        {`${
                          agendaDetail?.talent_review_date
                            ? moment(agendaDetail?.talent_review_date).format('DD MMMM YYYY')
                            : 'N/A'
                        }`}
                      </div>
                    </div>
                  }
                  color={'black'}
                  key={'black'}
                  overlayInnerStyle={{ borderRadius: '6px' }}
                >
                  <div className={styles.talentTypeValue}>
                    {agendaAsMain?.action_type || agendaDetail?.agenda_as_main_type || 'N/A'}
                  </div>
                </Tooltip>
              </div>
            )}
            <AddTalentDetail
              meetingDetail={meetingDetail}
              agendaDetail={agendaDetail}
              addTalent={addTalent}
              setAddTalent={setAddTalent}
              setShowSaveTalentStatusEdited={() =>
                handleShowMessage('success', TALENT_REVIEW_MESSAGE.TALENT_STATUS_UPDATE_SCUCCESS)
              }
              staffTalentReview={staffTalentReview}
              setStaffTalentReview={setStaffTalentReview}
              handleShowMessage={handleShowMessage}
              isCreateOrEditRemarks={isCreateOrEditRemarks}
              onlyViewTalentAgenda={!isShownByMeetingAgendaStatus}
              remarksOfConcludedAgenda={remarksOfConcludedAgenda}
              setFormMobilityPlan={setFormMobilityPlan}
            />
          </div>

          {onlyViewTalent && (
            <ReactToPrint
              trigger={() => (
                <BasicButton mode="teal" className="mb-3">
                  Print
                </BasicButton>
              )}
              content={() => printRef.current}
              removeAfterPrint
              documentTitle={staffTalentReview?.staff_id}
            />
          )}
          {isPrinting && (
            <TalentReviewAgendaPrintPreview
              agendaDetail={agendaDetail}
              addTalent={addTalent}
              setAddTalent={setAddTalent}
              setShowSaveTalentStatusEdited={() =>
                handleShowMessage('success', TALENT_REVIEW_MESSAGE.TALENT_STATUS_UPDATE_SCUCCESS)
              }
              staffTalentReview={staffTalentReview}
              setStaffTalentReview={setStaffTalentReview}
              handleShowMessage={handleShowMessage}
              setAgendaDetail={setAgendaDetail}
              isCreateOrEditRemarks={isCreateOrEditRemarks}
              onlyViewTalentAgenda={true}
              isPrinting={isPrinting}
              setIsPrinting={setIsPrinting}
              closeModal={closeModal}
              remarksOfConcludedAgenda={remarksOfConcludedAgenda}
            />
          )}
        </>
      )}

      <ModelTC
        info={{
          type: 'paperReadyTalentAgenda',
          visible: showChangeToPaperReady,
          setVisible: setShowChangeToPaperReady,
          handleSubmit: handleChangePaperReady,
          onClose: () => setShowChangeToPaperReady(false),
        }}
      />
      <ModelTC
        info={{
          type: 'editPaperTalentAgenda',
          visible: showModalEditPaper,
          setVisible: setShowModalEditPaper,
          handleSubmit: handleEditPaper,
          onClose: () => setShowModalEditPaper(false),
        }}
      />
      <ModelTC
        info={{
          type: 'finalizeTalentAgenda',
          visible: showFinalize,
          setVisible: setShowFinalize,
          handleSubmit: handleFinalize,
          onClose: () => setShowFinalize(false),
        }}
      />
      <ModelTC
        info={{
          type: 'unFinalizeTalentAgenda',
          visible: showUnFinalize,
          setVisible: setShowUnFinalize,
          handleSubmit: handleUnFinalize,
          onClose: () => setShowUnFinalize(false),
        }}
      />
      <ModelTC
        info={{
          type: 'deleteTalentInTalentAgenda',
          visible: isShowRemoveTalentModal,
          setVisible: setIsShowRemoveTalentModal,
          handleSubmit: handleRemoveTalent,
          onClose: () => setIsShowRemoveTalentModal(false),
        }}
      />
      <ModelTC
        info={{
          type: agendaId ? 'withoutSavingAgenda' : 'leaveWhileAddAgenda',
          title: `You are about to leave this page without saving.`,
          desc: messageChanges,
          visible: isShowPopupSaveAgenda === true && isEmpty(formMobilityPlan) ? true : false,
          setVisible: setIsShowPopupSaveAgenda,
          handleSubmit: async () => {
            setIsShowPopupSaveAgenda(false);
            const canNavigate = await handleSaveAsDraft();
            if (canNavigate) {
              handleNavigate();
            }
          },
          onClose: () => {
            setIsShowPopupSaveAgenda(false);
            handleNavigate();
          },
        }}
      />

      <TalentSearchForm
        isShowTalentSearchModal={isShowTalentSearchModal}
        setIsShowTalentSearchModal={setIsShowTalentSearchModal}
        setTalentSearchDetail={setTalentSearchDetail}
        setAddTalent={setAddTalent}
      />
      {showAllRemarks && (
        <RemarkTableDialog
          isRemark={showAllRemarks}
          setIsRemark={setShowAllRemarks}
          isViewModeAgenda={!isCreateOrEditRemarks}
          agendaDetail={agendaDetail}
          handleShowMessage={handleShowMessage}
        />
      )}
    </div>
  );
};

export default AddEditTalentReviewAgenda;
