import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import styles from './NewsAnnouncements.module.scss';
import NewsCard from './components/NewsCard';
import { view_more_vector } from '../../assets/img';
import { adminApi } from '../../services/admin';
import { Link } from 'react-router-dom';

const NewsAnnouncements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [newsAnnouncement, setNewsAnnouncement] = useState([]);
  const currentCards = newsAnnouncement.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(newsAnnouncement.length / cardsPerPage);

  const [loading, setLoading] = useState(false);

  const getViewNewsAnnouncement = async () => {
    setLoading(true);
    try {
      const res = await adminApi.getViewNewsAnnouncement();
      if (res.status === 200) {
        setNewsAnnouncement(res.data.result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getViewNewsAnnouncement();
  }, []);

  return (
    <Spin spinning={loading} size="large">
      <div style={{ backgroundColor: '#FFFFFF', padding: '32px 24px', width: '1290px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontWeight: '600', fontSize: '24px' }}>News and Announcements</div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <button
              className={styles.prevNextButton}
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {'<'}
            </button>
            <button
              className={styles.prevNextButton}
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {'>'}
            </button>
          </div>
        </div>
        <div
          style={{
            marginTop: '12px',
            marginBottom: '12px',
            display: 'flex',
            flexDirection: 'row',
            gap: '53px',
          }}
        >
          {currentCards?.map((item) => {
            return <NewsCard key={item.id} item={item} />;
          })}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '33.33% 33.33% 33.33%',
          }}
        >
          <div></div>
          <ul
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
              padding: '4px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 1px 1px 1px #0000001A',
              gap: '6px',
              justifySelf: 'center',
              margin: '0',
            }}
          >
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                className={styles.customPagingButton}
                style={{
                  backgroundColor: currentPage === index + 1 ? '#00908C' : '#9FDEDB',
                  height: '8px',
                  width: '8px',
                  borderRadius: currentPage === index + 1 ? '25%' : '50%',
                  border: 'none',
                  margin: '5px',
                  cursor: 'pointer',
                  transition:
                    'border-radius 0.2s ease, transform 0.3s ease, background-color 0.3s ease',
                  transformOrigin: 'center',
                  padding: 0,
                  transform: currentPage === index + 1 ? 'scaleX(2.5)' : 'scaleX(1)',
                }}
                key={index + 1}
                onClick={() => paginate(index + 1)}
              ></button>
            ))}
          </ul>
          <Link
            to="/home/news-announcement-list"
            style={{
              fontWeight: '500',
              fontSize: '14px',
              color: '#007874',
              justifySelf: 'right',
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
              textDecoration: 'none',
            }}
          >
            {'View All'}
            <img src={view_more_vector} alt="view_more_vector" />
          </Link>
        </div>
      </div>
    </Spin>
  );
};

export default NewsAnnouncements;
