import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EditTagging, ModelTC, Pagination } from '..';
import { BasicButton } from '../../assets/common';
import { edit_talent_profile, edit_tp_active } from '../../assets/img';
import {
  addOtherExperience,
  deleteOtherExperience,
  editOtherExperience,
  fetchOtherExperiences,
} from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import { OTHER_EXPERIENCE, PERMISSION } from '../../utils/constants';
import More3DotDropdown from '../More3DotDropdown/More3DotDropdown';
import AddEditOtherExperience from './AddEditOtherExperience';
import styles from './other-experience.module.scss';

const OtherExperience = (props) => {
  const { profileId, roleUser, tokenTalentProfile } = props;
  const {
    roleActive: { roleId },
    permissions,
  } = roleUser;
  const dispatch = useDispatch();
  const [otherExperience, setOtherExperience] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentItems, setCurrentItems] = useState(1);
  const [totalResults, setTotalResults] = useState(1);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const isEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_OTHER_EXPERIENCE_TAGGING);
  }, [permissions]);

  const info = {
    roleUser,
    id: profileId,
  };
  const limit = 4;

  const fetchApi = useCallback(async () => {
    if (!roleId || !profileId) return;
    try {
      setLoading(true);
      const params = {
        page: showEdit ? 1 : currentItems,
        limit: showEdit ? 1000 : limit,
        isCheck: !showEdit ? true : false,
      };

      const response = await fetchOtherExperiences(
        params,
        roleId,
        permissions,
        profileId,
        tokenTalentProfile
      );
      if (response.status === 200) {
        setOtherExperience(response?.data?.result?.experiences);
        setTotalResults(response?.data?.result?.totalExperiences);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [currentItems, permissions, profileId, roleId, showEdit]);

  const handleClickEdit = (item) => {
    setDataEdit(item);
    setIsEdit(true);
    setOpenAddEdit(true);
  };

  const handleClickDelete = (item) => {
    setDataEdit(item);
    setOpenDelete(true);
  };

  const handleClickAdd = () => {
    setDataEdit(null);
    setIsEdit(false);
    setOpenAddEdit(true);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteOtherExperience(dataEdit.id, roleUser);
      if (res.status === 200) {
        await fetchApi();
        setOpenDelete(false);
        dispatch(
          pushMessage({
            type: 'success',
            message: 'Other Experience successfully deleted ',
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAddEdit = async (data, handleResetForm) => {
    const body = { staff_id: profileId, ...data };
    const handleSuccess = () => {
      handleResetForm();
      setOpenAddEdit(false);
      fetchApi();
      dispatch(
        pushMessage({
          message: `Other Experience successfully ${isEdit ? 'edited' : 'added'}`,
          type: 'success',
          timeShow: 0,
        })
      );
    };
    try {
      if (isEdit) {
        const res = await editOtherExperience(dataEdit.id, body, roleUser);
        if (res.status === 200) {
          handleSuccess();
        }
      } else {
        const res = await addOtherExperience(body, roleUser);
        if (res.status === 200) {
          handleSuccess();
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (roleUser.roleActive || showEdit === true) {
      fetchApi();
    }
  }, [roleUser.roleActive, showEdit, fetchApi]);

  return (
    <div className={styles.OtherExperience} data-testid="other-experiences">
      <div className={styles.OtherExperience__header}>
        <h3 className={styles.OtherExperience__h3}>Other Experience</h3>
        {isEditPermission && (
          <button className={styles.editBtn} onClick={() => setEditable(!editable)}>
            {editable ? (
              <img alt="btn_edit_active" src={edit_tp_active} />
            ) : (
              <img alt="btn_edit" src={edit_talent_profile} />
            )}
          </button>
        )}
      </div>
      {editable && (
        <BasicButton className={styles.addBtn} mode="teal" onClick={handleClickAdd}>
          Add Other Experience
        </BasicButton>
      )}
      <div className={styles.OtherExperience__table}>
        <table>
          <thead>
            <tr>
              <th>
                <p>Experience</p>
              </th>
              <th>
                <p>Remarks</p>
              </th>
              {editable && <th></th>}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td align="center" colSpan="2">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              (otherExperience && otherExperience.length > 0 ? (
                otherExperience.map((item, idx) => {
                  return (
                    <tr key={item.id || idx}>
                      <td>
                        <p>{item.experience || '-'}</p>
                      </td>
                      <td>
                        <p>{item.remarks || '-'}</p>
                      </td>
                      {editable && (
                        <td>
                          <More3DotDropdown
                            editable={Boolean(item.is_editable) && item.source === 'Manual Input'}
                            item={item}
                            handleClickEdit={handleClickEdit}
                            handleClickDelete={handleClickDelete}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr key="0">
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {otherExperience && otherExperience.length > 0 && (
        <Pagination
          page={currentItems}
          rowPerPages={limit}
          totalResults={totalResults}
          setPage={setCurrentItems}
        />
      )}
      <EditTagging
        setShow={setShowEdit}
        show={showEdit}
        list={otherExperience}
        setOtherExperience={setOtherExperience}
        type={OTHER_EXPERIENCE}
        info={info}
        loading={loading}
      />
      <AddEditOtherExperience
        open={openAddEdit}
        setOpen={setOpenAddEdit}
        isEdit={isEdit}
        dataEdit={dataEdit}
        handleSubmit={handleSubmitAddEdit}
      />
      <ModelTC
        info={{
          type: 'deleteOtherExperience',
          visible: openDelete,
          setVisible: setOpenDelete,
          handleSubmit: () => handleDelete(),
          onClose: () => setOpenDelete(false),
        }}
      />
    </div>
  );
};
export default OtherExperience;
