import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { BasicLoading } from '../../assets/common';
import { fetchLeaderShips } from '../../services/talentProfiles';
import {
  EDGE_OPTION_VALUE,
  LEADERSHIP_DOT_COLOR,
  LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR,
  LEADERSHIP_ROW_NAME,
} from '../../utils/constants';
import { convertLeadershipChartData, getNoOf2NestedArr } from '../../utils/helper';
import * as styles from './EDGEChart.module.scss';

const PROFICIENCY = [1, 2, 3, 4, 5];
const PROFICIENCY_2_LADDER = [1, 2, 3, 4, 5, 6, 7, 8];
const CHILD_PER_PARENT = 2;
const LADDER_DIFFERENCE = PROFICIENCY_2_LADDER.length - PROFICIENCY.length;
const REGEX = new RegExp(/\b\w+\b/g);

const EDGEChart = ({ selectedYear, listSelectType, id, roleUser, tokenTalentProfile }) => {
  const [rawData, setRawData] = useState({ currentRoleLevel: '', nextRoleLevel: '', edges: [] });
  const [loading, setLoading] = useState(false);

  const data = useMemo(() => {
    return convertLeadershipChartData(rawData);
  }, [rawData]);

  const is2Ladder = useMemo(() => {
    if (!rawData.currentRoleLevel || !rawData.nextRoleLevel) return false;
    const currentRole = rawData?.currentRoleLevel?.match(REGEX)[0];
    const nextRole = rawData?.nextRoleLevel?.match(REGEX)[0];
    return currentRole !== nextRole;
  }, [rawData]);

  const isSameAssessedRole = useMemo(() => {
    if (is2Ladder) {
      const currentRole = rawData?.currentRoleLevel?.match(REGEX)[0];
      const assessedRole = rawData?.assessedRoleLevel?.match(REGEX)[0];
      if (currentRole !== assessedRole) return false;
    }
    return true;
  }, [is2Ladder, rawData]);

  const currentProficiency = useMemo(() => {
    return is2Ladder ? PROFICIENCY_2_LADDER : PROFICIENCY;
  }, [is2Ladder]);

  const getLeaderShips = async () => {
    try {
      setLoading(true);
      const typeLabels = listSelectType.map((ele) => ele.label);
      const params = { types: typeLabels, year: selectedYear };
      let res = await fetchLeaderShips({ roleUser, params, id, tokenTalentProfile });
      if (res.status === 200) {
        setRawData(res.data.result);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const renderDot = (value, proficiency) =>
    Object.keys(value.proficiency).map((key) => {
      if (
        !isSameAssessedRole &&
        value.proficiency[key] &&
        key === EDGE_OPTION_VALUE.EXTERNAL.toLocaleLowerCase()
      ) {
        return (
          <>
            {Math.floor(value.proficiency[key] + LADDER_DIFFERENCE) === proficiency && (
              <span className={styles.dot} style={{ background: LEADERSHIP_DOT_COLOR[key] }} />
            )}
          </>
        );
      }
      return (
        <>
          {Math.floor(value.proficiency[key]) === proficiency && (
            <span className={styles.dot} style={{ background: LEADERSHIP_DOT_COLOR[key] }} />
          )}
        </>
      );
    });

  const renderValue = (parentIndex, childIndex, proficiency) => {
    const value = data[parentIndex * CHILD_PER_PARENT + childIndex];
    const nextEffective =
      is2Ladder && value.nextEffectiveProficiency
        ? value.nextEffectiveProficiency + LADDER_DIFFERENCE
        : value.nextEffectiveProficiency;
    return (
      <>
        {value.currentEffectiveProficiency === proficiency && (
          <div
            className={styles.effectiveProficiency}
            style={{
              background: LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.CURRENT,
            }}
          />
        )}
        {nextEffective === proficiency && (
          <div
            id="next"
            className={styles.effectiveProficiency}
            style={{
              background: LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.NEXT,
            }}
          />
        )}
        {renderDot(value, proficiency)}
      </>
    );
  };

  useEffect(() => {
    if (listSelectType.length > 0) {
      getLeaderShips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSelectType, selectedYear]);

  return (
    <div id="EDGE">
      <BasicLoading spinning={loading}>
        <div className={styles.wrapper}>
          <div className={styles.desc}>
            {listSelectType.map((item) => (
              <React.Fragment key={item.value}>
                {item.label && (
                  <>
                    <div className={styles.name}>
                      <span
                        className={styles.dot}
                        style={{ background: LEADERSHIP_DOT_COLOR[item.label.toLowerCase()] }}
                      />
                      <span>{item.label}</span>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
            <div className={styles.name}>
              <span
                className={styles.effective}
                style={{ background: LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.CURRENT }}
              />
              {`Effective ${rawData.currentRoleLevel || ''}`}
            </div>
            {rawData.nextRoleLevel && (
              <div className={styles.name}>
                <span
                  className={styles.effective}
                  style={{ background: LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR.NEXT }}
                />
                {`Effective ${rawData.nextRoleLevel || ''}`}
              </div>
            )}
          </div>

          <div className={styles.ladder_wrapper}>
            <div className={styles.ladder}>
              {PROFICIENCY.map((value) => (
                <span key={value} className={is2Ladder ? styles.upper : ''}>
                  {value}
                </span>
              ))}
            </div>
            {is2Ladder && (
              <div className={styles.ladder}>
                {[...PROFICIENCY_2_LADDER].map((value) => (
                  <span key={value} className={value > PROFICIENCY.length ? styles.hidden : ''}>
                    {value <= PROFICIENCY.length ? value : ''}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className={styles.body}>
            {LEADERSHIP_ROW_NAME.map((item, parentIndex) => (
              <div className={styles.row} key={item.name}>
                <div className={styles.parent} style={{ background: item.color }}>
                  {item.name}
                </div>

                <div className={styles.child}>
                  {item.childNames.map((childName, childIndex) => (
                    <div
                      className={styles.child_wrapper}
                      key={childName}
                      style={{ background: item.childBackground }}
                    >
                      <div className={styles.child_name}>
                        <div className={styles.child_no} style={{ background: item.childColor }}>
                          {getNoOf2NestedArr(parentIndex, childIndex)}
                        </div>
                        <div className={styles.child_title}>{childName}</div>
                      </div>

                      <div className={styles.value_wrapper}>
                        {currentProficiency.map((proficiency) => (
                          <div className={styles.value_cell} key={proficiency}>
                            {!isEmpty(data) && renderValue(parentIndex, childIndex, proficiency)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </BasicLoading>
    </div>
  );
};
export default EDGEChart;
