import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './external-experience.module.scss';
import { BasicTable } from '../../assets/common';
import { Spin } from 'antd';
import talentProfileApi from '../../services/talentProfiles';
import moment from 'moment';

const NOT_AVAILABLE = 'Not Available';
const COLUMNS = [
  {
    title: 'Job Title',
    width: 150,
    dataIndex: 'job_title',
    render: (text) => <span className={styles.text1}>{text || NOT_AVAILABLE}</span>,
  },
  {
    title: 'Start Date',
    width: 130,
    dataIndex: 'start_date',
    render: (text) => (
      <span className={styles.text2}>
        {text ? moment(text).format('DD MMM YYYY') : NOT_AVAILABLE}
      </span>
    ),
  },
  {
    title: 'End Date',
    width: 130,
    dataIndex: 'end_date',
    render: (text) => (
      <span className={styles.text2}>
        {text ? moment(text).format('DD MMM YYYY') : NOT_AVAILABLE}
      </span>
    ),
  },
  {
    title: 'Employer Name',
    dataIndex: 'employer_name',
    render: (text) => <span className={styles.text2}>{text || NOT_AVAILABLE}</span>,
  },
  {
    title: 'Job Function',
    dataIndex: 'job_function',
    width: 150,
    render: (text) => <span className={styles.text2}>{text || NOT_AVAILABLE}</span>,
  },
];
const TABLE_STYLE = {
  headerColor: '#181818',
  headerWeight: 500,
  borderRadius: 6,
  roundPagination: true,
};

const ExternalExperience = ({ staffId, role, tokenTalentProfile }) => {
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await talentProfileApi.getExternalExperience(staffId, role, tokenTalentProfile);
      if (res.status === 200) {
        const data = res.data.result.map((item, index) => ({ ...item, id: index }));
        setExperiences(data);
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>External Experience</div>
      <Spin spinning={loading}>
        <BasicTable
          rowKey="id"
          styles={TABLE_STYLE}
          dataSource={experiences}
          columns={COLUMNS}
          pagination={false}
        />
      </Spin>
    </div>
  );
};

ExternalExperience.propTypes = {
  staffId: PropTypes.string,
  role: PropTypes.object,
};

export default ExternalExperience;
