import { Spin, Tooltip } from 'antd';
import { get, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { BasicPopover, BasicTable } from '../../assets/common';
import { close_modal_2 } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { groupAspirationMatching } from '../../utils/helper';
import {
  CustomNumber,
  Legend1,
  Legend2,
  TableData,
  TableDiv,
  Text,
} from '../ComplementaryExperience/complementaryExperienceStyled';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './career-aspiration.module.scss';

const Wrapper = styled.div`
  width: 600px;
`;
const modalStyle = {
  borderRadius: 8,
  closePosition: [32, 26],
  containerPaddingLeftRight: 32,
  containerPaddingTopBottom: 26,
  headerPaddingBottom: 12,
  headerBorderBottom: 'none',
  titleFontSize: 20,
  titleFontWeight: 700,
  titleColor: '#3F3C4C',
};

const tablePositionStyle = {
  headerWeight: 500,
  headerBackground: '#F2F4F8',
  headerColor: '#181818',
};

const ListPositionTable = React.memo(({ data, setViewPositionsIndex }) => {
  const columns = [
    {
      title: 'Position Code',
      width: '30%',
      dataIndex: 'position_code',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
    {
      title: 'Position Name',
      width: '70%',
      dataIndex: 'position_name',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
  ];

  return (
    <div className={styles.positionTable}>
      <img
        src={close_modal_2}
        alt="close_view_list_position"
        className={styles.close}
        onKeyDown={() => {}}
        onClick={() => setViewPositionsIndex(-1)}
      />
      <Wrapper>
        <BasicTable
          rowKey={(record) => `${record.position_code}${record.position_name}`}
          dataSource={data.filter((item) => !item.is_hide)}
          columns={columns}
          pagination={false}
          styles={tablePositionStyle}
          scroll={{ y: 275 }}
        />
      </Wrapper>
    </div>
  );
});

ListPositionTable.displayName = 'ListPositionTable';

ListPositionTable.propTypes = {
  data: PropTypes.array,
  setViewPositionsIndex: PropTypes.func,
};

const CareerAspirationModalTable = ({ openModal, setOpenModal }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [viewPositionsIndex, setViewPositionsIndex] = useState(-1);
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });
  const getTableData = async () => {
    try {
      setLoading(true);
      const params = { page: query.page, limit: query.limit };
      const res = await talentProfileApi.getAspirationMatching(id, params);
      const data = get(res, 'data.result.resAspMatching') || [];
      const total = get(res, 'data.result.total') || 0;
      setData(groupAspirationMatching(data));
      setQuery((prev) => ({ ...prev, total }));
    } catch (error) {
      console.error(error);
      setData([]);
      setQuery({
        page: 1,
        total: 0,
        limit: 10,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Job Function',
      dataIndex: 'job_function',
      key: 'job_function',
      width: '20%',
    },
    {
      title: '',
      width: '15%',
      dataIndex: 'list_of_position',
    },
    {
      title: 'Source',
      width: '10%',
      dataIndex: 'source',
    },
    {
      title: 'Confidence Score',
      key: 'confidence_score',
      dataIndex: 'confidence_score',
      width: '5%',
    },
    {
      title: 'Aspiration Statement',
      dataIndex: 'aspiration_statement',
      width: '35%',
    },
  ];

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  useEffect(() => {
    if (openModal) {
      getTableData();
    }
  }, [id, query.page, openModal]);

  const onCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <CustomAntModal
      hideDefaultBtn
      styles={modalStyle}
      open={openModal}
      setOpen={(value) => {
        setOpenModal(value);
      }}
      title="View Aspiration Matching"
      width={1500}
      closeType={1}
      onCancelModal={onCancelModal}
    >
      <div className={styles.jobFuncDesc}>
        <div>
          <div className={styles.modalOutput} />
          Model Output
        </div>
        <div>
          <div className={styles.admin} />
          Updated by Admin
        </div>
      </div>
      <Spin spinning={loading}>
        <div className={styles.table}>
          <TableDiv>
            <TableData
              columns={columns}
              rowClassName={(record) => {
                return record?.isFirstRow ? 'border' : '';
              }}
              dataSource={
                !isEmpty(data) &&
                data.map((item, _index) => ({
                  key: uuidv4(),
                  isFirstRow: item?.isFirstRow,
                  totalRowSpan: item?.totalRowSpan,
                  isSearching: item?.isSearching,
                  isJobFunction: item?.job_function,
                  job_function: (
                    <>
                      <div className="d-flex justify-content-between align-items-center">
                        {item.is_updated === false ? <Legend1 /> : <Legend2 />}
                        <Text className="col-10">{item.job_function}</Text>
                      </div>
                    </>
                  ),
                  list_of_position: (
                    <BasicPopover
                      content={
                        <ListPositionTable
                          data={item.list_of_position}
                          setViewPositionsIndex={setViewPositionsIndex}
                        />
                      }
                      trigger="click"
                      placement="bottomLeft"
                      getPopupContainer={(trigger) => trigger}
                      open={_index === viewPositionsIndex}
                      onOpenChange={(open) => {
                        if (!open) {
                          setViewPositionsIndex(-1);
                        }
                      }}
                      styles={{
                        minWidth: 630,
                      }}
                    >
                      <div
                        className={`textListPosition ${
                          isEmpty(
                            item?.list_of_position &&
                              isArray(item?.list_of_position) &&
                              item?.list_of_position.filter((item) => !item.is_hide)
                          ) ||
                          (!item?.list_of_position[0]?.position_code &&
                            !item?.list_of_position[0]?.position_name)
                            ? `disable`
                            : ''
                        }`}
                        onKeyDown={() => {}}
                        onClick={() => setViewPositionsIndex(_index)}
                      >
                        View list of position
                      </div>
                    </BasicPopover>
                  ),
                  source: (
                    <>
                      {item?.isFirstRow || _index % 10 === 0 ? (
                        <Text>{item.source}</Text>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ),
                  confidence_score: (
                    <Text>
                      {item.confidence_score && Number.parseFloat(item.confidence_score).toFixed(2)}
                    </Text>
                  ),
                  aspiration_statement: (
                    <>
                      {item?.isFirstRow || _index % 10 === 0 ? (
                        <Tooltip
                          title={
                            item.talent_statement && (
                              <div>Talent Statement: {item.talent_statement}</div>
                            )
                          }
                          {...{
                            overlayInnerStyle: {
                              width: '500px',
                              fontSize: '12px',
                              lineHeight: '18px',
                              fontWeight: 400,
                              fontFamily: '"Inter", sans-serif',
                              padding: '12px',
                              borderRadius: '4px',
                              backgroundColor: '#000',
                            },
                          }}
                        >
                          <Text>{item.aspiration_statement}</Text>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ),
                  group: item.group,
                  index: _index,
                }))
              }
              pagination={{ position: ['bottomRight'], ...paginationConfig }}
            />
          </TableDiv>
        </div>
      </Spin>
    </CustomAntModal>
  );
};

CareerAspirationModalTable.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default CareerAspirationModalTable;
