import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { BasicButton, BasicPopover, BasicTable, font } from '../../../../assets/common';
import {
  dot_news_and_announcement,
  edit_news_announcement,
  filter_news_annoucement,
  last_updated_news_announcement,
  no_image_news_announcement,
  trash_news_announcement,
} from '../../../../assets/img';
import DropdownCheckbox, {
  CheckBoxCustom,
} from '../../../../components/DropdownCheckbox/DropdownCheckbox';
import { Spin, Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import ModelTC from '../../../../components/Model/ModelMeetings/ModelTC';
import { adminApi } from '../../../../services/admin';
import { useSelector } from 'react-redux';
import moment from 'moment';

const COLUMNS = [
  {
    title: '',
    width: '34px',
    dataIndex: 'checkbox',
  },
  {
    title: 'Thumbnail',
    width: '114px',
    dataIndex: 'thumbnail_url',
  },
  {
    title: 'Title',
    width: '207px',
    dataIndex: 'title',
  },
  {
    title: 'Content',
    width: '194px',
    dataIndex: 'content',
    render: (text) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
  },
  {
    title: 'Banner',
    dataIndex: 'is_spotlight',
    width: '61px',
  },
  {
    title: 'Published by',
    dataIndex: 'published_by',
    width: '127px',
  },
  {
    title: 'Published Date',
    dataIndex: 'published_date',
    width: '136px',
  },
  {
    title: 'Expiry Date',
    dataIndex: 'expiry_date',
    width: '126px',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '89px',
  },
  {
    title: 'Last Updated',
    dataIndex: 'last_updated',
    width: '128px',
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'dot',
    width: '16px',
  },
];

const Status = styled.div`
  padding: 4px 8px;
  width: fit-content;
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: center;
  ${(props) => {
    switch (props.status) {
      case 'Published':
        return css`
          background-color: #e7f4e8;
          color: #096924;
        `;
      case 'Draft':
        return css`
          background-color: #ebebeb;
          color: #3d3d3d;
        `;
      case 'Expired':
        return css`
          background-color: #fbe2e2;
          color: #a91a1f;
        `;
    }
  }}
`;

const PopoverContent = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const DefaultImage = styled.div`
  padding: 5px 12px;
  height: 100px;
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0168em;
  text-align: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  gap: 5px;
  font-family: ${font.inter};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;

const NewsAndAnnouncementTable = (props) => {
  const { newsAndAnnouncement, setNewAndAnnouncement } = props;
  const [open, setOpen] = useState(null);
  function onChangeSelect(value) {
    const isExisted = newsAndAnnouncement.deleteMultiple.data.find((f) => f === value);
    setNewAndAnnouncement((prev) => ({
      ...prev,
      deleteMultiple: {
        ...prev.deleteMultiple,
        data: isExisted
          ? [...prev.deleteMultiple.data].filter((f) => f !== value)
          : [...prev.deleteMultiple.data, value],
      },
    }));
  }

  const paginationConfig = {
    total: newsAndAnnouncement?.total,
    current: newsAndAnnouncement?.page,
    showSizeChanger: false,
    defaultPageSize: newsAndAnnouncement?.limit,
    onChange: (pageNum) => {
      pageNum !== newsAndAnnouncement?.page &&
        setNewAndAnnouncement &&
        setNewAndAnnouncement((prev) => ({
          ...prev,
          page: pageNum,
          deleteSingle: {
            isDelete: false,
            data: [],
          },
          deleteMultiple: {
            isDelete: false,
            data: [],
          },
        }));
    },
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  return (
    <Spin spinning={newsAndAnnouncement?.loading}>
      <BasicTable
        styles={{
          headerColor: '#8896A7',
          headerWeight: 700,
          borderRadius: 6,
          backgroundPagination: '#AFDDDC',
          borderColorPagination: '#AFDDDC',
          colorPagination: '#00645C',
          headerPadding: '16px 10px',
          bodyPadding: '16px 10px',
        }}
        dataSource={
          !isEmpty(newsAndAnnouncement?.data)
            ? newsAndAnnouncement?.data.map((item, _index) => ({
                key: item?.id,
                id: item?.id,
                checkbox: (
                  <CheckBoxCustom
                    onClick={() => onChangeSelect(item.id)}
                    checked={newsAndAnnouncement.deleteMultiple.data.find((f) => f === item.id)}
                  />
                ),
                thumbnail_url: (
                  <>
                    {item.thumbnail_url ? (
                      <img
                        src={item?.thumbnail_url}
                        alt={item?.thumbnail_url}
                        style={{ width: '200px', height: '100px', objectFit: 'cover' }}
                        loading="lazy"
                      />
                    ) : (
                      <DefaultImage>
                        <img
                          src={no_image_news_announcement}
                          alt={no_image_news_announcement}
                          style={{ width: '20px' }}
                          loading="lazy"
                        />
                        <div>No image added</div>
                      </DefaultImage>
                    )}
                  </>
                ),
                title: item?.title,
                content: (
                  <>
                    {item?.content && item.content.length > 130
                      ? item.content.slice(0, 130) + '...'
                      : item?.content}
                  </>
                ),
                is_spotlight: item?.is_spotlight ? 'Yes' : 'No',
                published_by: item?.published_by,
                published_date: (
                  <>
                    {item?.published_date ? moment(item?.published_date).format('DD/MM/YYYY') : ''}
                  </>
                ),
                expiry_date: (
                  <>{item?.expiry_date ? moment(item?.expiry_date).format('DD/MM/YYYY') : ''}</>
                ),
                status: <Status status={item?.status}>{item?.status}</Status>,
                last_updated: (
                  <>
                    {item?.last_updated_by && item?.updated_on && (
                      <Tooltip
                        placement="top"
                        title={
                          <div
                            style={{
                              display: 'grid',
                              rowGap: '10px',
                              fontWeight: '400',
                              fontSize: '12px',
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: '600' }}>Last updated by:</span>{' '}
                              {item.last_updated_by}
                            </div>
                            <div>
                              <span style={{ fontWeight: '600' }}>Updated on:</span>{' '}
                              {item.updated_on
                                ? moment(item?.updated_on).format('DD/MM/YYYY hh:mm a')
                                : ''}
                            </div>
                          </div>
                        }
                        {...{
                          overlayInnerStyle: {
                            fontFamily: font.inter,
                            backgroundColor: '#000',
                            fontSize: '12px',
                            lineHeight: '18px',
                            gap: '24px',
                            padding: '12px',
                            borderRadius: '4px',
                          },
                        }}
                      >
                        <img
                          src={last_updated_news_announcement}
                          alt="last_updated_news_announcement"
                        />
                      </Tooltip>
                    )}
                  </>
                ),
                dot: (
                  <BasicPopover
                    open={open == item.id ? true : false}
                    onOpenChange={(open) => {
                      setOpen(open ? item.id : null);
                    }}
                    content={
                      <PopoverContent>
                        <DropdownItem onClick={() => setOpen(null)}>
                          <img src={edit_news_announcement} /> Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setNewAndAnnouncement((prev) => ({
                              ...prev,
                              deleteSingle: {
                                ...prev.deleteSingle,
                                isDelete: true,
                                data: [item.id],
                              },
                            }))
                          }
                        >
                          <img src={trash_news_announcement} />{' '}
                          <span style={{ color: '#DA2228' }}>Delete</span>
                        </DropdownItem>
                      </PopoverContent>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    getPopupContainer={(trigger) => trigger}
                    styles={{
                      w: 180,
                    }}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <img src={dot_news_and_announcement} alt="dot_news_and_announcement" />
                    </div>
                  </BasicPopover>
                ),
              }))
            : []
        }
        columns={COLUMNS}
        pagination={{ position: ['bottomRight'], ...paginationConfig }}
      />
    </Spin>
  );
};

export default function NewsAndAnnouncement() {
  const [newsAndAnnouncement, setNewAndAnnouncement] = useState({
    loading: false,
    page: 1,
    total: 0,
    limit: 15,
    filter: {
      display: false,
      banner: [],
      status: [],
    },
    data: [],
    deleteSingle: {
      isDelete: false,
      data: [],
    },
    deleteMultiple: {
      isDelete: false,
      data: [],
    },
  });
  const roleActive = useSelector((state) => state.user.roleActive);
  useEffect(() => {
    const root = document.querySelector('#root');
    if (root) {
      root.style.webkitTransform = 'translate3d(0, 0, 0)';
      // Alternatively, for cross-browser compatibility, you might want to apply the non-prefixed version as well
      root.style.transform = 'translate3d(0, 0, 0)';
    }

    return () => {
      if (root) {
        root.style.removeProperty('webkitTransform'); // Remove the -webkit-transform property
        root.style.removeProperty('transform'); // Remove the transform property
      }
    };
  }, []);
  async function getNewsAnnouncement() {
    try {
      setNewAndAnnouncement((prev) => ({ ...prev, loading: true }));
      const res = await adminApi.getNewsAnnouncement(
        {
          page: newsAndAnnouncement.page,
          limit: newsAndAnnouncement.limit,
          banner:
            newsAndAnnouncement.filter.banner.length === 1
              ? newsAndAnnouncement.filter.banner[0]
              : '',
          status: isEmpty(newsAndAnnouncement.filter.status)
            ? 'published,draft,expired'
            : newsAndAnnouncement.filter.status.join(','),
        },
        roleActive.roleId
      );
      const total = get(res, 'data.result.total');
      const data = get(res, 'data.result.data');
      setNewAndAnnouncement((prev) => ({ ...prev, loading: false, total, data }));
    } catch (error) {
      console.log(error);
      setNewAndAnnouncement((prev) => ({ ...prev, loading: false, data: [], total: 0, page: 1 }));
    }
  }
  async function onDeleteAnnouncement(deletes) {
    try {
      setNewAndAnnouncement((prev) => ({ ...prev, loading: true }));
      await adminApi.deleteNewsAnnouncement({ ids: deletes }, roleActive.roleId);
      if (newsAndAnnouncement.page === 1) {
        setNewAndAnnouncement((prev) => ({
          ...prev,
          deleteSingle: {
            isDelete: false,
            data: [],
          },
          deleteMultiple: {
            isDelete: false,
            data: [],
          },
        }));
        getNewsAnnouncement();
      }
      if (newsAndAnnouncement.page !== 1) {
        setNewAndAnnouncement((prev) => ({
          ...prev,
          page: 1,
          deleteSingle: {
            isDelete: false,
            data: [],
          },
          deleteMultiple: {
            isDelete: false,
            data: [],
          },
        }));
      }
    } catch (error) {
      console.log(error);
      setNewAndAnnouncement((prev) => ({
        ...prev,
        loading: false,
        deleteSingle: {
          isDelete: false,
          data: [],
        },
        deleteMultiple: {
          isDelete: false,
          data: [],
        },
      }));
    }
  }

  useEffect(() => {
    getNewsAnnouncement();
  }, [
    newsAndAnnouncement.page,
    JSON.stringify(newsAndAnnouncement.filter.banner),
    JSON.stringify(newsAndAnnouncement.filter.status),
  ]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div
          className="d-flex justify-content-start align-items-center"
          style={{
            gap: '10px',
            visibility: newsAndAnnouncement.filter.display === false && 'hidden',
          }}
        >
          <DropdownCheckbox
            stt={0}
            handleChangeFilter={(val) =>
              setNewAndAnnouncement((prev) => ({
                ...prev,
                page: JSON.stringify(val) !== JSON.stringify(prev.filter.banner) ? 1 : prev.page,
                filter: { ...prev.filter, banner: val },
              }))
            }
          >
            Banner
          </DropdownCheckbox>
          <DropdownCheckbox
            stt={1}
            handleChangeFilter={(val) =>
              setNewAndAnnouncement((prev) => ({
                ...prev,
                page: JSON.stringify(val) !== JSON.stringify(prev.filter.status) ? 1 : prev.page,
                filter: { ...prev.filter, status: val },
              }))
            }
          >
            Status
          </DropdownCheckbox>
        </div>
        <div className="d-flex justify-content-start align-items-center" style={{ gap: '10px' }}>
          <BasicButton
            mode="remove"
            width="100px"
            height="36px"
            disabled={newsAndAnnouncement.deleteMultiple.data.length === 0 ? true : false}
            onClick={
              newsAndAnnouncement.deleteMultiple.data.length === 0
                ? undefined
                : () =>
                    setNewAndAnnouncement((prev) => ({
                      ...prev,
                      deleteMultiple: { ...prev.deleteMultiple, isDelete: true },
                    }))
            }
          >
            Delete
          </BasicButton>
          <Link to={'/admin/home-management/news-announcement/create'}>
            <BasicButton mode="teal" width="100px" height="36px">
              Add new
            </BasicButton>
          </Link>
          <img
            src={filter_news_annoucement}
            alt="filter_news_annoucement"
            style={{ pointerEvents: 'all', cursor: 'pointer' }}
            onClick={() =>
              setNewAndAnnouncement((prev) => ({
                ...prev,
                filter: { ...prev.filter, display: !prev.filter.display },
              }))
            }
          />
        </div>
      </div>
      <NewsAndAnnouncementTable
        newsAndAnnouncement={newsAndAnnouncement}
        setNewAndAnnouncement={setNewAndAnnouncement}
      />
      {(newsAndAnnouncement.deleteSingle.isDelete ||
        newsAndAnnouncement.deleteMultiple.isDelete) && (
        <ModelTC
          info={{
            type: 'deleteNewsAnnouncement',
            visible:
              newsAndAnnouncement.deleteSingle.isDelete ||
              newsAndAnnouncement.deleteMultiple.isDelete,
            setVisible: newsAndAnnouncement.deleteSingle.isDelete
              ? (val) =>
                  setNewAndAnnouncement((prev) => ({
                    ...prev,
                    deleteSingle: { ...prev.deleteSingle, isDelete: val },
                  }))
              : (val) =>
                  setNewAndAnnouncement((prev) => ({
                    ...prev,
                    deleteMultiple: { ...prev.deleteMultiple, isDelete: val },
                  })),
            handleSubmit: () => {
              newsAndAnnouncement.deleteSingle.isDelete
                ? onDeleteAnnouncement(newsAndAnnouncement.deleteSingle.data)
                : onDeleteAnnouncement(newsAndAnnouncement.deleteMultiple.data);
            },
            onClose: () => {
              newsAndAnnouncement.deleteSingle.isDelete
                ? setNewAndAnnouncement((prev) => ({
                    ...prev,
                    deleteSingle: { ...prev.deleteSingle, isDelete: false, data: [] },
                  }))
                : setNewAndAnnouncement((prev) => ({
                    ...prev,
                    deleteMultiple: { ...prev.deleteMultiple, isDelete: false, data: [] },
                  }));
            },
          }}
        />
      )}
    </div>
  );
}
