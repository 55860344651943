import { Dropdown, Space, Spin, Tooltip } from 'antd';
import { get, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { BasicButton, BasicPopover, BasicTable } from '../../assets/common';
import {
  chevron_down_complementary,
  close,
  close_modal_2,
  deleteNote,
  edit_delete,
} from '../../assets/img';
import { useShowPopupAspiration } from '../../hooks/useShowPopupAspiration';
import talentProfileApi from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  GLOBAL_ALERT_MESSAGE_CONTAINER_ID,
  MODULE_FILTER_TABLE,
} from '../../utils/constants';
import {
  convertToAddEditAspirationMatching,
  groupAspirationMatching,
  insertAtIndex,
  returnContentHideStatus,
} from '../../utils/helper';
import {
  CustomNumber,
  Legend1,
  Legend2,
  LegendText,
  SelectAnt,
  TableData,
  TableDiv,
  Text,
  Title,
  Wrapper,
  WrapperLegend,
} from '../ComplementaryExperience/complementaryExperienceStyled';
import GlobalAlertMessage from '../GlobalAlertMessage/GlobalAlertMessage';
import { ModelTC } from '../Model';
import SearchJobFunctionComplementary from '../SearchJobFunctionComplementary/SearchJobFunctionComplementary';
import styles from './career-aspiration.module.scss';
import { updateIsError } from '../../store/pageSlice';
import DescForWithoutModalAspiration from '../DescForWithoutModalMatching/DescForWithoutModalAspiration';
import { useShowAlertWhenRefresh } from '../../hooks/useShowAlertWhenRefresh';

const tablePositionStyle = {
  headerWeight: 500,
  headerBackground: '#F2F4F8',
  headerColor: '#181818',
};

const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .text {
    color: #181818;
  }

  .textInfo {
    color: #3f3c4c;
  }

  .close {
    margin-bottom: -8px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  table {
    .ant-table-thead > tr > th {
      background: #f2f4f8 !important;
      color: #181818 !important;
    }
  }
`;

const WrapperPositionTable = styled.div`
  width: 600px;
`;

const CancelButton = styled.div`
  width: 108px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  color: #00a19c;
  padding: 8px 30px;
  user-select: none;
  cursor: pointer;
`;

const ListPositionTable = ({ data, setViewPositionsIndex }) => {
  const columns = [
    {
      title: 'Position Code',
      width: '30%',
      dataIndex: 'position_code',
      render: (text) => <div className={styles.text}>{text}</div>,
    },
    {
      title: 'Position Name',
      width: '70%',
      dataIndex: 'position_name',
      render: (text) => <div className={styles.text}>{text}</div>,
    },
  ];

  return (
    <PositionWrapper>
      <img
        src={close_modal_2}
        alt="close_view_list_position"
        className={styles.close}
        onKeyDown={() => {}}
        onClick={() => setViewPositionsIndex(-1)}
      />
      <WrapperPositionTable>
        <BasicTable
          rowKey={(record) => `${record.position_code}${record.position_name}`}
          dataSource={data.filter((item) => !item.is_hide)}
          columns={columns}
          pagination={false}
          styles={tablePositionStyle}
          scroll={{ y: 275 }}
        />
      </WrapperPositionTable>
    </PositionWrapper>
  );
};

const CareerAspirationEdit = (props) => {
  const {
    aspirationMatchingEdit,
    setAspirationMatchingEdit,
    roleId,
    staffId,
    permissions,
    getTalentProfileSummary,
  } = props;
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });

  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dataChanges = useMemo(() => {
    return convertToAddEditAspirationMatching(data, staffId);
  }, [data, staffId]);
  const {
    isShowPopupSaveAgenda,
    setIsShowPopupSaveAgenda,
    handleKeepNavigate,
    navigatingPathname,
  } = useShowPopupAspiration(dataChanges);
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    setData(groupAspirationMatching(data.map(({ isFirstRow, totalRowSpan, ...item }) => item)));
  }, [JSON.stringify(data)]);
  const thresholdVal = 0.7;
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [viewPositionsIndex, setViewPositionsIndex] = useState(-1);
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const columns = [
    {
      title: 'Job Function',
      dataIndex: 'job_function',
      key: 'job_function',
      width: '20%',
      onCell: (record) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.ids ? 4 : 1,
        };
      },
    },
    {
      title: '',
      width: '15%',
      dataIndex: 'list_of_position',
      className: 'align_top_content',
    },
    {
      title: 'Source',
      width: '10%',
      dataIndex: 'source',
      className: 'align_top_content',
      onCell: (record) => {
        return {
          rowSpan: record?.isFirstRow ? record?.totalRowSpan : 0,
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.ids ? 0 : 1,
        };
      },
    },
    {
      title: 'Confidence Score',
      key: 'confidence_score',
      dataIndex: 'confidence_score',
      width: '5%',
      onCell: (record) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.ids ? 0 : 1,
        };
      },
    },
    {
      title: 'Aspiration Statement',
      dataIndex: 'aspiration_statement',
      width: '35%',
      onCell: (record) => {
        return {
          rowSpan: record?.isFirstRow ? record?.totalRowSpan : 0,
          colSpan: record?.isSearching && !record.isJobFunction ? 0 : 1,
        };
      },
    },
    {
      title: 'Hide',
      dataIndex: 'show_hide',
      key: 'show_hide',
      width: '25%',
      // onCell: (record) => {
      //   return {
      //     colSpan: record?.isSearching && !record.isJobFunction ? 0 : 1,
      //   };
      // },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      onCell: (record) => {
        return {
          colSpan: (record?.isSearching && !record.isJobFunction) || isOpen === record?.ids ? 0 : 1,
        };
      },
    },
  ];

  const getViewData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await talentProfileApi.getAspirationMatchingToEdit(staffId, roleId);
      const data = get(res, 'data.result.resAspMatching') || [];
      const total = get(res, 'data.result.total') || 0;
      setData(data);
      setQuery((prev) => ({ ...prev, total }));
    } catch (error) {
      console.error(error);
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, `An unexpected error occurred.`);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, staffId]);

  useEffect(() => {
    if (!roleId || !staffId) return;
    getViewData();
  }, [roleId, staffId, getViewData]);

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;
  console.log(data);
  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  const saveAspirationMatching = async () => {
    dispatch(updateIsError(false));
    setAspirationMatchingEdit({ mode: 'view', open: true });
    try {
      setLoading(true);
      const bodyUpdate = convertToAddEditAspirationMatching(data, staffId);
      if (isEmpty(bodyUpdate)) {
        const index = data.findIndex((f) => !f.job_function);
        const lastIndex = data.length - 1;
        setQuery((prev) => ({
          ...prev,
          page: index % 10 === 0 && index === lastIndex ? prev.page - 1 : prev.page,
          total: data.filter((f) => f.job_function)?.length,
        }));
        setData((prev) => [...prev].filter((f) => f.job_function));
        setLoading(false);
        return;
      }
      const res = await talentProfileApi.updateAspirationMatching(staffId, bodyUpdate, roleId);
      setLoading(false);
      if (res.status === 200) {
        handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, 'The changes have been saved successfully.');
        getViewData();
        getTalentProfileSummary();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, `An unexpected error occurred.`);
    }
  };

  const addJobFunction = ({ item, _index, job_function_value, type }) => {
    if (data.some((d) => !d?.job_function)) {
      dispatch(updateIsError(true));
      handleShowMessage(
        ALERT_MESSAGE_TYPE.ERROR,
        `There is item that require your attention. Please fill out this field.`
      );
      return;
    }
    const body = {
      ids: `tempId_${uuidv4()}`,
      prev_id: item?.ids ?? null,
      list_of_position: item?.list_of_position ?? [],
      aspiration_statement: item?.aspiration_statement,
      talent_statement: item?.talent_statement,
      source: item?.source,
      confidence_score: 1,
      is_updated: true,
      is_hide: false,
      original_minilmjobfunction: item.original_minilmjobfunction,
      job_function: job_function_value ?? '',
      edit_type: 'hide',
    };
    if (type === 'isSearching') body.isSearching = true;
    if (type === 'isEditing') body.isEditing = true;
    const newData = insertAtIndex(data, _index + 1, body);
    setData(newData);
    setQuery((prev) => ({ ...prev, total: prev.total + 1 }));
  };

  const handleShowMessage = (type, message, description) => {
    dispatch(
      pushMessage({
        type,
        message,
        description,
        timeShow: 0,
        containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_ASPIRATION,
      })
    );
  };

  const handleCancel = () => {
    if (isEmpty(dataChanges)) {
      dispatch(updateIsError(false));
      setAspirationMatchingEdit({ mode: 'edit', open: false });
    } else {
      setIsShowPopupSaveAgenda(true);
    }
  };

  const handleDeleteRecord = (record) => {
    setSelectedRecord(record);
    setConfirmDeleteModal(true);
  };

  const confirmDeleteRecord = async () => {
    if (selectedRecord?.ids && !selectedRecord.ids.includes('tempId_')) {
      try {
        setLoading(true);
        const res = await talentProfileApi.deleteAspirationMatchingRecord(
          staffId,
          selectedRecord.ids,
          roleId
        );
        setLoading(false);
        if (res.status === 200) {
          resetDataAfterDeletedRecord();
          getTalentProfileSummary();
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, `An unexpected error occurred.`);
      }
    } else {
      const count = data.filter((f) => f.prev_id === selectedRecord.prev_id)?.length;
      if (count === 1) {
        setData((prev) => {
          const parentItem = prev.find((i) => i.ids === selectedRecord?.prev_id);
          if (parentItem) {
            parentItem.edit_type = parentItem.originalHide?.original_edit_type;
            parentItem.is_hide = parentItem.originalHide?.value;
          }
          delete parentItem.isEditing;
          delete parentItem.originalHide;
          return [...prev];
        });
      }
      resetDataAfterDeletedRecord();
    }
  };

  const resetDataAfterDeletedRecord = () => {
    setQuery((prev) => ({
      ...prev,
      page: 1,
      total: data.filter((f) => f.ids !== selectedRecord.ids)?.length,
    }));
    setData((prev) => [...prev].filter((f) => f.ids !== selectedRecord.ids));
    handleShowMessage(
      ALERT_MESSAGE_TYPE.SUCCESS,
      `Job function “${selectedRecord?.job_function}” successfully deleted from Aspiration Matching.`
    );
    setConfirmDeleteModal(false);
    setSelectedRecord(null);
  };

  useEffect(() => {
    isEmpty(dataChanges) ? setShowAlertRefresh(false) : setShowAlertRefresh(true);
  }, [JSON.stringify(dataChanges)]);

  return (
    <Wrapper
      title={
        <div>
          <div style={{ marginBottom: '20px' }}>
            <GlobalAlertMessage id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_ASPIRATION} />
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <Title>Edit Aspiration Matching</Title>
            <div style={{ cursor: 'pointer' }} onClick={() => handleCancel()} onKeyDown={() => {}}>
              <img src={close} alt="" />
            </div>
          </div>
        </div>
      }
      open={aspirationMatchingEdit.open}
      onCancel={() => handleCancel()}
      width={1450}
      footer={null}
      closable={false}
    >
      <WrapperLegend>
        <div className="d-flex justify-content-start align-items-center mr-3">
          <Legend1 />
          <LegendText>Model Output</LegendText>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Legend2 />
          <LegendText>Updated by Admin</LegendText>
        </div>
      </WrapperLegend>
      <Spin size="large" spinning={loading}>
        <TableDiv>
          <TableData
            tableEdit={aspirationMatchingEdit.mode === 'edit' && true}
            tableView={aspirationMatchingEdit.mode === 'view' && true}
            ghost
            columns={
              aspirationMatchingEdit.mode === 'view'
                ? columns.filter((column) => column.key !== 'show_hide' && column.key !== 'action')
                : columns
            }
            rowClassName={(record) => {
              return record?.isFirstRow ? 'border' : '';
            }}
            dataSource={
              !isEmpty(data) &&
              data
                .slice((query.page - 1) * query.limit, query.page * query.limit)
                .map((item, _index) => ({
                  key: item?.ids,
                  ids: item?.ids,
                  isFirstRow: item?.isFirstRow,
                  totalRowSpan: item?.totalRowSpan,
                  isSearching: item?.isSearching,
                  isJobFunction: item?.job_function,
                  job_function: (
                    <>
                      {item?.isSearching ? (
                        <div className="d-flex justify-content-between align-items-center">
                          <SearchJobFunctionComplementary
                            mode="add"
                            roleId={roleId}
                            permissions={permissions}
                            item={item}
                            openDropdown={isOpen}
                            setOpenDropdown={setIsOpen}
                            setValue={({ val }) => {
                              const isExist = [...data]
                                .filter(
                                  (f) =>
                                    f.original_minilmjobfunction ===
                                      item.original_minilmjobfunction &&
                                    f.aspiration_statement === item.aspiration_statement
                                )
                                .find((item) => item.job_function === val);
                              if (isExist) {
                                handleShowMessage(
                                  ALERT_MESSAGE_TYPE.WARNING,
                                  `Job Function "${isExist?.job_function}" already exists in this aspiration statement. `,
                                  'Please ensure this Job Function is not duplicated in same aspiration statement in order to proceed with editing.'
                                );
                              } else {
                                setData((prev) => {
                                  const itemSelected = prev.find((i) => i.ids === item.ids);
                                  const parentItem = prev.find((i) => i.ids === item?.prev_id);
                                  itemSelected.job_function = val;
                                  if (parentItem && isEmpty(parentItem?.originalHide)) {
                                    parentItem.originalHide = {
                                      value: parentItem.is_hide,
                                      original_edit_type: parentItem.edit_type,
                                    };
                                    parentItem.edit_type = 'hide';
                                    parentItem.is_hide = true;
                                    parentItem.isEditing = true;
                                  }
                                  return [...prev];
                                });
                                dispatch(updateIsError(false));
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          {item.is_updated === false ? <Legend1 /> : <Legend2 />}
                          <Text className="col-10">{item.job_function}</Text>
                        </div>
                      )}
                    </>
                  ),
                  list_of_position: !item?.isSearching ? (
                    <BasicPopover
                      content={
                        <ListPositionTable
                          data={item.list_of_position}
                          setViewPositionsIndex={setViewPositionsIndex}
                        />
                      }
                      trigger="click"
                      placement="bottomLeft"
                      getPopupContainer={(trigger) => trigger}
                      open={_index === viewPositionsIndex}
                      onOpenChange={(open) => {
                        if (!open) {
                          setViewPositionsIndex(-1);
                        }
                      }}
                      styles={{
                        minWidth: 630,
                      }}
                    >
                      <div
                        className={`textListPosition ${
                          isEmpty(
                            item?.list_of_position &&
                              isArray(item?.list_of_position) &&
                              item?.list_of_position.filter((item) => !item.is_hide)
                          ) ||
                          (!item?.list_of_position[0]?.position_code &&
                            !item?.list_of_position[0]?.position_name)
                            ? `disable`
                            : ''
                        }`}
                        onKeyDown={() => {}}
                        onClick={() => setViewPositionsIndex(_index)}
                      >
                        View list of position
                      </div>
                    </BasicPopover>
                  ) : null,
                  source: <Text>{item.source}</Text>,
                  confidence_score: (
                    <Text>
                      {item.confidence_score && Number.parseFloat(item.confidence_score).toFixed(2)}
                    </Text>
                  ),
                  aspiration_statement: (
                    <>
                      <Tooltip
                        title={
                          item.talent_statement && (
                            <div>Talent Statement: {item.talent_statement}</div>
                          )
                        }
                        {...{
                          overlayInnerStyle: {
                            width: '500px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            fontFamily: '"Inter", sans-serif',
                            padding: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#000',
                          },
                        }}
                      >
                        <Text>{item.aspiration_statement}</Text>
                      </Tooltip>
                    </>
                  ),
                  group: item.group,
                  index: _index,
                  show_hide: (
                    <>
                      {!item?.job_function && isOpen !== item.ids && (
                        <CancelButton
                          onClick={() => {
                            const index = data.findIndex((f) => f?.ids && f.ids === item.ids);
                            const lastIndex = data.length - 1;
                            setQuery((prev) => ({
                              ...prev,
                              page:
                                index % 10 === 0 && index === lastIndex ? prev.page - 1 : prev.page,
                              total: data.filter((f) => f?.ids && f.ids !== item.ids)?.length,
                            }));
                            setData((prev) => prev.filter((f) => f?.ids && f.ids !== item.ids));
                            dispatch(updateIsError(false));
                          }}
                        >
                          Cancel
                        </CancelButton>
                      )}
                      {item?.job_function && isOpen !== item.ids && (
                        <>
                          <SelectAnt
                            labelInValue
                            bordered={false}
                            value={item.is_hide}
                            style={{ width: 70 }}
                            suffixIcon={<img src={chevron_down_complementary} alt="open" />}
                            onChange={(value) => {
                              setData((prev) => {
                                const itemSelected = prev.find((i) => i.ids === item.ids);
                                itemSelected.is_hide = value.value;
                                if (!itemSelected?.isSearching) itemSelected.isEditing = true;
                                if (!isEmpty(item?.originalHide)) {
                                  if (!item?.originalHide?.isExistEditType) {
                                    delete itemSelected.edit_type;
                                  }
                                  delete itemSelected.isEditing;
                                  delete itemSelected.originalHide;
                                } else if (isEmpty(item?.originalHide)) {
                                  itemSelected.originalHide = {
                                    value: !value.value,
                                    isExistEditType: itemSelected.edit_type
                                      ? itemSelected.edit_type
                                      : '',
                                  };
                                  itemSelected.edit_type = 'hide';
                                }

                                return [...prev];
                              });
                            }}
                            options={[
                              { value: false, label: 'No' },
                              { value: true, label: 'Yes' },
                            ]}
                          />
                          <div className={styles.indicators}>
                            {returnContentHideStatus(item, thresholdVal)}
                          </div>
                        </>
                      )}
                    </>
                  ),
                  action: (
                    <>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              label: <div>Add Job Function</div>,
                              key: '0',
                              onClick: () => {
                                addJobFunction({
                                  item,
                                  _index: (query.page - 1) * query.limit + _index,
                                  type: 'isSearching',
                                });
                              },
                            },
                            {
                              icon: <img src={deleteNote} alt="delete" />,
                              label: <div>Delete</div>,
                              key: '1',
                              onClick: () => {
                                handleDeleteRecord(item);
                              },
                            },
                          ].filter((f) =>
                            item.is_updated === false ? f.key === '0' : f.key === '1'
                          ),
                        }}
                        trigger={['click']}
                        style={{ padding: '4px' }}
                        placement="bottomRight"
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Space style={{ cursor: 'pointer' }}>
                          <img src={edit_delete} alt="dot" />
                        </Space>
                      </Dropdown>
                    </>
                  ),
                }))
            }
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </TableDiv>

        {!isEmpty(data) && (
          <div className="d-flex justify-content-end align-items-center pb-5">
            <BasicButton mode="cancel" className="mr-3" onClick={() => handleCancel()}>
              Cancel
            </BasicButton>
            <BasicButton
              mode="teal"
              onClick={() =>
                aspirationMatchingEdit.mode === 'edit'
                  ? saveAspirationMatching()
                  : setAspirationMatchingEdit({ mode: 'edit', open: true })
              }
            >
              {aspirationMatchingEdit.mode === 'edit' ? 'Save' : 'Edit'}
            </BasicButton>
          </div>
        )}
        {isShowPopupSaveAgenda && (
          <ModelTC
            info={{
              type: 'withoutSavingAspirationMatching',
              title: `You are about to leave this page without saving.`,
              visible: isShowPopupSaveAgenda,
              desc: (
                <DescForWithoutModalAspiration
                  dataChanges={dataChanges}
                  allData={data}
                  typeMatching={MODULE_FILTER_TABLE.ASPIRATION_MATCHING}
                />
              ),
              setVisible: setIsShowPopupSaveAgenda,
              handleSubmit: () => {
                navigatingPathname && handleKeepNavigate(navigatingPathname);
                saveAspirationMatching();
                setIsShowPopupSaveAgenda(false);
                setAspirationMatchingEdit({ mode: 'edit', open: false });
              },
              onClose: () => {
                dispatch(updateIsError(false));
                navigatingPathname && handleKeepNavigate(navigatingPathname);
                setIsShowPopupSaveAgenda(false);
                setAspirationMatchingEdit({ mode: 'edit', open: false });
              },
            }}
          />
        )}
        {confirmDeleteModal && (
          <ModelTC
            info={{
              type: 'deleteRecordComplementaryExperience',
              visible: confirmDeleteModal,
              setVisible: setConfirmDeleteModal,
              handleSubmit: () => {
                confirmDeleteRecord();
              },
              loading: false,
              onClose: () => {
                setConfirmDeleteModal(false);
                setSelectedRecord(null);
              },
            }}
          />
        )}
      </Spin>
    </Wrapper>
  );
};

CareerAspirationEdit.propTypes = {
  aspirationMatchingEdit: PropTypes.object,
  setAspirationMatchingEdit: PropTypes.func,
  roleId: PropTypes.number,
  staffId: PropTypes.string,
  permissions: PropTypes.array,
};

export default CareerAspirationEdit;
