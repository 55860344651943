import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

const AiContentWrapper = styled.ul`
  font-family: 'inter';
  color: #9f9eae;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 20px 0;
`;

const AiViewMore = styled.div`
  font-family: 'inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #3f3c4c;
  user-select: none;
  cursor: pointer;
`;

const DescForWithoutModalAspiration = ({ dataChanges, allData, _typeMatching }) => {
  const [viewmore, setViewmore] = useState(false);
  const arr1 = [];
  const arr2 = [];
  dataChanges.forEach((element) => {
    if (element?.ids) {
      const isExist = arr1.find(
        (f) => f.job_function === element.job_function && f.is_hide === element.is_hide
      );
      console.log(allData.find((a) => a.ids === element.ids));
      console.log(element);
      if (
        isEmpty(isExist) &&
        allData.find((a) => a.ids === element.ids).originalHide?.value !== element.is_hide
      )
        arr1.push(element);
    }
    if (!element?.ids) {
      const isExist = arr2.find((f) => f.job_function === element.job_function);
      if (isEmpty(isExist)) arr2.push(element);
    }
  });
  return (
    <>
      You have made changes on:
      <AiContentWrapper>
        {!isEmpty([...arr1, ...arr2]) &&
          [...arr1, ...arr2].map((m, index) => {
            if (viewmore === false && index > 4) return <></>;
            return (
              <>
                {!m?.ids && <li key={m.ids + index}>Job Function - {m.job_function}</li>}
                {m?.ids && (
                  <li key={index}>
                    Hide ({m.is_hide ? 'Yes' : 'No'}) - {m.job_function}
                  </li>
                )}
              </>
            );
          })}
      </AiContentWrapper>
      {!isEmpty([...arr1, ...arr2]) && [...arr1, ...arr2].length > 5 && (
        <AiViewMore onClick={() => setViewmore(!viewmore)}>
          {viewmore === false ? '...View more' : 'View less'}
        </AiViewMore>
      )}
    </>
  );
};

export default DescForWithoutModalAspiration;
