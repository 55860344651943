import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchFirstSectionProfiles,
  fetchThirdSectionProfiles,
  getSecondSectionProfiles,
} from '../../../services/talentProfiles';

export const getFirstProfiles = createAsyncThunk(
  'profiles/getFirstSectionProfiles',
  async (params, thunkAPI) => {
    const { id, roleActive, permissions } = params;
    try {
      const response = await fetchFirstSectionProfiles(id, roleActive, permissions);
      const data = response.data.result[0];
      if (data) {
        const firstSection = {
          opu: data.opu || '',
          company: data.company || '',
          department: data.department || '',
          positionName: data.position_name || '',
          email: data.email || '',
          positionId: data.position_id || '',
          staffId: data.staff_id || '',
          birthName: data.birth_name || '',
          businessUnit: data.business_unit || '',
          division: data.division || '',
          imageUrl: data.image || '',
          action_name: data.action_name || '',
          action_reason: data.action_reason || '',
          assignment_status_type: data.assignment_status_type || '',
        };
        return firstSection;
      }
      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getSecondProfiles = createAsyncThunk(
  'profiles/getSecondProfiles',
  async (params, thunkAPI) => {
    const { id, roleActive, permissions } = params;
    try {
      const response = await getSecondSectionProfiles(id, roleActive, permissions);
      const data = response.data.result;
      if (data) {
        const secondSection = {
          aboutMe: data.aboutMe,
          age: data.age,
          country: data.country,
          stateOfBirth: data.stateOfBirth,
          dateOfBirth: data.dateOfBirth,
          retirement_date: data.retirement_date,
          contract_end_date: data.contract_end_date,
          is_contract_end_date: data.is_contract_end_date,
          topTalentStatus: data.topTalentStatus,
          yearsToRetirement: data.yearsToRetirement,
          yearsContractEndDate: data.yearsContractEndDate,
          line: data.line,
          salary_grade: data.salary_grade,
        };
        return secondSection;
      }
      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getThirdProfiles = createAsyncThunk(
  'profiles/getThirdProfiles',
  async (params, thunkAPI) => {
    const { id, roleActive, permissions } = params;
    try {
      const response = await fetchThirdSectionProfiles(id, roleActive, permissions);
      const data = response.data.result;
      if (data) {
        const thirdSection = {
          ...data,
          dateCompany: data.dateCompany,
          datePet: data.datePet,
          datePosition: data.datePosition,
          dateSg: data.dateSg,
          jg: data.jg,
          performances: data.performances,
          positionId: data.positionId,
          sg: data.sg,
          supervisorName: data.supervisorName,
          workLocation: data.workLocation,
          job_role_level: data.job_role_level,
          sg_role_level: data.sg_role_level,
          yip: data.yip,
          yisg: data.yisg,
          supervisorId: data.supervisorId,
        };
        return thirdSection;
      }
      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
const profileSlice = createSlice({
  name: 'profiles',
  initialState: {
    loading: false,
    firstSectionProfile: {},
    secondSectionProfile: {},
    thirdSectionProfile: {},
    error: '',
  },
  reducers: {},
  extraReducers: {
    [getFirstProfiles.pending]: (state, action) => {
      state.loading = true;
    },
    [getFirstProfiles.rejected]: (state, action) => {
      state.loading = false;
      state.firstSectionProfile = {};
    },
    [getFirstProfiles.fulfilled]: (state, action) => {
      state.firstSectionProfile = action.payload;
      state.loading = false;
    },
    [getSecondProfiles.pending]: (state, action) => {
      state.loading = true;
    },
    [getSecondProfiles.rejected]: (state, action) => {
      state.loading = false;
      state.secondSectionProfile = {};
    },
    [getSecondProfiles.fulfilled]: (state, action) => {
      state.secondSectionProfile = action.payload;
      state.loading = false;
    },
    [getThirdProfiles.pending]: (state, action) => {
      state.loading = true;
    },
    [getThirdProfiles.rejected]: (state, action) => {
      state.loading = false;
      state.thirdSectionProfile = {};
    },
    [getThirdProfiles.fulfilled]: (state, action) => {
      state.thirdSectionProfile = action.payload;
      state.loading = false;
    },
  },
});

export const { reducer: profilesReducer, actions } = profileSlice;
export default profilesReducer;
