import React from 'react';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { BreadCrumb } from '../../../components';
import { getBreadCrumbList } from '../../../utils/helper';
import styles from './homepage-management.module.scss';
import NewsAndAnnouncement from './NewsAndAnnouncement/NewsAndAnnouncement';

const tabs = [
  { id: 0, label: 'News and Announcement', width: '223px', left: 0, page: 'news-announcement' },
  { id: 1, label: 'Reminder', width: '112px', left: '223px', page: 'reminder' },
  { id: 2, label: 'Frequently Asked Questions (FAQ)', width: '282px', left: '335px', page: 'faq' },
];

const Navbar = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: start;
  align-items: center;
  width: 100%;
`;
const TabItem = styled.div`
  padding: 16px 24px;
  min-width: 106px;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0168em;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props?.active ? '#007874' : '#181818')};
  white-space: nowrap;
  transition: color 0.3s;
`;
const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbd6e2;
`;
const Tabs = styled.div`
  position: relative;
`;
const Transition = styled.div`
  position: absolute;
  width: ${(props) => props?.width};
  left: ${(props) => props?.left};
  height: 2px;
  background-color: #00a19c;
  transition: width 0.3s, left 0.3s, right 0.3s;
  transform: translateY(-50%);
`;

export default function HomepageManagement() {
  const { page } = useParams();
  const history = useHistory();
  const pageSelected = tabs.find((f) => f.page === page);

  return (
    <div className="max-width">
      <div className={styles.pageContainer}>
        <div>
          <BreadCrumb
            level={3}
            breadCrumbList={getBreadCrumbList([`Dashboard`, `Admin`, `Homepage Management`])}
          />
        </div>
        <h3 className={styles.mainTitle}>Homepage Management</h3>
        <Tabs>
          <Navbar>
            {tabs.map((m) => (
              <TabItem
                active={m.id === pageSelected?.id ? true : false}
                key={'position-tab' + m.id}
                id={m.id}
                onClick={() => history.push('/admin/home-management/' + m.page)}
              >
                {m.label}
              </TabItem>
            ))}
          </Navbar>
          <BreakLine />
          <Transition width={pageSelected.width} left={pageSelected.left} />
        </Tabs>
        {pageSelected.id === 0 && <NewsAndAnnouncement />}
        {pageSelected.id === 1 && <>Reminder</>}
        {pageSelected.id === 2 && <>Frequently Asked Questions (FAQ)</>}
      </div>
    </div>
  );
}
