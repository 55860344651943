import { Spin, Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AiCheckBox, BasicButton, BasicSelect } from '../../../../../assets/common';
import { delete_mobility_plan } from '../../../../../assets/img';
import { MOBILITY_PLAN_DROP_CARD_INFO } from '../../../../../utils/constants';
import * as styles from './select-position-mobility-plan.module.scss';
import SortableItem from '../../../../../components/SortableItem/SortableItem';
import { talentReviewApi } from '../../../../../services/talentReviewApi';
import { v4 as uuidv4 } from 'uuid';
import { InView } from 'react-intersection-observer';
const { Option } = BasicSelect;

const SelectPositionMobilityPlan = ({
  isView,
  data,
  setData,
  type,
  sectionIndex,
  maxLength,
  isOpenFulfilMent,
  setPositionSelected,
  positionSelected,
  anotherType,
}) => {
  const initFormValue = {
    open: false,
    keyword: '',
    search: '',
    page: 1,
    data: [],
    hasMore: true,
    loading: false,
    dataRender: [],
  };
  const [initForm, setInitForm] = useState(initFormValue);
  const [selectedPositionCodes, setSelectedPositionCodes] = useState([]);

  const getMobilityPositionOptions = async () => {
    if (initForm.loading) {
      return;
    }
    try {
      setInitForm((prev) => ({ ...prev, loading: true }));
      const res = await talentReviewApi.getMobilityPositions({
        search: initForm.search,
        page: initForm.page,
        limit: 5,
      });
      const result = get(res, 'data.result.result') || [];
      if (isEmpty(result)) return setInitForm((prev) => ({ ...prev, hasMore: false }));
      if (initForm.page > 1) {
        setInitForm((prev) => ({ ...prev, data: [...prev.data, ...result], hasMore: true }));
      } else {
        setInitForm((prev) => ({ ...prev, data: !isEmpty(result) ? result : [], hasMore: true }));
      }
    } catch (error) {
      console.error(error);
      setInitForm((prev) => ({
        ...prev,
        page: 1,
        search: '',
        data: [],
        dataRender: [],
        hasMore: true,
      }));
    } finally {
      setInitForm((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (initForm.open) getMobilityPositionOptions();
  }, [initForm?.search, initForm?.page, initForm.open]);

  const addedOptions = useMemo(() => {
    return data[type]?.selection || [];
  }, [data, type]);

  const handleSearch = (value) => {
    setInitForm((prev) => ({ ...prev, keyword: value }));
  };

  const handleDropdownVisibleChange = (visible) => {
    setInitForm((prev) => ({
      ...prev,
      search: '',
      page: 1,
      data: [],
      hasMore: true,
      loading: false,
      dataRender: [],
      keyword: '',
      open: visible,
    }));
    setSelectedPositionCodes([]);
  };

  useEffect(() => {
    if (!initForm.open) return;
    const delayInputTimeoutId = setTimeout(() => {
      setInitForm((prev) => ({
        ...prev,
        page: 1,
        search: initForm.keyword,
        data: [],
        dataRender: [],
        hasMore: true,
      }));
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [initForm.keyword]);

  const handleAdd = () => {
    const selectedOptions = initForm.data.filter((option) =>
      selectedPositionCodes.includes(option.position_code)
    );

    if (type === MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      if (
        !isEmpty(data?.end_state?.selection) &&
        selectedPositionCodes[0] === data.end_state.selection[0].position_code
      ) {
        return;
      } else {
        setData((prev) => ({
          ...prev,
          [type]: {
            ...prev?.[type],
            selection: [...selectedOptions],
          },
        }));
      }
    } else {
      const concatArr = [
        ...(!isEmpty(data?.next_mobility?.selection) ? data.next_mobility.selection : []),
        ...(!isEmpty(data?.next_2_mobility?.selection) ? data.next_2_mobility.selection : []),
      ].map((el) => el?.priority || 0);
      const isCurrentPriority = isEmpty(concatArr) ? 1 : Math.max(...concatArr) + 1;

      setData((prev) => ({
        ...prev,
        [type]: {
          ...prev?.[type],
          selection: [
            ...(prev?.[type]?.selection || []),
            ...selectedOptions.map((item) => ({ ...item, priority: isCurrentPriority })),
          ],
        },
      }));
    }
    setInitForm(initFormValue);
  };

  const handleOnSelect = (selectedPositionCode) => {
    const checkedPositionCode = selectedPositionCodes.find(
      (positionCode) => positionCode === selectedPositionCode
    );
    if (checkedPositionCode) {
      setSelectedPositionCodes((prev) =>
        prev.filter((positionCode) => positionCode !== checkedPositionCode)
      );
    } else {
      setSelectedPositionCodes((prev) => [...prev, selectedPositionCode]);
    }
  };

  const handleDelete = (deletePositionCode) => {
    if (type !== MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      const isPriorityDeleted = addedOptions.find(
        (f) => f.position_code === deletePositionCode
      ).priority;
      const deletedData = addedOptions
        .filter((option) => option.position_code !== deletePositionCode)
        .map((option) => ({
          ...option,
          priority: isPriorityDeleted < option.priority ? option.priority - 1 : option.priority,
        }));

      const anotherData = (data[anotherType]?.selection || []).map((option) => ({
        ...option,
        priority: isPriorityDeleted < option.priority ? option.priority - 1 : option.priority,
      }));

      setData((prev) => ({
        ...prev,
        [type]: { ...prev[type], selection: deletedData },
        [anotherType]: { ...prev[anotherType], selection: anotherData },
      }));
    } else {
      const deletedData = addedOptions.filter(
        (option) => option.position_code !== deletePositionCode
      );
      setData((prev) => ({
        ...prev,
        [type]: { ...prev[type], selection: deletedData },
      }));
    }
  };

  const dropdownRender = (menu) => {
    return (
      <Spin spinning={initForm.loading}>
        <div>
          {menu}
          <div className={styles.addButton}>
            <BasicButton mode="teal" onClick={handleAdd} disabled={isEmpty(selectedPositionCodes)}>
              Add
            </BasicButton>
          </div>
        </div>
      </Spin>
    );
  };

  useEffect(() => {
    if (isEmpty(initForm?.data)) return;
    let map = new Map();
    for (let option of addedOptions) {
      map.set(option.position_code, true);
    }
    setInitForm((prev) => ({
      ...prev,
      dataRender: prev.data
        .filter((option) => !map.has(option.position_code))
        .map((option, index) => ({ ...option, id: index })),
    }));
  }, [addedOptions, initForm?.data]);

  const isDisableSearchEndState = useMemo(() => {
    if (type === MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      return selectedPositionCodes.length > 0;
    } else {
      return false;
    }
  }, [selectedPositionCodes, type]);

  const height = isOpenFulfilMent
    ? maxLength < 61
      ? '84px'
      : maxLength < 70
      ? '106px'
      : '128px'
    : maxLength < 61
    ? '62px'
    : maxLength < 70
    ? '84px'
    : '106px';

  const color =
    type === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY.type
      ? '#005CB9'
      : type === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_2_MOBILITY.type
      ? '#763F98'
      : '#00A19C';

  const renderCardContent = () => {
    return (
      <>
        <div className={styles.added}>
          {isView &&
            addedOptions.map((option) => (
              <div
                key={`${option.position_code}${option.position_name}`}
                className={styles.positionName}
                style={{
                  height,
                  color,
                  borderLeftColor: color === '#00A19C' ? '#66B2C4' : color,
                  boxShadow:
                    positionSelected?.position_code === option.position_code &&
                    type === positionSelected?.typeMobility
                      ? '0px 4px 6px 0px rgba(52, 63, 75, 0.10), 0px 2px 4px 0px rgba(52, 63, 75, 0.06)'
                      : 'unset',
                }}
                onClick={() =>
                  setPositionSelected &&
                  setPositionSelected(
                    positionSelected?.position_code === option.position_code &&
                      type === positionSelected?.typeMobility
                      ? null
                      : { position_code: option.position_code, typeMobility: type }
                  )
                }
                onKeyDown={() => {}}
              >
                <Tooltip
                  placement="top"
                  color={'#181818'}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={
                    <>
                      {option.position_name}
                      <ul>
                        {option.department && <li>Department: {option.department}</li>}
                        {option.division && <li>Division: {option.division}</li>}
                        {option.opu && <li>OPU: {option.opu}</li>}
                        {option.business && <li>Business: {option.business}</li>}
                      </ul>
                    </>
                  }
                >
                  {option.position_name}{' '}
                  <span style={{ fontWeight: 400 }}>({option.position_code})</span>{' '}
                  {option.job_grade && (
                    <span style={{ fontWeight: 400 }}>({option.job_grade})</span>
                  )}
                </Tooltip>
                {!isView && (
                  <img
                    src={delete_mobility_plan}
                    alt="delete"
                    onClick={() => handleDelete(option.position_code)}
                    onKeyDown={() => {}}
                  />
                )}
              </div>
            ))}
          {!isView && (
            <SortableContext
              id={type}
              items={addedOptions.map((item) => item.position_code + type)}
              strategy={verticalListSortingStrategy}
            >
              <div>
                {addedOptions.map((item) => (
                  <SortableItem
                    key={item.position_code}
                    id={item.position_code + type}
                    item={item}
                    positionSelected={positionSelected}
                    setPositionSelected={setPositionSelected}
                    isView={isView}
                    color={color}
                    type={type}
                    height={height}
                    styles={styles}
                    handleDelete={handleDelete}
                  />
                ))}
              </div>
            </SortableContext>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper} style={isEmpty(initForm?.data) ? { border: 'none' } : {}}>
      {!isView && (
        <BasicSelect
          mode={'multiple'}
          bordered={false}
          searchValue={initForm.keyword}
          onSearch={handleSearch}
          value={[]}
          showSearch
          autoClearSearchValue={false}
          open={initForm.open}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          getPopupContainer={(trigger) => trigger.parentElement}
          placeholder="Search by Position Name or Position ID"
          dropdownRender={(e) => dropdownRender(e)}
          filterOption={false}
        >
          {initForm.dataRender.map((option, index) => (
            <Option
              key={uuidv4()}
              value={option.position_code}
              disabled={
                isDisableSearchEndState && !selectedPositionCodes.includes(option.position_code)
              }
            >
              <div
                onClick={() => {
                  if (
                    !isDisableSearchEndState ||
                    (isDisableSearchEndState &&
                      selectedPositionCodes.includes(option.position_code))
                  ) {
                    handleOnSelect(option.position_code);
                  }
                }}
                className={styles.option}
                onKeyDown={() => {}}
              >
                <AiCheckBox
                  checked={selectedPositionCodes.includes(option.position_code)}
                  onChange={(e) => e.preventDefault()}
                  disabled={
                    isDisableSearchEndState && !selectedPositionCodes.includes(option.position_code)
                  }
                >
                  {option.position_name}
                  <InView
                    as="div"
                    style={{ fontWeight: 400 }}
                    onChange={(inView) => {
                      index === initForm.dataRender.length - 1 &&
                        initForm.hasMore &&
                        inView &&
                        setInitForm((prev) => ({
                          ...prev,
                          page: prev.loading ? prev.page : prev.page + 1,
                        }));
                    }}
                  >
                    {option.position_code}
                  </InView>
                </AiCheckBox>
              </div>
            </Option>
          ))}
        </BasicSelect>
      )}

      {!isEmpty(addedOptions) && <>{renderCardContent()}</>}
    </div>
  );
};

SelectPositionMobilityPlan.propTypes = {
  isView: PropTypes.bool,
  options: PropTypes.array,
  data: PropTypes.object,
  setData: PropTypes.func,
  type: PropTypes.string,
};

export default SelectPositionMobilityPlan;
