import React, { useState } from 'react';
import { BasicPopover, font } from '../../assets/common';
import { arrow_news_announcement } from '../../assets/img';
import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';

const Label = styled.div`
  padding: 9px 15px;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 0px #0000000a;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  gap: 20px;
  background-color: #fff;
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  cursor: pointer;
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  gap: 5px;
  font-family: ${font.inter};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;

export const CheckBoxCustom = styled(Checkbox)`
  ${(_props) => {
    return css`
      .ant-checkbox:hover,
      .ant-checkbox {
        .ant-checkbox-inner {
          border-color: #95a3b2;
          border-radius: 4px !important;
        }
      }
      .ant-checkbox-input:focus
      {
        <input type="checkbox" class="ant-checkbox-input" value="">
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #00645c;
        border-color: #00645c;
        border-radius: 4px !important;
        ::after {
          border-radius: 1px;
        }
      }
    `;
  }}
`;

export const PopoverContent = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  user-select: none;
`;

const options = [
  [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
  ],
  [
    { value: 'published', label: 'Published' },
    { value: 'draft', label: 'Draft' },
    { value: 'expired', label: 'Expired' },
  ],
];

export default function DropdownCheckbox(props) {
  const { children, stt, handleChangeFilter } = props;
  const [selected, setSelected] = useState([]);

  function onChangeSelect(value) {
    setSelected((prev) => {
      const isExist = [...prev].find((f) => f === value);
      return isExist !== undefined ? [...prev].filter((f) => f !== value) : [...prev, value];
    });
  }

  return (
    <div>
      <BasicPopover
        onOpenChange={(open) => {
          if (!open) handleChangeFilter(selected);
        }}
        content={
          <PopoverContent>
            {options[stt].map((m) => (
              <DropdownItem onClick={() => onChangeSelect(m.value)} key={m.value}>
                <CheckBoxCustom
                  checked={selected.find((f) => f === m.value) === undefined ? false : true}
                />{' '}
                {m.label}
              </DropdownItem>
            ))}
          </PopoverContent>
        }
        trigger="click"
        placement="bottomLeft"
        getPopupContainer={(trigger) => trigger}
        styles={{
          w: 260,
        }}
      >
        <Label>
          {children} <img src={arrow_news_announcement} alt="arrow_news_announcement" />
        </Label>
      </BasicPopover>
    </div>
  );
}
